import styled from 'styled-components';

export const CargoDataCardContainer = styled.div`
  &.cargo-data-card {
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 380px;

    border: 1px solid #cfcbf1;
    border-radius: 10px;

    .cargo-data-card__title {
      font-size: 0.9rem;
      font-weight: bold;

      padding: 10px 15px;
      border-bottom: 1px solid #cfcbf1;
    }

    .cargo-data-card__wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      align-items: flex-start;
      justify-content: space-between;

      min-height: 50px;

      padding: 10px 15px;
    }

    .cargo-data-card__item {
      display: flex;
      flex-direction: column;
      gap: 5px;

      width: 100%;
      max-width: 100px;

      font-size: 1rem;
      font-weight: bold;

      small {
        font-size: 0.8rem;
        font-weight: normal;
      }
    }
  }
`;
