import React from 'react';

import { UseFormRegisterReturn } from 'react-hook-form';

import { Container } from './_squareInput';

interface IconInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  placeholder?: string;
  inputValue?: string;
  setInputValue?: Function;
  type?: string;
  error?: string;
  maxLength?: number;
  register?: UseFormRegisterReturn;
  inputMode?: any;
}

export const SquareInput: React.FC<IconInputProps> = ({
  label,
  placeholder,
  inputValue,
  setInputValue,
  type,
  error,
  maxLength,
  register,
  inputMode,
  ...rest
}) => {
  const handleChange = (value: string) => {
    if (setInputValue) {
      return setInputValue(value);
    }

    return;
  };

  return (
    <Container className="square-input" {...rest}>
      <label className="square-input__label">
        {label}

        <input
          type={type || 'text'}
          className="square-input__input"
          id={`input-${placeholder || label}`}
          maxLength={maxLength}
          placeholder={placeholder}
          onChange={(e) => handleChange(e.target.value)}
          value={inputValue && inputValue}
          inputMode={inputMode || undefined}
          {...register}
        />
      </label>
      {error && <span className="square-input__error">{error}</span>}
    </Container>
  );
};
