import React from 'react';

import { GenericButton } from './_secondaryButton';

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  backgroundColor?: string;
  disabled?: boolean;
  type?: any;
}

export const SecondaryButton: React.FC<ButtonProps> = ({
  backgroundColor,
  disabled,
  children,
  type,
  ...rest
}) => {
  return (
    <GenericButton
      type={type}
      className="secondary-button"
      backgroundColor={backgroundColor}
      disabled={disabled}
      {...rest}
    >
      {children}
    </GenericButton>
  );
};
