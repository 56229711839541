import React, { useContext, useState } from 'react';

import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';

import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import logo from '../../assets/icons/logo_vagoo.svg';
import { Input } from 'shared/components/molecules/Input/Input';
import { PrimaryButton } from 'shared/components/atoms/PrimaryButton/PrimaryButton';

import { Container, LoginWrapper, Logo } from './_login';
import { useHistory } from 'react-router-dom';
import { authSchema } from 'shared/utils/schemas';

import { ILogin } from 'types/authTypes';
import { Spinner } from 'shared/components/atoms/Spinner/Spinner';
import { AuthContext } from 'contexts/auth';
import { handleError } from 'services/errorHandler';
import api from 'services/api';
import { toast } from 'react-toastify';

export const Login: React.FC = () => {
  const history = useHistory();

  const { signIn, rememberUser, setRememberUser } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting, errors },
    watch,
  } = useForm<ILogin>({
    resolver: yupResolver(authSchema),
    mode: 'onChange',
  });

  const email = watch('email');

  const onSubmit: SubmitHandler<ILogin> = async (data) => {
    try {
      await signIn(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async () => {
    if (loading) {
      return;
    }

    if (!email || errors?.email) {
      handleError('Insira um e-mail válido para recuperar a senha');
      return;
    }

    setLoading(true);

    try {
      await api.post('/password/forgot', { email: email.trim() });

      toast.success('Senha enviada com sucesso, verifique seu e-mail!');
    } catch (error: any) {
      handleError(
        error?.response?.data?.message ||
          error?.message ||
          'Algo deu errado, tente novamente mais tarde',
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="container">
      <LoginWrapper className="login-wrapper" onSubmit={handleSubmit(onSubmit)}>
        <div className="login__container">
          <Logo
            className="header__logo"
            src={logo}
            alt="Logo Vagoo"
            width="280"
            height="60"
          />

          <div className="login__container form">
            <Input placeholder={'E-mail'} register={{ ...register('email') }} />
            <div className="form__password-wrapper">
              <Input
                type={showPassword ? 'text' : 'password'}
                placeholder={'Senha'}
                register={{ ...register('password') }}
              />

              <button
                className="form__visible-password-button"
                type="button"
                onClick={() => setShowPassword((prev) => !prev)}
              >
                {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
              </button>
            </div>

            <PrimaryButton disabled={isSubmitting || !isValid}>
              {loading ? <Spinner white /> : 'Login'}
            </PrimaryButton>

            <span
              onClick={handleResetPassword}
              className="form__forgot-password"
            >
              Esqueci a minha senha
            </span>

            <div className="form__log-wrapper">
              <label className="log-wrapper__keep-loggedin">
                <input
                  type="checkbox"
                  checked={rememberUser}
                  onChange={() => setRememberUser(!rememberUser)}
                />
                <span>&#10003;</span>
                Me mantenha logado
              </label>
              <div className="log-wrapper__register-call">
                É novo aqui?{' '}
                <span onClick={() => history.push('cadastro')}>
                  Cadastre-se
                </span>
              </div>
            </div>
          </div>
        </div>
      </LoginWrapper>
    </Container>
  );
};
