import styled from 'styled-components';

import visa from '../../../../assets/icons/visa.svg';
import mastercard from '../../../../assets/icons/mastercard.svg';
import double_check from '../../../../assets/icons/double_check.svg';
import { ReactComponent as EditIconRaw } from '../../../../assets/icons/edit_icon.svg';

export const Container = styled.div`
  &.add-card-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    margin: 0 auto 15px;

    color: var(--purple22);

    width: 100%;
    max-width: 420px;

    animation: entering 0.5s ease-in;

    .square-input__input {
      border-width: 2px;
    }

    .add-card-form__row {
      display: flex;
      gap: 5px;

      width: 100%;
    }

    .add-card-from__flag {
      width: 65px;
      height: 48px;

      margin-top: auto;

      background: no-repeat center;
      background-size: contain;

      background-position: 0px 9px;

      opacity: 0.3;
      filter: grayscale(1);

      &.visa {
        background-image: url(${visa});
      }

      &.mastercard {
        background-image: url(${mastercard});
      }
    }

    .add-card-form__short-input {
      max-width: 150px;
    }

    .add-card-form__half-input {
      .square-input__input {
        max-width: 300px;
      }
    }

    .secondary-button {
      text-transform: uppercase;
      margin-top: 10px;
    }
  }
`;

export const AddCreditCardFeedback = styled.div`
  &.add-card-feedback {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;

    width: 100%;
    max-width: 300px;

    padding: 15px;

    font-size: 1.8rem;
    color: var(--purple22);
    font-weight: bold;
    text-align: center;

    .add-card-feedback__icon {
      width: 100px;
      height: 100px;

      background: no-repeat center;
      background-size: contain;
      background-image: url(${double_check});
    }
  }
`;

export const Radio = styled.div`
  &.radio {
    display: flex;
    flex-wrap: wrap;

    gap: 15px;

    padding: 15px 0;

    width: 100%;

    .radio__option {
      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;

      cursor: pointer;
      user-select: none;

      input {
        opacity: 0;
        height: 0;
        width: 0;
      }

      input:checked ~ .radio__selection {
        color: var(--green2c);

        &:before {
          border: 1px solid var(--green2c);
          box-shadow: 0 0 0px 4px var(--green2c);
        }
      }

      .radio__selection {
        position: relative;

        display: flex;
        align-items: center;

        padding-left: 18px;

        min-height: 1.6875rem;

        color: #868e96;

        &:before {
          content: '';
          position: absolute;

          top: 45%;
          left: 0;

          transform: translateY(-50%);

          width: 7px;
          height: 7px;

          border-radius: 14px;
          border: 1px solid transparent;
          box-shadow: 0 0 0px 4px #868e96;
        }

        .secondary-button {
          text-transform: unset;
          margin: 0 10px;
        }
      }
    }

    .radio__add-address {
      margin: 0 15px;

      border-radius: 4px;
      text-transform: unset;

      display: flex;
      align-items: center;
      justify-content: center;

      min-height: 1.6875rem;
      height: fit-content;
      box-shadow: none;
      padding: 0 1.75rem;

      font-size: 0.6875rem;
      font-weight: bold;
      line-height: 1rem;
    }
  }
`;

export const AddressFeedback = styled.div`
  &.address-feedback {
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 5px;

    width: 100%;

    color: var(--purple22);
    font-size: 0.8rem;
    font-weight: 400;

    padding: 10px 24px;
    margin: 10px 0;

    border: 2px solid var(--greenb6);
    border-left: none;
    border-radius: 0.25rem;

    .address-feedback__header {
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .address-feedback__title {
      width: fit-content;
      padding: 10px;

      background-color: #effeff;

      border-radius: 15px;

      margin-bottom: 10px;
      white-space: nowrap;

      font-weight: 400;
      padding: 0.5rem 0.875rem;
    }

    .address-feedback__edit-button {
      /* position: absolute;
      top: 15px;
      right: 15px;

      width: 12px;
      height: 12px;

      background: no-repeat center 3px;

      cursor: pointer; */

      background: transparent;
      border: none;
      cursor: pointer;
    }

    &:before {
      position: absolute;
      top: -2px;
      bottom: -2px;
      left: 0;

      content: '';

      width: 7px;

      border-radius: 5px 0 0 5px;
      background-color: var(--purple22);
    }
  }
`;

export const EditIcon = styled(EditIconRaw)`
  width: 16px;
  height: 16px;
`

