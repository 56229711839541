import styled from 'styled-components';

import add_icon from '../../../../assets/icons/add_icon.svg';
import { ReactComponent as PinIconRaw } from 'assets/icons/pin_icon.svg';

export const CompanyRegisterContainer = styled.form`
  &.company-register-container {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100vw;
    height: 100vh;

    .company-register-container__form-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;

      width: 100%;
      max-width: 475px;
    }

    .form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;

      width: 100%;
      min-height: 500px;

      padding: 3em 2em;

      border-radius: 20px;
      background-color: rgb(255 255 255 /50%);

      .form__title {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;

        font-size: 0.95rem;
        color: var(--purple22);

        b {
          font-size: 1.5rem;
          color: var(--green2c);
          text-transform: uppercase;
        }
      }

      .form__register-container {
        width: 100%;
        height: 350px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background: #ced4da;
          border-radius: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background: var(--purple22);
          border-radius: 8px;
        }

        &::-webkit-scrollbar-thumb:hover {
          opacity: 0.9;
        }
      }

      .register-container__register {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 35px;

        min-height: 500px;

        padding-right: 10px;
      }

      .register__add-address {
        position: relative;

        display: flex;
        align-items: center;
        gap: 5px;

        margin: 0 auto 0 15px;

        color: var(--purple22);
        font-size: 1.5rem;
        font-weight: bold;

        cursor: pointer;

        &:before {
          position: relative;
          content: '';

          width: 41px;
          height: 41px;

          background: no-repeat center;
          background-size: cover;
          background-image: url(${add_icon});
        }
      }

      .register__address-container {
        display: flex;
        align-items: center;
        gap: 0.375rem;

        margin: 0 auto 0 15px;
      }

      .register__address {
        font-size: 1rem;
        font-weight: 400;
        color: #868e96;
      }

      .register__insurance {
        display: flex;
        flex-direction: column;
        gap: 10px;

        width: 100%;

        padding: 0 10px;

        color: var(--purple22);

        b {
          font-size: 1.4rem;
          color: var(--green2c);
        }
      }

      .register__create-password {
        display: flex;
        flex-direction: column;
        gap: 15px;

        width: 100%;

        .create-password__title {
          font-size: 1.5rem;
          color: var(--green2c);

          padding-left: 15px;
        }

        .create-password__input-wrapper {
          position: relative;
          width: 100%;
        }

        .create-password__visible-icon-button {
          position: absolute;

          position: absolute;
          right: 1.5rem;
          top: 55%;

          transform: translateY(-50%);

          background: none;
          border: none;

          color: #adb5bd;

          font-size: 1.5rem;

          cursor: pointer;

          &:hover {
            filter: brightness(0.9);
          }
        }

        input::-ms-reveal {
          display: none;
        }
      }

      .register__error-feedback {
        margin: 0 auto;

        color: #f00;
      }

      .icon-input__wrapper {
        height: 35px;

        .input__input {
          font-size: 1.5rem;
        }
      }

      .primary-button {
        width: 90%;

        position: relative;
        font-size: 1.4rem;
      }
    }
  }
`;

export const Logo = styled.img`
  width: 280px;
  height: auto;

  padding: 0 20px;
  @media (min-width: 1024.1px) {
    margin: auto;
  }
`;

export const PillSelector = styled.div`
  &.insurance__radio-wrapper {
    position: relative;
    display: flex;
    width: 100%;

    color: var(--purple22);
    font-size: 0.8rem;
    text-align: center;

    border-radius: 12px;
    box-shadow: 0 3px 6px rgb(0 0 0 /12%);

    input[type='radio'] {
      display: none;
    }

    &:before {
      content: '';

      position: absolute;
      top: 3px;
      bottom: 3px;
      left: 50%;

      transform: translateX(-50%);

      width: 2px;
      background-color: var(--purple22);
    }

    .insurance__ddr {
      padding: 15px 0;
    }

    .insurance__radio {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      padding: 15px 0;

      min-height: 45px;

      background-color: #b6eaed;

      cursor: pointer;
      transition: all 0.5s;
    }

    .insurance__radio.active {
      background-color: var(--purple22);
      color: #fff;
    }

    .radio--transporter {
      border-radius: 12px 0 0 12px;
    }

    .radio--shipper {
      border-radius: 0 12px 12px 0;
    }
  }
`;

export const Radio = styled.div`
  &.ddr-radio {
    display: flex;
    gap: 15px;

    padding: 15px 0;

    width: 100%;

    .ddr-radio__option {
      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;

      cursor: pointer;
      user-select: none;

      input {
        opacity: 0;
        height: 0;
        width: 0;
      }

      input:checked ~ .radio__selection {
        color: var(--green2c);

        &:before {
          border: 1px solid var(--green2c);
          box-shadow: 0 0 0px 4px var(--green2c);
        }
      }

      .radio__selection {
        position: relative;

        display: flex;
        align-items: center;

        padding-left: 18px;

        color: #868e96;

        &:before {
          content: '';
          position: absolute;

          top: 45%;
          left: 0;

          transform: translateY(-50%);

          width: 7px;
          height: 7px;

          border-radius: 14px;
          border: 1px solid transparent;
          box-shadow: 0 0 0px 4px #868e96;
        }
      }
    }
  }
`;

export const ReadPolicy = styled.div`
  position: relative;
  display: flex;

  font-size: 12px;
  color: var(--green2c);

  .policy__checkbox-label {
    display: block;
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    font-size: 14px;
    user-select: none;
    text-transform: none;

    input[type='checkbox'] {
      display: none;
    }

    input:checked ~ span {
      border: 3px solid #adb5bd;

      color: var(--purple22);
      padding: 1px 0;
    }

    span {
      width: 14px;
      height: 14px;
      display: block;
      position: absolute;

      top: 0;
      left: 0;

      font-size: 9px;
      color: #fff;
      font-weight: 700;
      text-align: center;

      border: 3px solid #adb5bd;
      border-radius: 3px;
      background-color: #fff;
    }
  }

  .policy__checkbox-label_text {
    margin-left: 30px;

    font-size: 12px;
    cursor: auto;
  }

  .policy__read-terms {
    position: absolute;
    left: 20px;

    font-size: 14px;
    color: var(--purple22);
    cursor: pointer;
  }

  @media (max-width: 400px) {
    margin: 5px auto;
    padding-right: 0;
  }
`;

export const PinIcon = styled(PinIconRaw)`
  height: 14px;
  width: 10px;
  overflow: visible;
`;
