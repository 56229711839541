import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  width: 100%;
  max-width: 370px;
  margin: 0 auto;

  .delivery-proof__label {
    font-size: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: left;

    position: relative;

    &:after {
      content: '';

      position: absolute;

      bottom: -10px;
      left: 0;

      width: 8px;
      height: 4px;

      background-color: var(--green2c);
    }
  }

  .delivery-proof__image {
    border-radius: 0.375rem;
    aspect-ratio: 4;
    width: fit-content;
    max-width: 100%;
    margin: 0 auto;
  }

  .delivery-proof__button {
    border: none;
    border-radius: 0.625rem;
    padding: 1rem 2rem;
    min-height: 3rem;

    background-color: var(--purple22);

    font-size: 0.875rem;
    font-weight: bold;

    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fff;

    cursor: pointer;

    &:hover {
      filter: brightness(1.1);
    }
  }
`;
