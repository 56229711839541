import React from 'react';
import { SecondaryButton } from 'shared/components/atoms/SecondaryButton/SecondaryButton';
import { TripStatus } from 'types/mainTypes';
import { ICreatedTrip } from 'types/requestVehicleTypes';
import { CargoStepDetail } from '../CargoStepDetail/CargoStepDetail';
import { CargoStepper } from '../CargoStepper/CargoStepper';

import { FollowRouteContainer } from './_followRouteDetails';
interface FollowRouteDetailsProps {
  trip: ICreatedTrip;
  onShowDeliveryInfo: () => void;
}

type StepStatus = 'pending' | 'done' | 'in_progress';

const steps = ['Deslocamento', 'Coleta', 'Viagem', 'Entrega'];

const defaultSteps = [
  {
    name: 'Deslocamento',
    statusIdWhenDone: TripStatus.Collecting,
  },
  {
    name: 'Coleta',
    statusIdWhenDone: TripStatus.OnGoing,
  },
  {
    name: 'Viagem',
    statusIdWhenDone: TripStatus.Finished,
  },
  {
    name: 'Entrega',
    statusIdWhenDone: 99,
  },
];

const getLastCompletedStep = (status_id: TripStatus): number => {
  switch (status_id) {
    case TripStatus.Collecting:
      return steps.indexOf('Deslocamento') + 1 || 0;
    case TripStatus.OnGoing:
      return steps.indexOf('Coleta') + 1 || 0;
    case TripStatus.Finished:
      return steps.indexOf('Entrega') + 1 || 0;
    default:
      return 0;
  }
};

export const FollowRouteDetails: React.FC<FollowRouteDetailsProps> = ({
  trip,
  onShowDeliveryInfo,
}) => {
  const { status_id, initial_address, status_marks } = trip;

  const formattedInitialAddress = `
    ${initial_address.address.street}, ${initial_address.address.number} -${' '}
    ${initial_address.address.neighborhood} -${' '}
    ${initial_address.address.cep} - ${initial_address.address.city.name},${' '}
    ${initial_address.address.city.state.uf}
  `;

  const lastCompletedStep = getLastCompletedStep(status_id);

  const getStepStatusFromLastCompleted = (index: number): StepStatus => {
    if (index < lastCompletedStep) {
      return 'done';
    }
    if (index === lastCompletedStep) {
      return 'in_progress';
    }
    return 'pending';
  };

  const getDoneDate = (statusId: TripStatus): Date | undefined => {
    if (!status_marks || status_marks.length === 0) return undefined;

    const statusMark = status_marks.find(
      ({ status_id: statusIdMark }) => statusIdMark === statusId,
    );

    if (!statusMark) {
      return undefined;
    } else {
      return new Date(statusMark.created_at);
    }
  };

  const cargoSteps = defaultSteps.map(({ name, statusIdWhenDone }, index) => {
    return {
      name,
      number: (index + 1).toString(),
      status: getStepStatusFromLastCompleted(index),
      address: name === 'Viagem' ? formattedInitialAddress : undefined,
      doneAt: getDoneDate(statusIdWhenDone),
    };
  });

  return (
    <FollowRouteContainer className="follow-route-details">
      <CargoStepper status_id={status_id} />
      <div className="follow-route-details__content">
        {cargoSteps.map((step) => (
          <CargoStepDetail
            key={step.name}
            name={step.name}
            number={step.number}
            status={step.status}
            address={step?.address}
            doneAt={step?.doneAt}
          />
        ))}

        {status_id === TripStatus.Finished && (
          <SecondaryButton type="button" onClick={onShowDeliveryInfo}>
            Detalhes de entrega
          </SecondaryButton>
        )}
      </div>
    </FollowRouteContainer>
  );
};
