import {
  ICreatedTrip,
  VehicleVolume,
  VehicleWeight,
} from './requestVehicleTypes';

export interface IVehiclesResponse {
  results: Vehicle[];
  total: number;
  page: number;
  limit: number;
}

export interface Vehicle {
  id: string;
  vehicle_type_id: string;
  name: string;
  plate: string;
  renavan: string;
  has_tracker: boolean;
  has_mopp: boolean;
  geolocation: Geolocation;
  vehicle_type: VehicleType;
  created_at: string;
  updated_at: string;
}

export interface Geolocation {
  type: string;
  coordinates: number[];
}

export interface VehicleType {
  id: string;
  bodywork_type_id?: number;
  vehicle_class_id?: number;
  percentage_per_cte: number;
  minimal_time_for_cancelation: number;
  transporter_cancelation_fee: number;
  shipper_cancelation_fee: number;
  minimal_radius: number;
  name: string;
  description: string;
  cargo_weight_id?: number;
  cargo_volume_id?: number;
  axis_number: number;
  filename: string;
  bodywork?: Bodywork;
  created_at: string;
  updated_at: string;
  vehicle_image_url: string;
  vehicle_class?: VehicleClass;
  weight?: VehicleWeight;
  volume?: VehicleVolume;
}

export interface Bodywork {
  id: number;
  name: string;
  created_at: string;
}

export interface VehicleClass {
  id: number;
  name: string;
  created_at: string;
}

export enum TripStatus {
  Pending = 1, // Aguardando transportadora aceitar
  Displacement, // Entrega em deslocamento
  Collecting, // Motorista indo coletar entrega
  OnGoing, // OnGoing = Aguardando motorista começar
  waitingPayment, // Aguardando pagamento
  Finished, // Motorista finalizou
  Canceled,
}

export interface INotification {
  id: string;
  trip_id: string;
  current_status_id: TripStatus;
  message: string;
  to_id: string;
  trip: ICreatedTrip;
  created_at?: string;
  read?: boolean;
}

export interface IDisplayNotification extends INotification {
  onClickCallback: () => void;
  displayMessage: string;
  // relativeDate: string;
  buttonText: string;
}

export type PaginatedResult<T> = {
  results: T[];
  total: number;
  page: number;
  limit: number;
};
