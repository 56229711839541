import styled from 'styled-components';

import truck_icon from '../../../../assets/icons/truck_icon.svg';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;

  width: fit-content;
  margin: 0 auto;

  padding: 15px 0 0;


  position: relative;
  .delivered-step__step {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;

    width: 100%;

    color: var(--green2c);
    font-size: clamp(7px, 1.2vw, 10px);
    line-height: 12px;
    font-weight: bold;
    text-transform: uppercase;

    white-space: pre-line;
    text-align: center;

    &.active {
      color: #706aa8;
    }
  }

  .delivered-step__dot {
    position: relative;
    width: 6px;
    height: 6px;

    margin-top: 10px;
    margin-left: 45%;

    background: var(--green2c);
    border-radius: 5px;

    &:before {
      @keyframes pulse {
        0% {
          transform: translate(-50%, -50%) scale(1);
        }
        50% {
          transform: translate(-50%, -50%) scale(1.5);
        }
        100% {
          transform: translate(-50%, -50%) scale(1);
        }
      }

      content: '';

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 30px;
      height: 30px;

      background-color: #DAFBFD80;
      border-radius: 50%;

      animation: pulse 1.5s ease-in-out infinite;
    }
  }

  .delivered-step__divider {
    position: relative;

    &:before {
      content: '';

      position: absolute;
      top: -8px;
      left: 12px;
      transform: translateX(50%) scaleX(-1);

      width: 20px;
      height: 18px;

      background: no-repeat center;
      background-image: url(${truck_icon});
      background-size: contain;
    }

    &:after {
      content: '';

      position: absolute;
      z-index: -1;
      top: 12px;
      left: -8px;

      width: 78px;
      height: 2px;

      background-color: #E3E1F7;
    }

    &.active {
      &:before {
        display: block;
      }

      &:after {
        background-color: #e8e6f8;
      }
    }
  }
`;
