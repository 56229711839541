import { useContext, useEffect, useState } from 'react';

import { AuthContext } from 'contexts/auth';
import api from 'services/api';
import { handleError } from 'services/errorHandler';

import { Input } from 'shared/components/molecules/Input/Input';
import { PhotoInput } from 'shared/components/molecules/PhotoInput/PhotoInput';
import { Modal } from 'shared/components/organisms/Modal/Modal';
import { EditCellPhoneComponent } from '../EditCellphoneComponent/EditCellPhoneComponent';
import { EditEmailComponent } from '../EditEmailComponent/EditEmailComponent';
import { EditPasswordComponent } from '../EditPasswordComponent/EditPasswordComponent';

import { Card, Container } from './_managerInfo';

import { toast } from 'react-toastify';
import { phoneFormat } from 'shared/utils/format';
import { User } from 'types/authTypes';
import photo_placeholder from '../../../../assets/icons/user_icon.svg';

export const ManagerInfo: React.FC = () => {
  const { user, setUser } = useContext(AuthContext);
  const email = user?.email;
  const cellphone = user?.cellphone;
  const avatar_url = user?.avatar_url;
  const manager =
    user?.legal_person?.responsible_name || user?.individual_person?.name || '';

  const [document, setDocument] = useState<File>();
  const [imageURL, setImageURL] = useState<string>();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [editingField, setEditingField] = useState<string>('email');

  const handleEditField = (editingField: string) => {
    setEditingField(editingField);

    setShowModal(true);
  };

  useEffect(() => {
    imageURL && document && editImage(document);
  }, [imageURL]);

  const editImage = async (avatar: File) => {
    const data = new FormData();

    data.append('avatar', avatar);

    try {
      const response = await api.patch<User>(`users/avatar`, data);

      if (response) {
        toast.success('Foto atualizada com sucesso');
        setUser(response.data);
      }
    } catch (error: any) {
      handleError(error.response?.data?.message || error.toString());
    }
  };

  const renderEditingField = () => {
    const selectedField = editingField;

    const editComponent: { [key: string]: JSX.Element } = {
      email: (
        <EditEmailComponent
          setShowModal={setShowModal}
          defaultValue={email || ''}
        />
      ),
      cellphone: (
        <EditCellPhoneComponent
          setShowModal={setShowModal}
          defaultValue={cellphone || ''}
        />
      ),
      password: <EditPasswordComponent setShowModal={setShowModal} />,
    };

    return editComponent[selectedField];
  };

  return (
    <>
      <Container className="manager-info">
        <Card className="manager-info__card">
          <div className="card__title">
            <b>Minhas informações</b>
            Edite suas informações aqui
          </div>

          <div className="card__body">
            <div className="card__photo">
              <img
                src={avatar_url || imageURL || photo_placeholder}
                alt="foto do responsável"
              />
              <PhotoInput
                setInputFile={setDocument}
                setImageURL={setImageURL}
              />
            </div>

            <div className="card__manager-name">{manager}</div>

            <div className="card__input-wrapper">
              <Input
                placeholder={email || 'Email'}
                disabled
                onClick={() => handleEditField('email')}
              />
              <Input
                placeholder={(cellphone && phoneFormat(cellphone)) || 'Celular'}
                disabled
                onClick={() => handleEditField('cellphone')}
              />
            </div>

            <div className="card__password-wrapper">
              <div className="card__password">
                Senha
                <input type="password" value="*********" disabled />
              </div>

              <span
                className="card__password-anchor"
                onClick={() => handleEditField('password')}
              >
                Alterar senha
              </span>
            </div>
          </div>
        </Card>
      </Container>

      <Modal showModal={showModal} setShowModal={setShowModal}>
        {renderEditingField()}
      </Modal>
    </>
  );
};
