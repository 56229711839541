import { Container } from './_deliveredStep';

export const DeliveredStep: React.FC = () => {
  return (
    <Container>
      <div className="delivered-step__divider" />
      <div className="delivered-step__step">
        <div className="delivered-step__dot" />
        {`ENTREGA CONCLUÍDA\n(COMPROVANTE ENVIADO)`}
      </div>
    </Container>
  );
};
