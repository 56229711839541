import styled from 'styled-components';

export const Container = styled.form`
  &.edit-company {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;

    width: 100%;
    max-width: 380px;
    min-height: 200px;

    padding: 0 15px;

    .edit-company__title {
      font-size: 1.5rem;
      color: var(--purple22);
      font-weight: bold;
    }

    .icon-input {
      margin: 0;
      padding: 0;

      .icon-input__wrapper {
        width: 100%;
        height: 40px;
        margin: 0;
      }

      .input__input {
        justify-content: flex-start;

        font-size: 1.4rem;
        text-align: left;
      }
    }

    .secondary-button {
      font-size: 1rem;
    }
  }
`;
