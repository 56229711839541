import React, { createContext, useState, useEffect } from 'react';

import api from 'services/api';
import { handleError } from 'services/errorHandler';
import { IAuthentication, User } from 'types/authTypes';

interface AuthContextItems {
  signed: boolean;
  user: User | null;
  setUser: Function;
  loading: boolean;
  signIn(credentials: object): Promise<void>;
  signOut(): void;
  rememberUser: boolean;
  setRememberUser: Function;
  checkIfFirstTimeUsingApplication: Function;
  token: string;
}

export const AuthContext = createContext<AuthContextItems>(
  {} as AuthContextItems,
);

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [rememberUser, setRememberUser] = useState<boolean>(false);
  const [token, setToken] = useState<string>('');

  useEffect(() => {
    loadLocalStorageData();
    checkIfFirstTimeUsingApplication();
  }, []);

  const checkIfFirstTimeUsingApplication = () => {
    const checkedGuest = localStorage.getItem('@vagoo_emb isGuest');

    const isGuest = checkedGuest === 'not_guest';

    return isGuest;
  };

  const loadLocalStorageData = async () => {
    setLoading(true);

    const storageUser = localStorage.getItem('@vagoo_emb user');
    const storageToken = localStorage.getItem('@vagoo_emb accessToken');
    const storageRefreshToken = localStorage.getItem('@vagoo_emb refreshToken');

    if (storageToken && storageUser && storageRefreshToken) {
      setUser(JSON.parse(storageUser));

      setTokenOnApiHeaders(storageToken);

      refreshToken(storageRefreshToken);

      setRememberUser(true);

      setToken(storageToken);
    }
    setLoading(false);
  };

  const refreshToken = async (refreshToken: string) => {
    const token = {
      refresh_token: refreshToken,
    };

    try {
      const response = await api.put<IAuthentication>(
        `sessions/refresh-token`,
        token,
      );

      if (response) {
        setLocalStorage(
          response.data.user,
          response.data.access_token,
          response.data.refresh_token,
        );
      }
    } catch (error: any) {
      handleError(error?.response?.data?.message || error.toString());

      signOut();
    }
  };

  const signIn = async (credentials: object) => {
    try {
      const response = await api.post<IAuthentication>('sessions', credentials);

      if (response) {
        if (response.data.user?.role_id !== 2) {
          handleError('A conta do usuário deve ser do tipo embarcador');
          return;
        }

        setUser(response.data.user);

        setTokenOnApiHeaders(response.data.access_token);
        setToken(response.data.access_token);

        localStorage.setItem('@vagoo_emb isGuest', 'not_guest');

        rememberUser &&
          setLocalStorage(
            response.data.user,
            response.data.access_token,
            response.data.refresh_token,
          );
      }
    } catch (error: any) {
      handleError(error?.response?.data?.message || error.toString());
    }
  };

  const signOut = async () => {
    try {
      const response = await api.delete(`sessions/logout`);

      if (response) {
        eraseLocalData();
      }
    } catch (error: any) {
      handleError(error?.response?.data?.message || error.toString());

      eraseLocalData();
    }
  };

  const eraseLocalData = () => {
    localStorage.clear();
    localStorage.setItem('@vagoo_emb user', '');
    localStorage.setItem('@vagoo_emb accessToken', '');
    localStorage.setItem('@vagoo_emb refreshToken', '');

    setUser(null);
  };

  const setLocalStorage = (
    user: object,
    token: string,
    refreshToken: string,
  ) => {
    localStorage.setItem('@vagoo_emb user', JSON.stringify(user));
    localStorage.setItem('@vagoo_emb accessToken', token);
    localStorage.setItem('@vagoo_emb refreshToken', refreshToken);
  };

  const setTokenOnApiHeaders = (token: string) => {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  };

  const updateUser = (userData: User) => {
    const storageToken = localStorage.getItem('@vagoo_emb accessToken') || '';
    const storageRefreshToken =
      localStorage.getItem('@vagoo_emb refreshToken') || '';

    const updatedUser = {
      ...user,
      ...userData,
    };

    setUser(updatedUser);
    if (storageToken) {
      setLocalStorage(updatedUser, storageToken, storageRefreshToken);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user: user,
        setUser: updateUser,
        loading,
        signIn,
        signOut,
        rememberUser,
        setRememberUser,
        checkIfFirstTimeUsingApplication,
        token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
