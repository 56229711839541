import { createContext, useEffect, useState } from 'react';
import useAuth from 'shared/hooks/useAuth.hook';
import { io, Socket } from 'socket.io-client';


interface ISocketContextData {
  socket?: Socket;
}

export const SocketContext = createContext({} as ISocketContextData);

interface ISocketContextProviderProps {
  children: React.ReactNode;
}

export const SocketProvider = ({ children }: ISocketContextProviderProps) => {
  const [socket, setSocket] = useState<Socket>();
  const { token } = useAuth();

  useEffect(() => {
    if (!token) return () => {};

    const API_URL = process.env.REACT_APP_API || '';

    const s = io(API_URL, {
      transports: ['websocket', 'polling'],
      query: {
        token,
      }
    });

    setSocket(s);

    return () => {
      socket?.disconnect();
    };
  }, [token]);

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};
