import { phoneFormat } from 'shared/utils/format';
import { ResponsibleUser, Vehicle } from 'types/requestVehicleTypes';
import { ShipperDetailCardContainer } from './_shipperDetailCard';

interface Props {
  vehicle: Vehicle;
  responsible?: ResponsibleUser;
}

export const ShipperDetailCard = ({ vehicle, responsible }: Props) => {
  const isResponsibleTransporter = responsible?.transporter != null;

  const responsibleName = isResponsibleTransporter
    ? responsible?.transporter?.user?.legal_person?.corporate_name || '-'
    : responsible?.driver?.user?.individual_person?.name || '-';

  const responsiblePhone = phoneFormat(responsible?.cellphone || '');

  return (
    <ShipperDetailCardContainer className="shipper-detail-card">
      <div className="shipper-detail-card__info-wrapper">
        <div className="shipper-detail-card__company">
          <div className="shipper-detail-card__company-name">
            <span>{responsibleName}</span>
          </div>

          <span className="shipper-detail-card__company-phone">
            <small>Telefone</small>
            {responsiblePhone}
          </span>
        </div>

        <div className="shipper-detail-card__truck-info">
          <div className="truck-info__detail">
            <div className="truck-info__icon truck-info__icon--truck" />
            <span>Veículo</span>
            {vehicle?.vehicle_type?.name || '-'}
          </div>

          <div className="truck-info__detail">
            <div className="truck-info__icon truck-info__icon--weight" />
            <span>Peso</span>
            {vehicle?.vehicle_type?.weight
              ? (vehicle?.vehicle_type?.weight?.max / 1000).toLocaleString(
                  'pt-BR',
                  {
                    maximumFractionDigits: 1,
                  },
                ) + 'ton'
              : '-'}
          </div>

          <div className="truck-info__detail">
            <div className="truck-info__icon truck-info__icon--cubage" />
            <span>Cubagem</span>
            {vehicle?.vehicle_type?.volume?.max || '-'} m³
          </div>

          <div className="truck-info__detail">
            <div className="truck-info__icon truck-info__icon--plate" />
            <span>Placa</span>
            {vehicle?.plate}
          </div>
        </div>
      </div>
    </ShipperDetailCardContainer>
  );
};
