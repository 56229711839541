import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthProvider } from 'contexts/auth';
import Routes from './routes';
import GlobalStyle from './styles/global';
import { SocketProvider } from 'contexts/socket';
import { NotificationsProvider } from 'contexts/notifications';
import ErrorBoundary from 'shared/components/organisms/ErrorBoundary/ErrorBoundary';

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <AuthProvider>
          <SocketProvider>
            <NotificationsProvider>
              <Routes />
            </NotificationsProvider>
          </SocketProvider>
        </AuthProvider>
      </BrowserRouter>
      <GlobalStyle />
      <ToastContainer autoClose={3000} />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);
