import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { editShipperSchema } from 'shared/utils/schemas';

import { SecondaryButton } from 'shared/components/atoms/SecondaryButton/SecondaryButton';
import { Input } from 'shared/components/molecules/Input/Input';
import { Modal } from 'shared/components/organisms/Modal/Modal';

import { maskCpfOrCnpj } from 'shared/utils/format';

import {
  Card,
  Container,
  EditIcon,
  PillSelector,
  PinIcon,
  Radio,
} from './_companyInfo';

import { EditCompanyAddressComponent } from '../EditCompanyAddressComponent/EditCompanyAddressComponent';
import { EditCompanyComponent } from '../EditCompanyComponent/EditCompanyComponent';

import api from 'services/api';
import { handleError } from 'services/errorHandler';
import { FeedbackModal } from 'shared/components/organisms/FeedbackModal/FeedbackModal';
import useAuth from 'shared/hooks/useAuth.hook';
import { IShipperEdit } from 'types/generalTypes';
import { AddressClass } from 'types/requestVehicleTypes';
import { EditPersonComponent } from '../EditPersonComponent/EditPersonComponent';

const formatAddress = (address?: AddressClass): string => {
  if (!address) {
    return '';
  }

  return `${address.street}
  ${', '}
  ${address.number} ${', '}
  ${address.neighborhood} ${' - '}
  ${address.cep}`;
};

export const CompanyInfo: React.FC = () => {
  const { user, setUser } = useAuth();

  const {
    register,
    formState: { isValid, isSubmitting, isDirty },
    watch,
    handleSubmit,
  } = useForm<IShipperEdit>({
    resolver: yupResolver(editShipperSchema),
    mode: 'onChange',
    defaultValues: {
      shipper: {
        has_ddr: String(user?.shipper.has_insurance),
        has_insurance: String(user?.shipper.has_ddr),
      },
    },
  });

  const [showModal, setShowModal] = useState<boolean>(false);
  const [editingField, setEditingField] = useState<string>('');
  const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false);

  const company = user?.legal_person;
  const individualPerson = user?.individual_person;

  const securityIsActive = (option: string) => {
    const insuranceSelected = watch('shipper.has_insurance');
    return insuranceSelected === option ? 'active' : '';
  };

  const handleEditField = (editingField: string) => {
    setEditingField(editingField);

    setShowModal(true);
  };

  const handleSubmitInsuranceData = async (data: IShipperEdit) => {
    const { shipper } = data;

    try {
      const response = await api.put('/users', {
        shipper,
      });

      setUser(response.data);
      setShowFeedbackModal(true);
    } catch (error: any) {
      handleError(
        error?.response?.data?.message ||
          error?.message ||
          'Algo deu errado, tente novamente mais tarde',
      );
    }
  };

  const renderEditingField = () => {
    const editCorporationName = (
      <EditCompanyComponent
        setShowModal={setShowModal}
        modalTitle="Razão social"
        field={editingField}
        defaultValue={company?.corporate_name || 'Razão social'}
      />
    );

    const editCNPJ = (
      <EditCompanyComponent
        setShowModal={setShowModal}
        modalTitle="CNPJ/CPF"
        field={editingField}
        defaultValue={company?.cnpj || 'CNPJ/CPF'}
      />
    );

    const editTradeName = (
      <EditCompanyComponent
        setShowModal={setShowModal}
        modalTitle="Nome"
        field={editingField}
        defaultValue={company?.trade_name || individualPerson?.name || 'Nome'}
      />
    );

    const editAddress = (
      <EditCompanyAddressComponent
        setShowModal={setShowModal}
        address_id={company?.address_id || individualPerson?.address_id}
      />
    );

    const editCPF = (
      <EditPersonComponent
        defaultValue={individualPerson?.cpf || 'CPF'}
        field={editingField}
        setShowModal={setShowModal}
        modalTitle="CNPJ/CPF"
      />
    );

    const editName = (
      <EditPersonComponent
        defaultValue={individualPerson?.name || 'Nome'}
        field={editingField}
        setShowModal={setShowModal}
        modalTitle="Nome"
      />
    );

    const editComponent: { [key: string]: JSX.Element } = {
      corporate_name: editCorporationName,
      cnpj: editCNPJ,
      trade_name: editTradeName,
      address: editAddress,
      cpf: editCPF,
      name: editName,
    };

    return editComponent[editingField];
  };

  const formattedAddress = formatAddress(
    company?.address || individualPerson?.address,
  );

  const document = maskCpfOrCnpj(company?.cnpj || individualPerson?.cpf || '');

  const name = company?.trade_name || individualPerson?.name || '';

  return (
    <>
      <Container className="company-info">
        <Card className="company-info__card">
          <div className="card__title">
            <b>Informações da empresa</b>
            Edite as informações da empresa aqui
          </div>

          <div className="card__body">
            <div className="card__input-wrapper">
              {company && (
                <Input
                  placeholder={company.corporate_name || 'Razão social'}
                  disabled
                  onClick={() => handleEditField('corporate_name')}
                />
              )}

              <Input
                placeholder={document || 'CNPJ/CPF'}
                disabled
                onClick={() => {
                  if (company) {
                    handleEditField('cnpj');
                  } else {
                    handleEditField('cpf');
                  }
                }}
              />

              <Input
                placeholder={name || 'Nome'}
                disabled
                onClick={() => {
                  if (company) {
                    handleEditField('trade_name');
                  } else {
                    handleEditField('name');
                  }
                }}
              />

              <div className="company-info__address-wrapper">
                <div className="company-info__address-title">Endereço</div>
                <div
                  className="company-info__address-container"
                  onClick={() => handleEditField('address')}
                >
                  <PinIcon />
                  <span className="company-info__address-text">
                    {formattedAddress}
                  </span>
                  <EditIcon />
                </div>
              </div>

              {/* <form
                className="company-info__insurance"
                onSubmit={handleSubmit(handleSubmitInsuranceData)}
              >
                <b>Seguro</b>
                Opção escolhida
                <PillSelector className="insurance__radio-wrapper">
                  <label
                    className={`
                    insurance__radio
                    radio--transporter ${securityIsActive('false')}`}
                  >
                    <input
                      type="radio"
                      value="false"
                      {...register('shipper.has_insurance')}
                    />
                    Por conta do Vagoo
                  </label>

                  <label
                    className={`
                    insurance__radio
                    radio--shipper ${securityIsActive('true')}`}
                  >
                    <input
                      type="radio"
                      value="true"
                      {...register('shipper.has_insurance')}
                    />
                    Por conta do embarcador
                  </label>
                </PillSelector>
                {watch('shipper.has_insurance') === 'true' && (
                  <>
                    <div className="insurance__ddr">
                      Fornece DDR?
                      <Radio className="ddr-radio">
                        <label className="ddr-radio__option">
                          <input
                            {...register('shipper.has_ddr')}
                            type="radio"
                            value="true"
                          />
                          <div className="radio__selection">Sim</div>
                        </label>

                        <label className="ddr-radio__option">
                          <input
                            {...register('shipper.has_ddr')}
                            type="radio"
                            value="false"
                          />
                          <div className="radio__selection">Não</div>
                        </label>
                      </Radio>
                    </div>
                  </>
                )}
                <SecondaryButton
                  disabled={isSubmitting || !isDirty || !isValid}
                >
                  Salvar alterações no seguro
                </SecondaryButton>
              </form> */}
            </div>
          </div>
        </Card>
      </Container>

      <Modal showModal={showModal} setShowModal={setShowModal}>
        {renderEditingField()}
      </Modal>

      <FeedbackModal
        message="Alterado com sucesso!"
        showModal={showFeedbackModal}
        setShowModal={setShowFeedbackModal}
      />
    </>
  );
};
