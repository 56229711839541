import { LoadingScreen } from 'pages/LoadingScreen/LoadingScreen';
import { useContext } from 'react';
import { AuthContext } from '../contexts/auth';

import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';

const Routes = () => {
  const { signed, loading } = useContext(AuthContext);

  if (loading) {
    return <LoadingScreen />;
  }
  return signed ? <PrivateRoutes /> : <PublicRoutes />;
};
export default Routes;
