import styled from 'styled-components';

interface Props {
  color: string;
}

export const Container = styled.div<Props>`
  &.cargo-details-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    color: var(--purple22);

    animation: entering 0.5s ease-in-out;

    .cargo-info__request {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 3px;

      width: 100%;

      padding: 15px 20px;

      background-color: #cfcbf142;
    }

    .cargo-info__request-info {
      display: flex;
      gap: 5px;

      color: var(--purple22);

      span {
        min-width: 150px;

        font-size: 1rem;
      }

      p {
        font-size: 0.9rem;
        font-weight: bold;
        /* word-wrap: break-word;
        word-break: break-all; */
      }
    }

    .secondary-button {
      width: 100%;
      max-width: 370px;
      min-height: 50px;

      color: #43386b;
      text-transform: uppercase;

      background-color: #cfcbf1;
    }
  }
`;
