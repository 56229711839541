import React from 'react';

import { Container } from './_statusCardComponent';

interface Props {
  background: string;
  color: string;
  title: string;
  description: string;
  quantity: number;
}

export const StatusCardComponent: React.FC<Props> = ({
  background,
  color,
  title,
  description,
  children,
  quantity,
}) => {
  return (
    <Container className="status-card" background={background} color={color}>
      <div className="status-card__header">
        <span className="status-card__status">
          {title}
          {`(${quantity})`}
        </span>
        <span className="status-card__description">{description}</span>
      </div>

      <div className="status-card__body">{children}</div>
    </Container>
  );
};
