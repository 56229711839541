import styled from 'styled-components';

import map_icon from '../../../../assets/icons/map_icon.svg';
import pink_pin_icon from '../../../../assets/icons/pink_pin_icon.svg';
import truck_icon from '../../../../assets/icons/truck_icon.svg';

export const CargoStepperContainer = styled.div`
  &.cargo-stepper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    width: 100%;
    max-width: 370px;

    margin: 0 auto;
    padding: 15px 0;

    .cargo-stepper__title {
      position: relative;

      display: flex;
      gap: 10px;

      font-size: 1rem;
      font-weight: bold;

      &::before {
        content: '';
        position: relative;

        width: 20px;
        height: 18px;

        background: no-repeat center;
        background-image: url(${map_icon});
      }
    }

    .cargo-stepper__step-container {
      display: flex;
      justify-content: space-between;

      padding-top: 15px;
    }

    .cargo-stepper__step {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 10px;

      width: 100%;
      height: 50px;

      color: #868e96;
      font-size: clamp(7px, 1.2vw, 10px);
      font-weight: bold;
      text-transform: uppercase;

      &.active {
        color: #706aa8;
      }
    }

    .cargo-stepper__step__initial {
      width: 17px;
      height: 24px;

      background: no-repeat center;
      background-color: #fff;
      background-image: url(${pink_pin_icon});
      background-size: contain;
    }

    .cargo-stepper__step__divider {
      position: relative;

      &:before {
        content: '';

        display: none;

        position: absolute;
        top: -8px;
        left: -28px;
        transform: translateX(-50%) scaleX(-1);

        width: 20px;
        height: 18px;

        background: no-repeat center;
        background-image: url(${truck_icon});
        background-size: contain;
      }

      &:after {
        content: '';

        position: absolute;
        z-index: -1;
        top: 12px;
        left: -28px;
        transform: translateX(-50%);

        width: 78px;
        height: 2px;

        background-color: #ced4da;
      }

      &.active {
        &:before {
          display: block;
        }

        &:after {
          background-color: #e8e6f8;
        }
      }
    }

    .cargo-stepper__step__dot {
      position: relative;

      width: 6px;
      height: 6px;

      margin-top: 10px;
      margin-left: 15px;

      background: var(--purple22);
      border-radius: 5px;

      &.active:after {
        @keyframes pulse {
          0% {
            transform: translate(-50%, -50%) scale(1);
          }
          50% {
            transform: translate(-50%, -50%) scale(1.5);
          }
          100% {
            transform: translate(-50%, -50%) scale(1);
          }
        }

        content: '';

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        width: 30px;
        height: 30px;

        background-color: rgba(207, 203, 241, 0.5);
        border-radius: 50%;

        animation: pulse 1.5s ease-in-out infinite;
      }
    }
  }
`;
