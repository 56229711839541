import { forwardRef } from 'react';
import { Container, StyledInput, StyledLabel } from './_generalInput';

interface GeneralInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  inputClassName?: string;
  labelClassName?: string;
  containerClassName?: string;
}

export const GeneralInput = forwardRef<HTMLInputElement, GeneralInputProps>(
  (
    {
      containerClassName,
      inputClassName,
      labelClassName,
      label,
      name,
      ...rest
    },
    ref,
  ) => {
    return (
      <Container className={containerClassName}>
        {label && (
          <StyledLabel htmlFor={name} className={labelClassName}>
            {label}
          </StyledLabel>
        )}
        <StyledInput
          name={name}
          id={name}
          {...rest}
          className={inputClassName}
          ref={ref}
        />
      </Container>
    );
  },
);
