import { TripStatus } from "types/mainTypes";

export const requestingStatuses = [
  TripStatus.Pending,
]

export const onGoingStatuses = [
  TripStatus.waitingPayment,
  TripStatus.Displacement,
  TripStatus.Collecting,
  TripStatus.OnGoing,
]

export const deliveredStatuses = [TripStatus.Finished];
