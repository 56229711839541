import { VehicleType } from 'types/mainTypes';
import {
  ICreatedTrip,
  RequestTripFormData,
  Option,
  VehicleWeight,
} from 'types/requestVehicleTypes';

const getOptionFromValue = (value: string | number, label?: string) => ({
  value,
  label: label || value.toString(),
});

const formatNumber = (number: number): string => {
  return number.toLocaleString('pt-BR', {
    maximumFractionDigits: 1,
  });
};

// Field is too small to display entire name field
const formatWeightOption = (vehicleWeight: VehicleWeight): Option => {
  const { min, max } = vehicleWeight;

  let formattedMin = '';
  let formattedMax = '';

  if (min >= 1000) {
    formattedMin = `${formatNumber(min / 1000)} ton`;
  } else {
    formattedMin = `${formatNumber(min)} kg`;
  }

  if (max >= 1000) {
    formattedMax = `${formatNumber(max / 1000)} ton`;
  } else {
    formattedMax = `${formatNumber(max)} kg`;
  }

  return getOptionFromValue(
    vehicleWeight.id,
    `${formattedMin}-${formattedMax}`,
  );
};

export const getFormFieldsFromVehicleType = (
  vehicleType: VehicleType,
  initialData?: Partial<RequestTripFormData>,
): Partial<RequestTripFormData> => {
  const { volume, weight, bodywork, id, name } = vehicleType;

  return {
    ...initialData,
    cargo_bodywork_id: bodywork
      ? getOptionFromValue(bodywork.id, bodywork.name)
      : undefined,
    volume: volume ? getOptionFromValue(volume.id, volume.name) : undefined,
    cargo_weight: weight ? formatWeightOption(weight) : undefined,
    vehicle_type_id: {
      label: name,
      value: id,
    },
  };
};

export interface CreateTripRequest {
  cargo_type: string;
  cargo_value: number;
  search_radius: number;
  observation?: string;
  insurance_fee: number;
  initial_address: CreateTripAddress;
  delivery_address: CreateTripAddress;
  vehicle_type_id: string;
  colecting_contact?: string;
  delivery_contact?: string;
}

export interface CreateTripAddress {
  street: string;
  neighborhood: string;
  cep: string;
  number: string;
  city_ibge_code: number;
}

export const formatDataForRequest = (
  data: RequestTripFormData,
): CreateTripRequest => {
  const {
    cargo_type,
    cargo_value,
    delivery_address,
    initial_address,
    search_radius,
    observation,
    vehicle_type_id,
    colecting_contact,
    delivery_contact,
  } = data;

  const { city, state, ...initialAddress } = initial_address;
  const {
    city: deliveryCity,
    state: deliveryState,
    ...deliveryAddress
  } = delivery_address;

  return {
    initial_address: {
      ...initialAddress,
      city_ibge_code: Number(initialAddress.city_ibge_code),
    },
    delivery_address: {
      ...deliveryAddress,
      city_ibge_code: Number(deliveryAddress.city_ibge_code),
    },
    vehicle_type_id,
    cargo_type,
    cargo_value: Number(cargo_value),
    search_radius: Number(search_radius.value),
    observation,
    insurance_fee: 0,
    colecting_contact,
    delivery_contact,
  };
};

export const mapTripToForm = (
  data: ICreatedTrip,
): Partial<RequestTripFormData> => {
  const {
    cargo_type,
    cargo_value,
    delivery_address,
    id,
    initial_address,
    observation,
    vehicle,
  } = data;
  const partialData = {
    cargo_type,
    cargo_value: cargo_value.toString(),
    delivery_address: {
      cep: delivery_address.address.cep,
      city: delivery_address.address.city.name,
      city_ibge_code: delivery_address.address.city.ibge_code,
      neighborhood: delivery_address.address.neighborhood,
      number: delivery_address.address.number,
      street: delivery_address.address.street,
      state: delivery_address.address.city.state.uf,
    },
    initial_address: {
      cep: initial_address.address.cep,
      city: initial_address.address.city.name,
      city_ibge_code: initial_address.address.city.ibge_code,
      neighborhood: initial_address.address.neighborhood,
      number: initial_address.address.number,
      street: initial_address.address.street,
      state: initial_address.address.city.state.uf,
    },
    observation,
    id,
  };

  const formFields = getFormFieldsFromVehicleType(
    vehicle.vehicle_type,
    partialData,
  );

  return formFields;
};
