import styled from 'styled-components';

import truck from '../../../../assets/icons/truck.svg';
import cubage from '../../../../assets/icons/cubage.svg';
import plate from '../../../../assets/icons/plate.svg';
import paper_roll_icon from '../../../../assets/icons/paper_roll_icon.svg';

export const ShipperDetailCardContainer = styled.div`
  &.shipper-detail-card {
    width: 100%;

    .shipper-detail-card__info-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      width: 100%;
      max-width: 380px;

      margin: 0 auto;

      border: 1px solid #cfcbf1;
      border-radius: 10px;
    }

    .shipper-detail-card__company {
      display: flex;

      width: 100%;

      border-bottom: 1px solid #cfcbf1;
    }

    .shipper-detail-card__company-name {
      display: inline-flex;
      flex-direction: column;
      margin: auto 0;

      font-size: 1rem;
      font-weight: bold;

      max-width: 50%;
      padding: 15px;

      border-right: 1px solid #cfcbf1;

      small {
        font-size: 0.8rem;
        font-weight: normal;
      }
    }

    .shipper-detail-card__company-phone {
      display: inline-flex;
      flex-direction: column;

      padding: 15px;

      font-size: 0.9rem;
      font-weight: bold;

      max-width: 50%;

      small {
        font-size: 0.8rem;
        font-weight: normal;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }

    .shipper-detail-card__truck-info {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      width: 100%;
      max-width: 380px;

      padding: 15px;

      font-size: 1rem;
      font-weight: bold;

      .truck-info__detail {
        display: flex;
        flex-direction: column;
        gap: 5px;

        span {
          font-size: 0.7rem;
          font-weight: normal;
        }
      }

      .truck-info__icon {
        width: 25px;
        height: 25px;

        background: no-repeat center;
        background-size: contain;
        &.truck-info__icon--truck {
          background-image: url(${truck});
        }

        &.truck-info__icon--weight {
          background-image: url(${cubage});
        }

        &.truck-info__icon--cubage {
          background-image: url(${paper_roll_icon});
        }

        &.truck-info__icon--plate {
          background-image: url(${plate});
        }
      }
    }
  }
`;
