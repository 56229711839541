import { SecondaryButton } from 'shared/components/atoms/SecondaryButton/SecondaryButton';
import { CargoDataCard } from 'shared/components/organisms/CargoDataCard/CargoDataCard';
import { CargoNotesCard } from 'shared/components/organisms/CargoNotesCard/CargoNotesCard';
import { ShipperDetailCard } from 'shared/components/organisms/ShipperDetailsCard/ShipperDetailCard';
import { TripStatus } from 'types/mainTypes';
import { ICreatedTrip } from 'types/requestVehicleTypes';
import { DeliveredStep } from '../DeliveredStep/DeliveredStep';
import { DeliveryProof } from '../DeliveryProof/DeliveryProof';

import { Container } from './_cargoDetailsInfo';

interface Props {
  cargoInfo: ICreatedTrip;
  cancelTrip: (id: string) => Promise<void>;
  showDeliveryInfo: boolean;
}

const cancellableStatuses: TripStatus[] = [
  TripStatus.Pending,
  TripStatus.Displacement,
  TripStatus.waitingPayment,
];

export const CargoDetailsInfo = ({
  cargoInfo,
  cancelTrip,
  showDeliveryInfo,
}: Props) => {
  const {
    status_id,
    vehicle,
    created_at,
    initial_address,
    delivery_address,
    responsible,
    document,
  } = cargoInfo;

  const color = () => {
    const setBackground: { [key: string]: string } = {
      request: '#CFCBF1',
      load: 'var(--purple22)',
      delivery: 'var(--green2c)',
    };

    return setBackground[status_id];
  };

  return (
    <Container className="cargo-details-info" color={color()}>
      {showDeliveryInfo && <DeliveredStep />}
      <div className="cargo-info__request">
        <div className="cargo-info__request-info">
          <span>Data da solicitação:</span>
          <p>{new Date(created_at).toLocaleDateString()}</p>
        </div>
        <div className="cargo-info__request-info">
          <span>Hora da solicitação:</span>
          <p>{new Date(created_at).toLocaleTimeString().slice(0, 5)}</p>
        </div>
        <div className="cargo-info__request-info">
          <span>Endereço de coleta:</span>
          <p>
            {initial_address.address.street}, {initial_address.address.number} -{' '}
            {initial_address.address.neighborhood} -{' '}
            {initial_address.address.cep} - {initial_address.address.city.name},{' '}
            {initial_address.address.city.state.uf}
          </p>
        </div>
        <div className="cargo-info__request-info">
          <span>Endereço de entrega:</span>
          <p>
            {delivery_address.address.street}, {delivery_address.address.number}{' '}
            - {delivery_address.address.neighborhood} -{' '}
            {delivery_address.address.cep} -{' '}
            {delivery_address.address.city.name},{' '}
            {delivery_address.address.city.state.uf}
          </p>
        </div>
      </div>

      <ShipperDetailCard vehicle={vehicle} responsible={responsible} />

      <CargoDataCard cargo={cargoInfo} />

      <CargoNotesCard cargo={cargoInfo} />

      {cancellableStatuses.includes(status_id) && (
        <SecondaryButton onClick={() => cancelTrip(cargoInfo.id)}>
          Cancelar solicitação
        </SecondaryButton>
      )}

      {showDeliveryInfo && <DeliveryProof document={document} />}
    </Container>
  );
};
