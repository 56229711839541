import React from 'react';

import logo from '../../../../assets/icons/logo_vagoo.svg';

import { Input } from 'shared/components/molecules/Input/Input';
import { PrimaryButton } from 'shared/components/atoms/PrimaryButton/PrimaryButton';

import { Logo, ManagerRegisterContainer } from './_managerRegisterComponent';

import { IManager } from 'types/registerTypes';
import { useHistory } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { managerSchema } from 'shared/utils/schemas';
import { phoneFormat } from 'shared/utils/format';

interface Props {
  setCurrentSection: Function;
  setManagerFormData: Function;
}

export const ManagerRegisterComponent: React.FC<Props> = ({
  setCurrentSection,
  setManagerFormData,
}) => {
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting },
    control,
  } = useForm<IManager>({
    resolver: yupResolver(managerSchema),
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<IManager> = (data) => {
    setCurrentSection('company');

    setManagerFormData(data);
  };

  return (
    <ManagerRegisterContainer
      className="manager-register-container"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="manager-register-container__form-container">
        <Logo
          className="header__logo"
          src={logo}
          alt="Logo Vagoo"
          width="280"
          height="60"
        />

        <div className="form">
          <h1 className="form__title">
            <b>Cadastro</b>
            Informações sobre o responsável
          </h1>

          <Input
            placeholder={'Nome completo'}
            register={{ ...register('responsible_name') }}
          />

          <Input
            type="e-mail"
            placeholder={'E-mail'}
            register={{ ...register('email') }}
          />

          <Controller
            name="cellphone"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <Input
                placeholder={'Número do Celular'}
                maxLength={15}
                inputValue={phoneFormat(field.value || '')}
                setInputValue={field.onChange}
              />
            )}
          />

          <PrimaryButton type="submit" disabled={isSubmitting || !isValid}>
            Próximo
            <div className="primary-button__double-chevron" />
          </PrimaryButton>

          <div
            className="form__registered"
            onClick={() => history.push('login')}
          >
            Já tenho cadastro! <span>Logar</span>
          </div>
        </div>
      </div>
    </ManagerRegisterContainer>
  );
};
