import React, { useState } from 'react';
import { PrimaryButton } from 'shared/components/atoms/PrimaryButton/PrimaryButton';
import { SecondaryButton } from 'shared/components/atoms/SecondaryButton/SecondaryButton';

import { Header } from 'shared/components/organisms/Header/Header';
import { CompanyInfo } from './components/CompanyInfo/CompanyInfo';
import { ManagerInfo } from './components/ManagerInfo/ManagerInfo';
import { PaymentInformationComponent } from './components/PaymentInformationComponent/PaymentInformation';

import { Container, SettingsDisplay, SettingsOptions } from './_settingsPage';

export const SettingsPage: React.FC = () => {
  const [currentSection, setCurrentSection] = useState<string>('');

  const renderSection = () => {
    const section: { [key: string]: JSX.Element } = {
      payment: <PaymentInformationComponent />,
      manager: <ManagerInfo />,
      company: <CompanyInfo />,
    };

    return section[currentSection];
  };

  return (
    <>
      <Header />
      <Container className="settings">
        <SettingsOptions className="settings__options">
          <div className="options__title">
            <div className="options__icon" />
            Configurações
          </div>

          <PrimaryButton onClick={() => setCurrentSection('payment')}>
            Pagamento <div className="options__arrow-icon" />
          </PrimaryButton>

          <SecondaryButton onClick={() => setCurrentSection('manager')}>
            Minhas informações <div className="options__arrow-icon" />
          </SecondaryButton>

          <PrimaryButton onClick={() => setCurrentSection('company')}>
            Informações da empresa <div className="options__arrow-icon" />
          </PrimaryButton>
        </SettingsOptions>

        <SettingsDisplay className="settings__display">
          {renderSection()}
        </SettingsDisplay>
      </Container>
      ;
    </>
  );
};
