import styled from 'styled-components';

export const Container = styled.div`
  &.icon-input {
    width: 100%;
  }

  .icon-input__wrapper {
    display: flex;
    align-items: center;
    gap: 10px;

    width: 90%;
    height: 45px;

    margin: 0 auto;

    border-bottom: 2px solid #adb5bd;

    transition: all 0.5s;

    &:focus-within {
      border-bottom: 2px solid var(--green2c);
    }
  }

  .input__input {
    display: flex;
    align-items: center;

    width: 90%;
    border: 0;

    font-size: 1.8rem;
    color: var(--purple22);
    text-align: center;

    background-color: transparent;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #adb5bd;
    }
  }
  input[type='number'] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
  }
`;
