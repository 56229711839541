import { realCurrency } from 'shared/utils/format';
import { ICreatedTrip } from 'types/requestVehicleTypes';

import { CargoNotesCardContainer } from './_cargoNotesCard';

interface Props {
  cargo: ICreatedTrip;
}

export const CargoNotesCard = ({ cargo }: Props) => {
  const { transport_cost, toll_cost, insurance_cost } = cargo;

  const calculatedCost = () => {
    return toll_cost + transport_cost + insurance_cost;
  };

  return (
    <CargoNotesCardContainer className="cargo-notes-card">
      <span className="cargo-notes-card__small-title">Observações</span>
      <p className="cargo-notes-card__observations">{cargo.observation}</p>
      <div className="cargo-notes-card__card">
        <span className="cargo-notes-card__title">Valor</span>

        <div className="cargo-notes-card__values-wrapper">
          <div className="cargo-notes-card__value">
            <small>Valor frete</small>
            {realCurrency(transport_cost)}
          </div>
          {/* <div className="cargo-notes-card__value">
            <small>Valor do pedágio</small>
            {realCurrency(toll_cost)}
          </div> */}
          <div className="cargo-notes-card__value">
            <small>Valor seguro</small>
            {realCurrency(insurance_cost)}
          </div>
        </div>

        <div className="cargo-notes-card__total-value">
          <small>Valor total</small>
          {realCurrency(calculatedCost())}
        </div>
      </div>
    </CargoNotesCardContainer>
  );
};
