import styled from 'styled-components';

import key_icon from 'assets/icons/key_icon.svg';
import clock_icon from 'assets/icons/clock_icon.svg';
import calendar_icon from 'assets/icons/calendar_icon.svg';

import tag_icon from 'assets/icons/tag_icon.svg';
import stroke_truck_icon from 'assets/icons/stroke_truck_icon.svg';

import chevron_right from 'assets/icons/chevron_right.svg';
import dark_chevron_right from 'assets/icons/dark_chevron_right.svg';

interface Props {
  border: string;
}

export const Container = styled.div<Props>`
  &.status-info {
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 320px;

    padding: 15px 10px;

    color: var(--purple22);

    border: 1px solid ${({ border }) => border};
    border-radius: 10px;
  }

  .status-info__columns-wrapper {
    display: flex;
    flex-wrap: wrap;

    width: 100%;

    font-size: 12px;
  }

  .status-info__column {
    display: flex;
    flex-direction: column;
    gap: 5px;

    width: 50%;
  }

  .status-info__code {
    position: relative;

    display: inline-flex;
    align-items: center;

    &:before {
      content: '';
      position: relative;

      width: 11px;
      height: 11px;

      background: no-repeat center;
      background-size: contain;
      background-image: url(${key_icon});
    }
  }

  .status-info__way {
    position: relative;

    display: flex;
    gap: 10px;
    flex-direction: column;

    width: 100%;
    max-width: 150px;

    margin: 5px 0;

    &:before {
      content: '';

      position: absolute;

      left: 35px;
      top: 20%;
      bottom: 20%;
      transform: translateX(50%);

      width: 1px;

      background-color: var(--green2c);
    }
  }

  .status-info__address {
    position: relative;

    display: inline-flex;
    gap: 3px;

    span {
      position: relative;

      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      min-width: 40px;

      &:after {
        display: inline-block;
        content: '';

        position: relative;

        width: 6px;
        height: 6px;

        border: 1px solid var(--green2c);
        border-radius: 4px;

        background-color: #fff;
      }
    }

    b {
      font-weight: bold;
    }
  }

  .status-info__hour {
    position: relative;

    display: flex;
    gap: 5px;

    margin-left: auto;

    &:before {
      content: '';
      position: relative;

      width: 13px;
      height: 13px;

      background: no-repeat center;
      background-size: contain;
      background-image: url(${clock_icon});
    }
  }

  .status-info__date {
    position: relative;

    display: flex;
    gap: 5px;

    margin-left: auto;

    /* &:before {
      content: '';
      position: relative;

      width: 13px;
      height: 13px;

      background: no-repeat center;
      background-size: contain;
      background-image: url(${calendar_icon});
    } */
  }

  .status-info__additional-info {
    display: flex;
    justify-content: space-between;

    width: 100%;

    padding-top: 10px;

    font-size: 12px;
  }

  .status-info__price {
    position: relative;

    display: flex;
    gap: 5px;

    &:before {
      content: '';
      position: relative;

      width: 13px;
      height: 13px;

      background: no-repeat center;
      background-size: contain;
      background-image: url(${tag_icon});
    }
  }

  .status-info__truck-type {
    position: relative;

    display: flex;
    gap: 5px;

    &:before {
      content: '';
      position: relative;

      width: 13px;
      height: 13px;

      background: no-repeat center;
      background-size: contain;
      background-image: url(${stroke_truck_icon});
    }
  }

  .status-info__point-button {
    position: relative;

    display: flex;
    align-items: center;
    gap: 3px;

    width: fit-content;
    max-width: 100px;
    min-height: 18px;

    margin: 7px 0 0 auto;
    padding: 5px 10px;

    color: #fff;
    font-size: 12px;

    background-color: var(--purple22);

    border-radius: 9px;

    cursor: pointer;

    &:before {
      content: '';
      position: relative;

      width: 8px;
      height: 8px;

      background: no-repeat top;
      background-size: contain;
      background-image: url(${chevron_right});
    }
  }

  .status-info__point-button--delivery {
    position: relative;

    display: flex;
    align-items: center;
    gap: 10px;

    width: 100%;
    max-width: 90px;
    min-height: 18px;

    margin: 7px 0 0 auto;
    padding: 5px 10px;

    color: var(--purple22);
    font-size: 12px;

    background-color: var(--green2c);

    border-radius: 9px;

    cursor: pointer;
    &:before {
      content: '';
      position: relative;

      width: 8px;
      height: 8px;

      background: no-repeat top;
      background-size: contain;
      background-image: url(${dark_chevron_right});
    }
  }
`;
