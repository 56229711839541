import styled from 'styled-components';

export const FollowRouteContainer = styled.div`
  &.follow-route-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    width: 100%;

    animation: entering 0.5s ease-in-out;

    .follow-route-details__content {
      width: 100%;
      padding: 0 0.75rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    .secondary-button {
      text-transform: uppercase;
      max-width: 15.625rem;
      margin: 0 auto;
    }
  }
`;
