import {
  Control,
  Controller,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import {
  AddressFeedback,
  Container,
  EditIcon,
  Radio,
} from './_addCreditCardComponent';
import { GeneralInput } from 'shared/components/atoms/GeneralInput/GeneralInput';
import { ICreateCardForm } from 'types/paymentTypes';
import { cardExpirationFormat, cardNumberFormat } from 'shared/utils/format';
import { SecondaryButton } from 'shared/components/atoms/SecondaryButton/SecondaryButton';
import { useState } from 'react';
import {
  AddressModal,
  IAddressModalFormData,
} from '../AddressModal/AddressModal';

interface Props extends React.HTMLAttributes<HTMLElement> {
  register: UseFormRegister<ICreateCardForm>;
  setValue: UseFormSetValue<ICreateCardForm>;
  watch: UseFormWatch<ICreateCardForm>;
  control: Control<ICreateCardForm>;
}

function getCardBrandFromCardNumber(cardNumber: string) {
  if (!cardNumber) return 'visa';

  const cards = {
    visa: /^4[0-9]{12}(?:[0-9]{3})/,
    mastercard: /^5[1-5][0-9]{14}/,
  } as { [key: string]: RegExp };

  let brand = 'visa';

  Object.keys(cards).forEach((card) => {
    if (cards[card].test(cardNumber.replace(/\D/g, ''))) {
      brand = card;
    }
  });

  return brand;
}

export const AddCreditCardComponent: React.FC<Props> = ({
  control,
  register,
  setValue,
  watch,
}) => {
  const [showAddressModal, setShowAddressModal] = useState(false);

  const handleMaskedInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    switch (name) {
      case 'number':
        setValue(name, cardNumberFormat(value));
        break;
      case 'expiration_date':
        setValue(name, cardExpirationFormat(value));
        break;
      case 'cvv':
        setValue(name, value.replace(/\D/g, ''));
        break;
      default:
        setValue(name as keyof ICreateCardForm, value);
        break;
    }
  };

  const handleConfirmAddress = (data: IAddressModalFormData) => {
    setValue('address', data, {
      shouldDirty: true,
      shouldValidate: true,
    });
    setShowAddressModal(false);
  };

  const address = watch('address');

  return (
    <>
      <Container className="add-card-form">
        <div className="add-card-form__row">
          <GeneralInput
            label="Número do cartão"
            maxLength={19}
            {...register('number', {
              onChange: handleMaskedInputChange,
            })}
          />
          <div
            className={`add-card-from__flag ${getCardBrandFromCardNumber(
              watch('number'),
            )}`}
          />
        </div>

        <div className="add-card-form__row">
          <GeneralInput
            label="Nome completo (Como está impresso no cartão)"
            title="O nome deve conter apenas letras e espaços, não contendo caracteres especiais ou acentos"
            pattern="^[a-zA-Z ]+$"
            {...register('holder_name')}
          />
        </div>
        <div className="add-card-form__row">
          <GeneralInput
            inputClassName="add-card-form__short-input"
            label="Data de validade"
            maxLength={5}
            {...register('expiration_date', {
              onChange: handleMaskedInputChange,
            })}
          />
          <GeneralInput
            inputClassName="add-card-form__short-input"
            label="CVV"
            maxLength={3}
            {...register('cvv', {
              onChange: handleMaskedInputChange,
            })}
          />
        </div>
        <div className="add-credit-card__extras">
          O endereço da cobrança é o mesmo cadastrado na plataforma?
          <Controller
            control={control}
            name="newBillingAddress"
            defaultValue={false}
            render={({ field: { onChange, value, name } }) => (
              <>
                <Radio className="radio">
                  <label
                    className="radio__option"
                    onClick={() => onChange(false)}
                  >
                    <input type="radio" name={name} defaultChecked={!value} />
                    <div className="radio__selection">Sim</div>
                  </label>

                  <label
                    className="radio__option"
                    onClick={() => onChange(true)}
                  >
                    <input type="radio" name={name} defaultChecked={value} />
                    <div className="radio__selection">
                      Não
                      {value && (
                        <SecondaryButton
                          className="radio__add-address secondary-button"
                          onClick={() => setShowAddressModal(true)}
                          type="button"
                        >
                          Adicionar endereço
                        </SecondaryButton>
                      )}
                    </div>
                  </label>
                </Radio>
                {value && !!address?.street && (
                  <AddressFeedback className="address-feedback">
                    <div className="address-feedback__header">
                      <div className="address-feedback__title">
                        Endereço de cobrança
                      </div>
                      <button
                        type="button"
                        className="address-feedback__edit-button"
                        onClick={() => setShowAddressModal(true)}
                      >
                        <EditIcon />
                      </button>
                    </div>

                    <span>
                      {address.street}, {address.number}
                    </span>

                    <span>
                      {address.neighborhood}, {address.city} - {address.uf}
                    </span>

                    <span>CEP {address.cep}</span>
                  </AddressFeedback>
                )}
              </>
            )}
          />
        </div>
      </Container>
      {showAddressModal && (
        <AddressModal
          setShowModal={setShowAddressModal}
          showModal={showAddressModal}
          title="Adicionar novo endereço de cobrança"
          buttonText="Adicionar endereço"
          onConfirm={handleConfirmAddress}
          initialFormData={address}
        />
      )}
    </>
  );
};
