import styled from "styled-components";

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    width: 20px;
    height: 20px;
  }
`;

export const OptionsWrapper = styled.div`
  position: absolute;
  top: 90%;
  right: 50%;
  height: fit-content;
  overflow: hidden;
  width: fit-content;

  max-height: 0px;

  transform: translateX(50%);

  transition: max-height 0.4s ease-in-out, padding 0.4s ease-in-out;

  border-radius: 0.875rem;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  scrollbar-width: none;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #e6e6ef;
    border-radius: 4px;

    margin: 0.75rem 0;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--purple22);
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    opacity: 0.9;
  }

  @media (max-width: 1024.1px) {
    top: unset;
    bottom: 100%;
    left: 0;

    transform: translateX(-50%);
  }
`;

export const Option = styled.button`
  display: block;
  text-decoration: none;

  background: #fff;
  border: none;

  font-size: 1rem;
  color: #868e96;

  transition: filter 0.2s;

  width: 100%;
  cursor: pointer;

  text-align: right;

  transition: background-color 0.2s;

  border-radius: 0.3125rem;
  padding: 0.3125rem;

  &:hover {
    background-color: #e4e4e4;
  }
`;

export const Container = styled.div`
  position: relative;

  cursor: pointer;

  &:hover {
    ${OptionsWrapper} {
      max-height: 300px;
      overflow: auto;
      padding: 0.625rem 0.5rem;
    }
  }
`;
