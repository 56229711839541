import styled from 'styled-components';

import edit_icon from '../../../../assets/icons/edit_icon.svg';
import pin_icon from '../../../../assets/icons/pin_icon.svg';

import { ReactComponent as Pin } from 'assets/icons/pin_icon.svg';
import { ReactComponent as Edit } from 'assets/icons/edit_icon.svg';

export const Container = styled.div`
  &.company-info {
    margin: 3em auto 0;

    padding: 0 15px;

    width: 100%;
    max-width: 595px;

    animation: slide_left 0.5s;
  }
`;

export const Card = styled.div`
  &.company-info__card {
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 595px;

    border-radius: 20px;

    box-shadow: 0 3px 6px rgb(0 0 0 /16%);

    .card__title {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      padding: 15px;

      color: #fff;
      font-size: 1rem;

      border-radius: 20px 20px 0 0;

      background-color: var(--purple22);

      b {
        font-size: 1.2rem;
        font-weight: bold;
      }
    }

    .card__body {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;

      padding: 15px 15px 3em;
    }

    .card__input-wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;

      width: 100%;
      max-width: 385px;
    }

    .icon-input {
      position: relative;

      &:after {
        content: '';

        position: absolute;
        top: 50%;
        right: 20px;

        width: 20px;
        height: 20px;

        transform: translateY(-50%);

        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-image: url(${edit_icon});

        filter: grayscale(1);
        opacity: 0.4;
        cursor: pointer;
      }
    }

    .input__input {
      align-items: flex-start;
      text-align: left;

      font-size: 1.4rem;
    }

    .company-info__address-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;

      margin: 15px auto 0;
      width: 90%;
      // max-width: 327px;
    }

    .company-info__address-title {
      font-size: 1.5rem;
      color: #afafaf;
      padding-left: 10px;
    }

    .company-info__address {
      position: relative;

      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5px;

      width: 100%;
      max-width: 385px;

      font-size: 0.9rem;
      color: #afafaf;

      &:before {
        content: '';

        width: 14px;
        height: 20px;

        background: no-repeat center;
        background-size: cover;
        background-image: url(${pin_icon});
      }

      &:after {
        content: '';

        width: 20px;
        height: 20px;

        filter: grayscale(1);
        opacity: 0.4;

        background: no-repeat center;
        background-size: cover;
        background-image: url(${edit_icon});

        cursor: pointer;
      }
    }

    .company-info__address-container {
      display: flex;
      align-items: center;
      gap: 1rem;

      padding-left: 3px;

      > svg {
        height: 1.25rem;
        width: 1.25rem;
        overflow: visible;
      }
    }

    .company-info__address-text {
      font-size: 0.9rem;
      color: #afafaf;
    }

    .company-info__insurance {
      position: relative;

      display: flex;
      flex-direction: column;
      gap: 10px;

      width: 100%;

      margin-top: 15px;
      padding: 0 25px;

      color: var(--purple22);

      b {
        font-size: 1.4rem;
        color: var(--green2c);
        font-weight: bold;
      }

      &:after {
        content: '';
        position: absolute;

        top: 10px;
        right: 30px;

        width: 20px;
        height: 20px;

        filter: grayscale(1);
        opacity: 0.4;

        background: no-repeat center;
        background-size: cover;
        background-image: url(${edit_icon});

        cursor: pointer;
      }
    }
  }
`;

export const PillSelector = styled.div`
  &.insurance__radio-wrapper {
    position: relative;
    display: flex;
    width: 100%;

    color: var(--purple22);
    font-size: 0.8rem;
    text-align: center;

    border-radius: 12px;
    box-shadow: 0 3px 6px rgb(0 0 0 /12%);

    input[type='radio'] {
      display: none;
    }

    &:before {
      content: '';

      position: absolute;
      top: 3px;
      bottom: 3px;
      left: 50%;

      transform: translateX(-50%);

      width: 2px;
      background-color: var(--purple22);
    }

    .insurance__ddr {
      padding: 15px 0;
    }

    .insurance__radio {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      padding: 15px 0;

      min-height: 45px;

      background-color: #b6eaed;

      cursor: pointer;
      transition: all 0.5s;
    }

    .insurance__radio.active {
      background-color: var(--purple22);
      color: #fff;
    }

    .radio--transporter {
      border-radius: 12px 0 0 12px;
    }

    .radio--shipper {
      border-radius: 0 12px 12px 0;
    }
  }
`;

export const Radio = styled.div`
  &.ddr-radio {
    display: flex;
    gap: 15px;

    padding: 15px 0;

    width: 100%;

    .ddr-radio__option {
      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;

      cursor: pointer;
      user-select: none;

      input {
        opacity: 0;
        height: 0;
        width: 0;
      }

      input:checked ~ .radio__selection {
        color: var(--green2c);

        &:before {
          border: 1px solid var(--green2c);
          box-shadow: 0 0 0px 4px var(--green2c);
        }
      }

      .radio__selection {
        position: relative;

        display: flex;
        align-items: center;

        padding-left: 18px;

        color: #868e96;

        &:before {
          content: '';
          position: absolute;

          top: 45%;
          left: 0;

          transform: translateY(-50%);

          width: 7px;
          height: 7px;

          border-radius: 14px;
          border: 1px solid transparent;
          box-shadow: 0 0 0px 4px #868e96;
        }
      }
    }
  }
`;

export const PinIcon = styled(Pin)`

`;

export const EditIcon = styled(Edit)`
  cursor: pointer;

  path {
    fill: #868e96;
  };
`
