import { useContext } from 'react';
import { SocketContext } from 'contexts/socket';

const useSocket = () => {
  const data = useContext(SocketContext);

  return data;
};

export default useSocket;
