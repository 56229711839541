import { Control, Controller } from 'react-hook-form';
import Select, { Props as ReactSelectProps } from 'react-select';
import {
  ControllerSelectContainer,
  ControllerSelectLabelText,
  ErrorMessage,
} from './_controllerSelect';

interface Option {
  label: string;
  value: string | number;
}

interface Props extends ReactSelectProps<Option | null, false> {
  control: Control<any, any>;
  name: string;
  disabled?: boolean;
  label?: string;
  containerMaxWidth?: string;
  containerWidth?: string;
  error?: string;
  onChange?: (option: Option | null) => void;
}

export const ControllerSelect: React.FC<Props> = ({
  control,
  label,
  containerMaxWidth,
  name,
  error,
  placeholder,
  disabled,
  options = [],
  containerWidth,
  onChange: propsOnChange,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, onBlur } }) => {
        return (
          <ControllerSelectContainer
            containerMaxWidth={containerMaxWidth}
            containerWidth={containerWidth}
          >
            {label && (
              <ControllerSelectLabelText>{label}</ControllerSelectLabelText>
            )}
            <Select
              onChange={(option) => {
                propsOnChange && propsOnChange(option);
                onChange(option);
              }}
              value={value}
              options={options}
              onBlur={onBlur}
              isSearchable
              placeholder={placeholder}
              isDisabled={disabled}
              noOptionsMessage={() => 'Nenhuma opção encontrada'}
              styles={{
                indicatorSeparator: (prev) => ({
                  ...prev,
                  display: 'none',
                }),
                control: (prev, { isDisabled }) => ({
                  ...prev,
                  borderRadius: '0.625rem',
                  border: '2px solid var(--greenb6)',
                  '&:hover': {
                    border: '2px solid var(--greenb6)',
                  },
                  '&:focus-within': {
                    boxShadow: 'none',
                    border: '2px solid var(--green2c)',
                  },
                  padding: '0 0.375rem',
                  outline: 'none',
                  backgroundColor: isDisabled ? '#fff' : 'inherit',
                  minHeight: '3rem',
                  cursor: 'pointer',
                }),
                placeholder: (prev) => ({
                  ...prev,
                  fontSize: '0.875rem',
                  fontFamily: 'Roboto',
                  fontWeight: 400,
                  color: 'var(--green2c)',
                }),
                container: (prev) => ({
                  ...prev,
                  width: containerWidth,
                  maxWidth: containerMaxWidth,
                  outline: 'none',
                }),
                menu: (prev) => ({
                  ...prev,
                  fontSize: '0.875rem',
                  fontFamily: 'Roboto',
                  fontWeight: 400,
                  color: 'var(--green2c)',
                }),
                option: (prev, state) => ({
                  ...prev,
                  backgroundColor: state.isSelected
                    ? '#B6EAED'
                    : prev.backgroundColor,
                }),
                singleValue: (prev) => ({
                  ...prev,
                  fontSize: '0.875rem',
                  fontFamily: 'Roboto',
                  fontWeight: 400,
                  color: 'var(--green2c)',
                }),
                dropdownIndicator: (prev) => ({
                  ...prev,
                  color: 'var(--green2c)',
                  '&:hover': {
                    color: 'var(--green2c)',
                  },
                }),
                menuList: (prev) => ({
                  ...prev,
                  marginRight: '0.125rem',
                  '&::-webkit-scrollbar': {
                    width: '6px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'var(--purple22)',
                    borderRadius: '8px',
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: '#dedede',
                    borderRadius: '4px',
                    margin: '0.25rem 0',
                  },
                }),
              }}
              {...rest}
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </ControllerSelectContainer>
        );
      }}
    />
  );
};
