import styled from 'styled-components';

export const AddressForm = styled.form`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  padding: 2rem 2.375rem 2.375rem;

  max-width: 37.5rem;
  width: fit-content;

  background-color: #fff;
  border-radius: 0.625rem;

  .address-form__close-button {
    background-color: transparent;
    border: none;

    position: absolute;
    top: 1rem;
    right: 1rem;

    color: var(--green2c);
    font-size: 2rem;
    font-weight: 300;

    cursor: pointer;
  }

  .address-form__title {
    font-size: 1.25rem;
    font-weight: 500;
    color: var(--purple22);
  }

  .address-form__row {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }

  .address-form__field {
    width: 100%;
    max-width: max-content;

    > label {
      font-weight: 500;
    }

    > label,
    input {
      width: 100%;
    }
  }

  .address-form__field--small {
    > input {
      max-width: 7.5rem;
    }
  }

  .address-form__field--medium {
    > input {
      max-width: 11rem;
    }
  }

  .address-form__field--long {
    max-width: unset;

    > input {
      max-width: 20rem;
    }
  }

  .address-form__field--fill {
    max-width: unset;
  }

  .secondary-button {
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 0.08em;
    text-transform: uppercase;
  }

  @media (max-width: 600px) {
    .address-form__row {
      flex-wrap: wrap;
    }
  }
`;
