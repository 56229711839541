import styled from 'styled-components';

import arrow_down from '../../../../assets/icons/arrow_down.svg';

export const Container = styled.form`
  &.request-form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .request-form__input--tiny {
      width: 100%;
      max-width: 8ch;
    }

    .request-form__input--small {
      width: 100%;
      max-width: 14ch;
    }

    .request-form__input--medium {
      width: 100%;
      max-width: 10rem;
    }

    .request-form__input--long {
      width: 100%;
      max-width: 13rem;
    }

    .request-form__input--fill {
      max-width: 30.5rem;
      width: 100%;
    }

    .square-input,
    .request-form__input--medium,
    .request-form__input--small,
    .request-form__input--tiny,
    .request-form__input--long,
    .request-form__input--fill {
      .square-input__input {
        border-width: 2px;
        border-color: var(--greenb6);
        border-radius: 0.625rem;
        min-height: 3rem;
        font-size: 0.875rem;
        padding: 0 1rem;

        &:focus {
          border-color: var(--green2c);
        }
      }
    }

    .request-form__prefix-input-container {
      width: 100%;
    }

    .request-form__prefix-input-container--medium {
      width: 100%;
      max-width: 13rem;
    }

    .request-form__prefix-input {
      width: 100%;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type='number'] {
        -moz-appearance: textfield;
      }
    }

    .request-form__group {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      gap: 10px;

      font-size: 0.8rem;
    }

    .request-form__label {
      font-size: 1rem;
      font-weight: 500;
      color: var(--purple22);
      white-space: nowrap;
    }

    .request-form__select {
      display: flex;
      flex-direction: column;
      gap: 10px;

      width: 100%;
      max-width: 10rem;
    }

    .request-form__select--long {
      max-width: 13rem;
    }

    .request-form__temperature-select {
      max-width: 23.75rem;
    }

    .request-form__number-label {
      position: relative;

      display: flex;
      flex-direction: column;
      gap: 10px;

      font-size: 1rem;
      font-weight: 500;
      color: var(--purple22);
      white-space: nowrap;

      input[type='number'] {
        position: relative;

        width: 100px;
        height: 48px;

        color: var(--green2c);

        border: 2px solid var(--greenb6);
        border-radius: 0.625rem;

        padding: 0.625rem 1rem;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
        }

        -moz-appearance: textfield;

        &:focus {
          outline: none;
          border: 2px solid var(--green2c);
        }
      }

      .request-form__number-label__arrow {
        content: '';

        position: absolute;

        width: 15px;
        height: 14px;

        background-color: #fff;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 9px;
        background-image: url(${arrow_down});

        cursor: pointer;

        &.arrow--up {
          bottom: 20px;
          right: 48px;
          transform: translateY(-50%) scaleY(-1);
        }

        &.arrow--down {
          bottom: 0px;
          right: 48px;
          transform: translateY(-50%);
        }
      }
    }

    .secondary-button {
      margin: auto auto 0;

      max-width: 350px;
      height: 48px;

      text-transform: uppercase;
      letter-spacing: 0.08em; // 80 character spacing units in XD
    }

    .dropdown {
      min-height: 3rem;
      border: 2px solid var(--greenb6);
      border-radius: 0.625rem;
      font-size: 0.875rem;
    }

    .dropdown.selecting {
      border-radius: 0.625rem 0.625rem 0 0;
    }

    .dropdown__placeholder {
      padding: 0 1rem;
      color: var(--green2c);
    }

    .dropdown__wrapper {
      border-radius: 0 0 0.625rem 0.625rem;
      border-color: var(--greenb6);
      border-width: 2px;
      right: -2px;
      left: -2px;

      max-height: 13rem;
      overflow: auto;
    }

    .dropdown__fake-dropshadow.selecting {
      height: 90vh;
      width: 90vw;
    }
  }
`;

export const FormGroupRow = styled.div`
  &.form__group-row {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;

    .square-input__label {
      font-size: 1rem;
      font-weight: 500;
      color: var(--purple22);
      white-space: nowrap;
    }

    .request-form__textarea-label {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      max-width: 23.75rem;

      font-size: 1rem;
      font-weight: 500;
      color: var(--purple22);

      textarea {
        width: 100%;
        height: 60px;

        padding: 12px 1rem;

        resize: none;
        border: 2px solid var(--greenb6);
        border-radius: 0.625rem;

        &:focus {
          outline: none;
          border: 2px solid var(--green2c);
        }

        &::placeholder {
          font-family: 'Roboto', sans-serif;
          color: #868e96;
          font-size: 0.8rem;
        }
      }
    }

    .request-form__cargo-value-label {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
    }

    .request-form__input--cargo-value {
      align-items: center;
      width: 100%;
      padding: 5px;
      border-radius: 4px;
      border: 1.5px solid var(--green2c);
      font-size: 1rem;
      color: var(--purple22);
      background-color: transparent;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }
      &:focus {
        outline: none;
      }
    }

    .form__group-row__error-message {
      position: absolute;
      bottom: -0.875rem;
      left: 0;
      width: 100%;
      white-space: normal;
      font-size: 0.75rem;
      color: #ff6565;
    }

    @media (min-width: 600px) {
      flex-wrap: nowrap;

      .request-form__textarea-label {
        max-width: unset;
      }
    }
  }
`;
