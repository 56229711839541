import { useEffect, useRef, useState } from 'react';

import { ReactComponent as BellIcon } from 'assets/icons/alert_icon.svg';

import useNotifications from 'shared/hooks/useNotifications.hook';
import useOutside from 'shared/hooks/useOutside.hook';
import {
  Container,
  IconButton,
  NotificationActionButton,
  NotificationItem,
  NotificationItemBottom,
  NotificationsContainer,
  NotificationsList,
  NotificationText,
  NotificationTimeSince,
  PlaceholderText,
} from './_notificationBell';
import { formatRelativeDate } from 'shared/utils/format';

export const NotificationBell = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { notifications, hasUnreadNotifications, setHasUnreadNotifications } =
    useNotifications();
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    setIsExpanded(true);
  }, [notifications]);

  const toggle = () => {
    setIsExpanded((prevState) => {
      if (!prevState) {
        setHasUnreadNotifications(false);
      }

      return !prevState;
    });
  };

  const handleClose = () => {
    setIsExpanded(false);
  };

  const isPaid = (message: string) => {
    if (message.includes('foi pago')) {
      return true;
    }
    return false;
  };

  const { ref } = useOutside(handleClose);

  return (
    <Container ref={ref}>
      <IconButton
        showBadge={hasUnreadNotifications}
        type="button"
        onClick={toggle}
      >
        <BellIcon />
      </IconButton>
      <NotificationsContainer isExpanded={isExpanded}>
        <NotificationsList isExpanded={isExpanded}>
          {notifications.length > 0 ? (
            notifications.map((notification) => (
              <NotificationItem key={notification.id}>
                <NotificationText>
                  {notification.displayMessage}
                </NotificationText>
                <NotificationItemBottom>
                  <NotificationActionButton
                    type="button"
                    onClick={notification.onClickCallback}
                    disabled={isPaid(notification.displayMessage)}
                  >
                    {notification.buttonText}
                  </NotificationActionButton>
                  <NotificationTimeSince>
                    {formatRelativeDate(
                      notification.created_at
                        ? new Date(notification.created_at)
                        : new Date(),
                    )}
                  </NotificationTimeSince>
                </NotificationItemBottom>
              </NotificationItem>
            ))
          ) : (
            <NotificationItem>
              <PlaceholderText>Você não possui notificações</PlaceholderText>
            </NotificationItem>
          )}
        </NotificationsList>
      </NotificationsContainer>
    </Container>
  );
};
