import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from 'services/api';
import { handleError } from 'services/errorHandler';

import { Header } from 'shared/components/organisms/Header/Header';
import useNotifications from 'shared/hooks/useNotifications.hook';
import { mockedLoadings } from 'shared/utils/mockedItems';
import {
  deliveredStatuses,
  onGoingStatuses,
  requestingStatuses,
} from 'shared/utils/tripStatus';
import { ICreatedTrip } from 'types/requestVehicleTypes';

import { DetailsModal } from './components/DetailsModal/DetailsModal';

import { StatusCardComponent } from './components/StatusCard/StatusCardComponent';
import { StatusInfoComponent } from './components/StatusInfoComponent/StatusInfoComponent';

import {
  Container,
  SearchBoxContainer,
  SearchBoxInput,
  SearchIcon,
} from './_myLoadingsPage';

interface MyTripsResponse {
  myTrips: {
    status: number;
    trip: ICreatedTrip[];
  }[];
}

export const MyLoadingsPage: React.FC = () => {
  const { notifications } = useNotifications();
  const [myLoadings, setMyLoadings] = useState<ICreatedTrip[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  const { tripId } = useParams<{
    tripId?: string;
  }>();

  const [selectedId, setSelectedId] = useState(tripId || '');

  useEffect(() => {
    fetchTrips();
  }, [notifications]);

  const fetchTrips = async () => {
    try {
      const response = await api.get<MyTripsResponse>('/trips/my-trips', {
        params: {
          group_by_status: true,
        },
      });

      const trips = response.data.myTrips.reduce(
        (acc: ICreatedTrip[] = [], curr) => {
          return [...acc, ...curr.trip];
        },
        [],
      );

      setMyLoadings(trips);
    } catch (error: any) {
      handleError(
        error?.response?.data?.message ||
          'Algo deu errado, tente novamente mais tarde',
      );
    }
  };

  const cancelTrip = async (id: string) => {
    try {
      await api.put(`/trips/${id}`, {
        status_id: 7,
      });

      setMyLoadings((prevState) => prevState.filter((trip) => trip.id !== id));

      toast.success('Solicitação cancelada com sucesso!');
    } catch (error: any) {
      handleError(
        error?.response?.data?.message ||
          'Algo deu errado, tente novamente mais tarde',
      );
    }
  };

  const filteredLoadings = myLoadings.filter((loading) =>
    loading.order.includes(searchQuery),
  );

  const totalLoadingsWithStatuses = (statuses: number[]) => {
    if (filteredLoadings.length <= 0) return 0;
    return filteredLoadings.reduce((acc: number, curr: ICreatedTrip) => {
      if (statuses.includes(curr.status_id)) {
        return acc + 1;
      }

      return acc;
    }, 0);
  };

  const requestingLoadingsConfig = {
    background: '#CFCBF1',
    color: 'var(--purple22)',
    title: `Em solicitação `,
    description: 'Cargas esperando a resposta do Transportador',
    quantity: totalLoadingsWithStatuses(requestingStatuses),
  };

  const onGoingLoadingsConfig = {
    background: 'var(--purple22)',
    color: '#fff',
    title: `Em andamento `,
    description: 'Acompanhe o status da sua carga',
    quantity: totalLoadingsWithStatuses(onGoingStatuses),
  };

  const deliveredLoadingsConfig = {
    background: 'var(--green2c)',
    color: 'var(--purple22)',
    title: `Entregue `,
    description: 'Histórico de cargas entregues',
    quantity: totalLoadingsWithStatuses(deliveredStatuses),
  };

  const renderLoadingWithStatuses = (status_ids: number[]) => {
    if (totalLoadingsWithStatuses(status_ids) <= 0)
      return <p>Nenhum carregamento encontrado</p>;

    return filteredLoadings.map((item) => {
      if (!status_ids.includes(item.status_id)) return;

      return (
        <StatusInfoComponent
          key={item.order}
          loadingInfo={item}
          border={requestingLoadingsConfig.background}
          showDetails={onGoingStatuses.includes(item.status_id)}
          handleInfoClick={() => setSelectedId(item.id)}
        />
      );
    });
  };

  return (
    <>
      <Header />
      <Container className="my-loadings">
        <h1 className="my-loadings__title">Minhas cargas</h1>
        {/* <FilterComponent {...filterConfig} /> */}

        <SearchBoxContainer>
          <SearchBoxInput
            placeholder="Pesquisar cargas"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <SearchIcon />
        </SearchBoxContainer>

        <div className="my-loadings__status-wrapper">
          <StatusCardComponent {...requestingLoadingsConfig}>
            {renderLoadingWithStatuses(requestingStatuses)}
          </StatusCardComponent>

          <StatusCardComponent {...onGoingLoadingsConfig}>
            {renderLoadingWithStatuses(onGoingStatuses)}
          </StatusCardComponent>

          <StatusCardComponent {...deliveredLoadingsConfig}>
            {renderLoadingWithStatuses(deliveredStatuses)}
          </StatusCardComponent>
        </div>
      </Container>

      <DetailsModal
        cancelTrip={cancelTrip}
        cargoId={selectedId}
        onClose={() => setSelectedId('')}
        showModal={!!selectedId}
      />
    </>
  );
};
