import React from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import api from 'services/api';

import { SecondaryButton } from 'shared/components/atoms/SecondaryButton/SecondaryButton';
import { Input } from 'shared/components/molecules/Input/Input';
import { Spinner } from 'shared/components/atoms/Spinner/Spinner';

import { editPasswordSchema } from 'shared/utils/schemas';

import { Container } from './_editPasswordComponent';

interface Props {
  setShowModal: Function;
}

interface IPassword {
  password: string;
  password_confirmation: string;
  old_password: string;
}

export const EditPasswordComponent: React.FC<Props> = ({ setShowModal }) => {
  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting, errors },
  } = useForm<IPassword>({
    resolver: yupResolver(editPasswordSchema),
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<IPassword> = (data) => {
    editInformation(data);
  };

  const editInformation = async (data: IPassword) => {
    try {
      const response = await api.patch(`users/password`, data);

      if (response) {
        toast.success('Senha atualizada com sucesso');

        setShowModal(false);
      }
    } catch (error: any) {
      handleError(error.response?.data?.message || error.toString());
    }
  };

  const handleError = (error: string) => {
    toast.error(error);
  };

  const confirmPasswordError = () => {
    if (errors.password_confirmation?.message === 'not-match')
      return 'As senhas precisam ser iguais';
  };

  return (
    <Container className="edit-password" onSubmit={handleSubmit(onSubmit)}>
      <span className="edit-password__title">Alterar senha:</span>

      <Input
        placeholder="Senha atual..."
        register={{ ...register('old_password') }}
        type="password"
      />

      <Input
        placeholder="Nova senha..."
        register={{ ...register('password') }}
        type="password"
      />

      <Input
        placeholder="Confirmação de senha"
        register={{ ...register('password_confirmation') }}
        type="password"
      />
      <span className="edit-password__error-feedback">
        {confirmPasswordError()}
      </span>

      <SecondaryButton disabled={isSubmitting || !isValid}>
        {isSubmitting ? <Spinner white /> : 'Salvar alteração'}
      </SecondaryButton>
    </Container>
  );
};
