import styled from 'styled-components';

import { ReactComponent as ForwardArrowIconRaw } from 'assets/icons/forward_arrow.svg';

interface ChatContainerProps {
  isExpanded: boolean;
}

export const ChatContainer = styled.div<ChatContainerProps>`
  position: absolute;
  top: 5%;
  right: 0%;
  z-index: 2;

  width: ${({ isExpanded }) => (isExpanded ? '16.625rem' : '9.375rem')};

  display: flex;
  flex-direction: column;

  transition: width 0.2s;

  .chat__header-button {
    min-height: 2.25rem;
    width: 100%;

    background-color: #fff;
    border: none;
    border-radius: 6.25rem 0 0 6.25rem;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

    font-size: 0.875rem;
    font-weight: 700;
    color: var(--purple22);

    cursor: pointer;
  }

  .chat__content {
    position: relative;
    z-index: -1;
    top: -20px;

    transition: max-height 0.2s;

    max-height: ${({ isExpanded }) => (isExpanded ? '40vh' : '0px')};
    height: 100vh;

    background-color: #fff;
    border-radius: 0 0 0 0.625rem;
    padding: 0.5rem 0.25rem 0.5rem 0.75rem;

    display: flex;
    flex-direction: column;

    overflow: hidden;
  }

  .chat__messages-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    padding: 2rem 2px 0.75rem;

    max-height: 280px;
    height: fit-content;
    margin-top: auto;

    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #e6e6ef;
      border-radius: 4px;
      margin-top: 1rem;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--purple22);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      opacity: 0.9;
    }
  }

  .chat__message {
    border-radius: 0.625rem;
    padding: 0.5rem 0.625rem;
    width: fit-content;

    max-width: 60%;

    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
  }

  .chat__message--from-me {
    align-self: flex-end;
    margin-right: 0.5rem;
    background: #effeff;
  }

  .chat__message-text {
    white-space: pre-line;
    word-break: break-all;
  }

  .chat__textarea-container {
    width: 100%;
    min-height: 2.325rem;
    max-height: 50%;

    display: flex;
    align-items: center;

    border-radius: 0.625rem;
    border: 2px solid var(--greenb6);
    padding: 0.5rem;
    background-color: #effeff;

    &:focus-within {
      outline: none;
      border: 2px solid var(--green2c);
    }
  }

  .chat__submit-button {
    background-color: transparent;
    border: none;
    margin-left: auto;
    padding: 0.25rem;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
  }

  .chat__textarea-wrapper {
    position: relative;

    height: 100%;
    width: 100%;

    overflow: hidden;

    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .chat__textarea-wrapper__text {
    font-size: 0.875rem;
    max-width: 14rem;
    width: 100%;
    height: 100%;
    line-height: normal;
    white-space: pre-line;
    word-break: break-all;
    overflow: hidden;
  }

  .chat__textarea {
    position: absolute;
    inset: 0;

    max-width: 14rem;
    height: 100%;
    width: 100%;
    border: none;
    resize: none;

    line-height: normal;
    color: var(--purple22);
    font-size: 0.875rem;
    font-weight: 400;
    background-color: #effeff;

    &::placeholder {
      color: var(--purple22);
    }

    &:focus {
      outline: none;
    }
  }
`;

export const ForwardArrowIcon = styled(ForwardArrowIconRaw)``;

export const HasUnreadBadge = styled.div`
  position: absolute;
  top: -0.125rem;
  left: -0.25rem;

  background: #00b0f0;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
`;
