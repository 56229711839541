import axios from 'axios';
import { IAddress } from 'types/requestVehicleTypes';

export const addressApi = axios.create({
  baseURL: 'https://viacep.com.br',
});

interface AddressAPIResponse {
  cep: string;
  logradouro: string;
  bairro: string;
  complemento: string;
  localidade: string;
  ibge: string;
  uf: string;
  erro?: boolean;
}

interface AddressFields {
  cep: string;
  street: string;
  neighborhood: string;
  city_ibge_code: string;
  state: string;
  number: string;
  city: string;
}

function mapResponseToAddress(addressResponse: AddressAPIResponse): AddressFields {
  const {
    cep,
    bairro,
    logradouro,
    ibge,
    uf,
    localidade
  } = addressResponse;

  return {
    street: logradouro || '',
    neighborhood: bairro || '',
    cep: cep || '',
    city_ibge_code: ibge || '',
    state: uf || '',
    number: '',
    city: localidade || '',
  };
}

export async function getAddressInformation(cep: string): Promise<AddressFields> {
  const response = await addressApi.get<AddressAPIResponse>(`/ws/${cep.replace(/-/g, '')}/json`);

  if (response?.data?.erro) {
    throw new Error('CEP não encontrado!');
  }

  return mapResponseToAddress(response.data);
}
