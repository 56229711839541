import styled from 'styled-components';

export const CargoNotesCardContainer = styled.div`
  &.cargo-notes-card {
    display: flex;
    flex-direction: column;
    gap: 15px;

    width: 100%;
    max-width: 370px;

    .cargo-notes-card__small-title {
      position: relative;

      font-size: 10px;
      text-transform: uppercase;
      letter-spacing: 1px;

      &:after {
        content: '';

        position: absolute;

        bottom: -10px;
        left: 0;

        width: 8px;
        height: 4px;

        background-color: var(--green2c);
      }
    }

    .cargo-notes-card__observations {
      font-size: 12px;
    }

    .cargo-notes-card__card {
      display: flex;
      flex-direction: column;

      min-height: 136px;

      background-color: #effeff;
      border-radius: 10px;
    }

    .cargo-notes-card__title {
      padding: 15px 15px 0;

      font-size: 1.3rem;
      font-weight: bold;
    }

    .cargo-notes-card__values-wrapper {
      display: flex;
      justify-content: space-between;

      gap: 10px;

      padding: 10px 15px;

      border-bottom: 2px solid var(--green2c);
    }

    .cargo-notes-card__value {
      display: flex;
      flex-direction: column;

      font-size: 0.9rem;
      font-weight: bold;

      small {
        font-size: 10px;
        font-weight: normal;
      }
    }

    .cargo-notes-card__total-value {
      display: flex;
      flex-direction: column;

      padding: 5px 15px;

      font-size: 0.9rem;
      font-weight: bold;

      small {
        font-size: 10px;
        font-weight: normal;
      }
    }
  }
`;
