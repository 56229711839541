import styled from 'styled-components';

import double_chevron from '../../../../assets/icons/double_right_chevron.svg';

export const Controller = styled.div``;

export const ManagerRegisterContainer = styled.form`
  &.manager-register-container {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100vw;
    height: 100vh;

    .manager-register-container__form-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;

      width: 100%;
      max-width: 450px;
    }

    .form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 15px;

      width: 100%;
      min-height: 500px;

      padding: 3em 2em;

      border-radius: 20px;
      background-color: rgb(255 255 255 /60%);

      .icon-input__wrapper {
        height: 35px;

        .input__input {
          font-size: 1.5rem;
        }
      }

      .primary-button {
        position: relative;
        font-size: 1.4rem;

        .primary-button__double-chevron {
          content: '';
          position: absolute;
          top: 50%;
          right: 15px;

          transform: translateY(-50%);

          width: 17px;
          height: 17px;

          background: no-repeat center;
          background-size: cover;
          background-image: url(${double_chevron});
        }
      }
    }

    .form__title {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;

      font-size: 0.95rem;
      color: var(--purple22);

      b {
        font-size: 1.5rem;
        color: var(--green2c);
        text-transform: uppercase;
      }
    }

    .form__registered {
      font-size: 1.25rem;
      color: #868e96;
      span {
        color: var(--green2c);
        cursor: pointer;
      }
    }
  }
`;

export const Logo = styled.img`
  width: 280px;
  height: auto;

  padding: 0 20px;
  @media (min-width: 1024.1px) {
    margin: auto;
  }
`;
