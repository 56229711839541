import styled from 'styled-components';

import visa from '../../../../assets/icons/visa.svg';
import mastercard from '../../../../assets/icons/mastercard.svg';

import delete_icon from '../../../../assets/icons/delete.svg';
import delete_active from '../../../../assets/icons/delete_active.svg';

import add_icon from '../../../../assets/icons/add_icon.svg';

import double_check from '../../../../assets/icons/double_check.svg';

export const Container = styled.div`
  &.payment-information {
    display: flex;
    flex-direction: column;

    width: 95%;
    max-width: 600px;
    min-height: 650px;

    margin: 3em auto 0;

    box-shadow: 0 3px 6px rgb(0 0 0 /16%);
    border-radius: 22px;

    animation: slide_left 0.5s;

    .payment-information__title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;

      padding: 15px;

      color: #fff;
      font-size: 1.25rem;
      font-weight: bold;

      background-color: var(--purple22);
      border-radius: 22px 22px 0 0;

      span {
        font-size: 1rem;
        font-weight: lighter;
      }
    }

    .payment-information__payment-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      padding: 15px;
      margin: 15px auto 15px;

      color: var(--purple22);

      width: 100%;
      max-width: 420px;

      > span {
        margin-right: auto;
        font-size: 1.25rem;
      }
    }

    .payment-wrapper__card {
      display: flex;
      align-items: center;
      gap: 15px;

      width: 100%;
      min-height: 70px;

      padding: 15px;

      font-size: 1rem;
      color: var(--purple22);

      border: 0;
      border-radius: 5px;
      box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
      border: 2px solid transparent;

      &:hover {
        border: 2px solid var(--green2c);
        .payment-wrapper__card__delete {
          background-image: url(${delete_active});
        }
      }
    }

    .payment-wrapper__dots {
      font-size: 1.25rem;
      letter-spacing: 0.1em;
      padding-right: 0.25rem;
    }

    .payment-wrapper__card__flag {
      width: 65px;
      height: 40px;

      background: no-repeat center;
      background-size: contain;

      &.visa {
        background-image: url(${visa});
      }

      &.mastercard {
        background-image: url(${mastercard});
      }
    }

    .payment-wrapper__card__delete {
      width: 18px;
      height: 18px;
      margin-left: auto;

      background: no-repeat center;
      background-size: contain;
      background-image: url(${delete_icon});

      cursor: pointer;
    }

    .payment-wrapper__add-card-anchor {
      position: relative;

      display: flex;
      align-items: center;
      gap: 5px;

      margin: 15px auto 0 0;

      color: var(--purple22);
      font-weight: bold;

      cursor: pointer;

      &:before {
        position: relative;
        content: '';

        width: 41px;
        height: 41px;

        background: no-repeat center;
        background-size: cover;
        background-image: url(${add_icon});
      }
    }

    .payment-wrapper__add-card-anchor-text {
      margin-left: 0.375rem;
      font-size: 1.5rem;
    }

    .payment-information__add-card-form {
      width: 100%;
      max-width: 420px;
      margin: 0 auto;

      padding: 0 1rem 3rem;
    }

    .secondary-button {
      text-transform: uppercase;
    }
  }
`;

export const DeleteCardConfirmation = styled.div`
  &.delete-card-confirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;

    width: 100%;
    max-width: 400px;
    height: 220px;

    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
    color: var(--purple22);
  }

  .delete-card-confirmation__options {
    display: flex;
    justify-content: center;
    gap: 10px;

    flex-wrap: wrap;
  }

  .delete-card-confirmation__button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 140px;
    height: 55px;

    font-size: 1.7rem;
    text-transform: uppercase;
    color: var(--purple22);

    border: 2px solid var(--purple22);
    border-radius: 8px;

    cursor: pointer;
    transition: all 0.5s;

    &:hover {
      color: var(--green2c);
      border: 2px solid var(--green2c);

      // background-color: #effeff;
    }
  }
`;

export const DeleteCreditCardFeedback = styled.div`
  &.delete-card-feedback {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;

    text-align: center;

    width: 100%;
    max-width: 300px;

    padding: 15px;

    font-size: 1.8rem;
    color: var(--purple22);
    font-weight: bold;

    .delete-card-feedback__icon {
      width: 100px;
      height: 100px;

      background: no-repeat center;
      background-size: contain;
      background-image: url(${double_check});
    }
  }
`;

export const AddCreditCardFeedback = styled.div`
  &.add-card-feedback {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;

    width: 100%;
    max-width: 300px;

    padding: 15px;

    font-size: 1.8rem;
    color: var(--purple22);
    font-weight: bold;
    text-align: center;

    .add-card-feedback__icon {
      width: 100px;
      height: 100px;

      background: no-repeat center;
      background-size: contain;
      background-image: url(${double_check});
    }
  }
`;
