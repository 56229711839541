import { AuthContext } from 'contexts/auth';
import { useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'shared/components/atoms/Button/Button';
import { UserSettingsButton } from 'shared/components/molecules/UserSettingsButton/UserSettingsButton';

import logo from '../../../../assets/icons/logo_vagoo.svg';
import { NotificationBell } from '../NotificationBell/NotificationBell';

import {
  HeaderContainer,
  Logo,
  Navbar,
  Hamburger,
  DropShadow,
  Spacer,
  ButtonContainer,
  UserInfoWrapper,
} from './_header';

interface Props {
  setShowSidebar?: Function;
}

export const Header: React.FC<Props> = ({ setShowSidebar }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const { signOut, user } = useContext(AuthContext);
  const userPhoto = user?.avatar_url;

  const [openNavigationMenu, setOpenNavigationMenu] = useState<boolean>(false);

  const handleClick = () => {
    if (setShowSidebar) {
      setShowSidebar(true);
      setOpenNavigationMenu(false);
    }

    if (!setShowSidebar) {
      if (pathname !== 'home') {
        history.push('/home/search');
      }
    }
  };

  const activeLink = () => {
    return pathname === '/minhas-cargas' ? 'active' : '';
  };

  return (
    <>
      <Spacer />

      <HeaderContainer className="header">
        <Logo
          className="header__logo"
          src={logo}
          alt="Logo Vagoo"
          width="150"
          height="25"
        />

        <div className="header__container">
          <ButtonContainer className="header__button-container">
            <Button
              backgroundColor="var(--purple22)"
              onClick={() => handleClick()}
            >
              Solicitar veículo
            </Button>
            <Button
              className={`button button--loadings ${activeLink()}`}
              onClick={() => history.push('/minhas-cargas')}
            >
              Minhas cargas
            </Button>
          </ButtonContainer>

          <Logo
            src={logo}
            alt="Logo Vagoo"
            width="150"
            height="25"
            onClick={() => history.push('/home')}
          />

          <UserInfoWrapper className="header__user-info-wrapper">
            <div className="header__user-info">
              <NotificationBell />
              <UserSettingsButton />
              {userPhoto ? (
                <div className="header__user-info-icon info-icon--photo">
                  <img src={userPhoto} alt="" />
                </div>
              ) : (
                <div className="header__user-info-icon info-icon--user" />
              )}
              {/* <div className="user-info__actions">
                <span onClick={() => history.push('/configuracoes')}>
                  Configurações
                </span>

                <span onClick={() => signOut()}>Sair</span>
              </div> */}
            </div>
          </UserInfoWrapper>
        </div>

        <Hamburger
          openNavigationMenu={openNavigationMenu}
          onClick={() => setOpenNavigationMenu(!openNavigationMenu)}
        />

        <Navbar
          className="header__navbar"
          openNavigationMenu={openNavigationMenu}
        >
          <ButtonContainer className="header__button-container">
            <Button
              backgroundColor="var(--purple22)"
              onClick={() => handleClick()}
            >
              Solicitar veículo
            </Button>
            <Button onClick={() => history.push('/minhas-cargas')}>
              Minhas cargas
            </Button>
          </ButtonContainer>

          <UserInfoWrapper className="header__user-info-wrapper">
            <div className="header__user-info">
              <NotificationBell />
              <UserSettingsButton />
              {userPhoto ? (
                <div className="header__user-info-icon info-icon--photo">
                  <img src={userPhoto} alt="" />
                </div>
              ) : (
                <div className="header__user-info-icon info-icon--user" />
              )}
              {/* <div className="user-info__actions  user-info__actions--mobile">
                <span onClick={() => history.push('/configuracoes')}>
                  Configurações
                </span>

                <span onClick={() => signOut()}>Sair</span>
              </div> */}
            </div>
          </UserInfoWrapper>
        </Navbar>
      </HeaderContainer>

      <DropShadow
        openNavigationMenu={openNavigationMenu}
        onClick={() => setOpenNavigationMenu(!openNavigationMenu)}
      />
    </>
  );
};
