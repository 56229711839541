import { Modal } from "../Modal/Modal";
import { FeedbackContainer } from "./_feedbackModal";

interface FeedbackModalProps {
  message: string;
  setShowModal: (open: boolean) => void;
  showModal: boolean;
}

export const FeedbackModal: React.FC<FeedbackModalProps> = ({ message, setShowModal, showModal }) => {
  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
    >
      <FeedbackContainer>
        {message}
        <div className="feedback__icon" />
      </FeedbackContainer>
    </Modal>
  )
}
