import styled from 'styled-components';

import background_login from '../../assets/img/background_login.png';

export const Container = styled.div`
  &.container {
    width: 100vw;
    height: 100vh;

    background: no-repeat center;
    background-size: cover;
    background-image: url(${background_login});

    animation: entering 0.5s ease-in;
  }
`;

export const LoginWrapper = styled.form`
  &.login-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100vw;
    height: 100vh;

    input::-ms-reveal {
      display: none;
    }

    .login__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;

      width: 100%;
      max-width: 450px;
    }

    .form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 15px;

      width: 100%;
      min-height: 500px;

      padding: 3em 15px;

      border-radius: 20px;
      background-color: rgb(255 255 255 /60%);
    }

    .primary-button {
      font-size: 1.4rem;
      width: 80%;
    }

    .form__forgot-password {
      position: relative;
      font-size: 1.25rem;
      color: var(--green2c);

      cursor: pointer;

      &:after {
        content: '';
        position: absolute;
        bottom: -3px;
        left: 0;
        right: 0;

        border-bottom: 1px solid var(--green2c);
      }
    }

    .form__password-wrapper {
      position: relative;
      width: 100%;
    }

    .form__visible-password-button {
      position: absolute;
      right: 1.5rem;
      top: 55%;

      transform: translateY(-50%);

      background: none;
      border: none;

      color: #adb5bd;

      font-size: 1.5rem;

      cursor: pointer;

      &:hover {
        filter: brightness(0.9);
      }
    }

    .form__log-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
    }

    .log-wrapper__keep-loggedin {
      position: relative;

      display: flex;
      gap: 8px;
      align-items: center;

      color: #868e96;
      font-size: 1.25rem;

      cursor: pointer;

      input[type='checkbox'] {
        display: none;
      }

      input:checked ~ span {
        color: var(--green2c);
        border-color: var(--green2c);
      }

      span {
        position: relative;
        width: 15px;
        height: 15px;
        display: block;
        top: 0;
        left: 0;

        margin-bottom: 3px;

        background-color: #fff;

        font-size: 12.9px;
        text-align: center;
        font-weight: 700;
        color: transparent;

        border: 2px solid #868e96;
        border-radius: 2px;
      }
    }

    .log-wrapper__register-call {
      color: #868e96;
      font-size: 1.35rem;

      span {
        color: var(--green2c);
        cursor: pointer;
      }
    }
  }
`;

export const Logo = styled.img`
  width: 280px;
  height: auto;

  padding: 0 20px;
  @media (min-width: 1024.1px) {
    margin: auto;
  }
`;
