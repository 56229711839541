import styled from 'styled-components';

import spinner from '../../../../assets/icons/spinner.svg';
import white_spinner from '../../../../assets/icons/white_spinner.svg';

export const Container = styled.div`
  &.spinner {
    width: 20px;
    height: 20px;

    margin: 0 auto;
    animation: spin 1s linear infinite;

    /* border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-left-color: #fff;
  border-bottom-color: #fff; */

    background: center no-repeat;
    background-size: cover;
    background-image: url(${spinner});

    &.spinner--white {
      background-image: url(${white_spinner});
    }

    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  }
`;
