import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  width: 100%;
  max-width: 23.75rem;
`;

export const LabelText = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: var(--purple22);
  width: 100%;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  width: 100%;

  font-size: 0.875rem;
  line-height: 1;

  border: 2px solid var(--greenb6);
  border-radius: 0.625rem;

  min-height: 3rem;

  padding: 0 1rem;

  &:focus-within {
    border: 2px solid var(--green2c);
  }
`;

export const ErrorMessage = styled.span`
  position: absolute;
  bottom: -0.875rem;
  left: 0.125rem;
  width: 100%;
  white-space: normal;
  font-size: 0.75rem;
  color: #ff6565;
`;

export const Prefix = styled.span`
  font-size: inherit;
  width: fit-content;
  height: fit-content;
  line-height: inherit;
  display: block;

  color: var(--green2c);
`;

export const StyledInput = styled.input`
  display: block;
  border: none;
  font-size: inherit;
  line-height: 1.15;
  outline: none;
  min-width: 0px;
  width: 8ch;
  flex: 1;

  color: var(--purple22);
`;
