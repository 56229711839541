import { MutableRefObject, useEffect, useRef } from 'react';

type UseOutsideHook = (callback: Function) => {
  ref: MutableRefObject<HTMLDivElement | null>;
};

const useOutside: UseOutsideHook = (callback) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleClick = (e: MouseEvent) => {
    if (ref.current && ref.current.contains(e.target as Node)) {
      return;
    }
    callback();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [ref]);

  return { ref };
};

export default useOutside;
