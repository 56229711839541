import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const IconButton = styled.button<{
  showBadge: boolean;
}>`
  background: transparent;
  border: none;
  position: relative;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    width: 20px;
    height: 20px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--purple22);
    opacity: ${(props) => (props.showBadge ? 1 : 0)};
  }
`;

export const NotificationsContainer = styled.div<{
  isExpanded: boolean;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 5%);

  transition: all 0.3s ease-in-out;

  min-width: 17.5rem;
  max-height: ${(props) => (props.isExpanded ? '100vh' : '0px')};
  overflow: ${(props) => (props.isExpanded ? 'auto' : 'hidden')};

  border-radius: 0.875rem;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  @media (max-width: 1024.1px) {
    top: auto;
    bottom: 0%;
    transform: translate(-10%, -10%);
  }
`;

export const NotificationsList = styled.div<{
  isExpanded: boolean;
}>`
  height: fit-content;

  transition: all 0.3s ease-in-out;

  max-height: ${(props) => (props.isExpanded ? '50vh' : '0px')};
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #e6e6ef;
    border-radius: 4px;

    margin: 0.75rem 0;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--purple22);
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    opacity: 0.9;
  }

  > * {
    &:nth-child(2n + 1) {
      background-color: #e6feff;

      button {
        background-color: var(--green2c);
      }
    }
  }
`;

export const NotificationItem = styled.div`
  padding: 1rem;

  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const NotificationItemBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

export const NotificationText = styled.p`
  font-size: 0.75rem;
  color: var(--purple22);
  line-height: 1rem;
`;

export const NotificationActionButton = styled.button`
  background-color: var(--purple22);
  text-transform: uppercase;
  font-size: 0.625rem;
  font-weight: bold;
  color: #fff;

  min-height: 1.75rem;
  border-radius: 0.375rem;
  padding: 0.5rem;

  white-space: nowrap;

  min-width: 8.75rem;

  border: none;

  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:disabled {
    filter: saturate(0.3);
    cursor: not-allowed;
  }
`;

export const NotificationTimeSince = styled.time`
  font-size: 0.75rem;
  color: #706aa8;
`;

export const PlaceholderText = styled.p`
  text-align: center;
  font-size: 0.75rem;
  color: var(--purple22);
  line-height: 1rem;
`;
