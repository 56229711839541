import styled from 'styled-components';

import truck from '../../../../assets/icons/truck.svg';
import cubage from '../../../../assets/icons/cubage.svg';
import plate from '../../../../assets/icons/plate.svg';

export const Container = styled.div`
  &.request-result {
    display: flex;
    flex-direction: column;
    gap: 30px;

    padding: 30px 15px;

    .request-result__info-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .request-result__distance {
      font-size: 0.9rem;
    }

    .request-result__company {
      font-size: 1.5rem;
      font-weight: bold;
    }

    .request-result__truck-info {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      width: 100%;
      max-width: 380px;

      padding: 15px;

      font-size: 1rem;
      font-weight: bold;

      border: 1px solid var(--green2c);
      border-radius: 10px;

      .truck-info__detail {
        display: flex;
        flex-direction: column;
        gap: 5px;

        span {
          font-size: 0.7rem;
          font-weight: normal;
        }
      }

      .truck-info__icon {
        width: 25px;
        height: 25px;

        background: no-repeat center;
        background-size: contain;
        &.truck-info__icon--truck {
          background-image: url(${truck});
        }

        &.truck-info__icon--cubage {
          background-image: url(${cubage});
        }

        &.truck-info__icon--plate {
          background-image: url(${plate});
        }
      }
    }

    .request-result__divider {
      width: 70px;
      height: 1px;

      border-bottom: 1px solid var(--green2c);
    }

    .request-result__summary {
      display: flex;
      flex-direction: column;

      width: 100%;
      max-width: 380px;

      color: var(--purple22);
      font-size: 0.9rem;

      background-color: #effeff;
      border-radius: 10px;
    }

    .summary__title {
      font-size: 1.25rem;
      font-weight: bold;

      padding: 15px;
    }

    .summary__values {
      display: flex;
      flex-direction: column;
      gap: 15px;

      font-weight: lighter;

      padding: 15px;
    }

    .values__row {
      display: inline-flex;
      justify-content: space-between;

      padding: 0 3px;
    }

    .summary__total {
      display: inline-flex;
      justify-content: space-between;

      padding: 15px 18px;

      font-weight: bold;
      text-transform: uppercase;

      border-top: 1px solid var(--green2c);
    }

    .secondary-button {
      max-width: 380px;
      margin: unset;

      font-weight: normal;
      text-transform: uppercase;
    }
  }
`;
