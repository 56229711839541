import styled from 'styled-components';

import blue_key_icon from 'assets/icons/blue_key_icon.svg';
import white_key_icon from 'assets/icons/white_key_icon.svg';

interface Props {
  color: string;
  background: string;
}

const icon = (props: Props) =>
  props.background === 'var(--purple22)' ? white_key_icon : blue_key_icon;

export const Container = styled.div<Props>`
  &.cargo-details {
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 1200px;
    min-height: 60px;

    .cargo-details__header {
      position: relative;

      display: flex;
      align-items: center;
      gap: 15px;
      flex-wrap: wrap;

      width: 100%;
      max-width: 1200px;
      min-height: 60px;

      padding: 15px 30px;

      font-size: 1.1rem;
      color: ${({ color }) => color};
      background-color: ${({ background }) => background};

      border-radius: 10px 10px 0 0;

      &:before {
        content: '';

        position: relative;

        width: 14px;
        height: 28px;

        background: no-repeat center;
        background-size: contain;
        background-image: url(${icon});
      }
    }

    .cargo-details__content {
      display: flex;
    }

    .cargo-details__map-container {
      position: relative;
      flex: 1;
      min-width: 300px;
    }

    .cargo-details__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      width: 100%;
      max-width: 430px;
      height: 74vh;
      padding-bottom: 1rem;

      overflow: auto;

      box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);

      ::-webkit-scrollbar {
        width: 6px;
      }

      ::-webkit-scrollbar-track {
        background: #e6e6ef;
        border-radius: 4px;
      }

      ::-webkit-scrollbar-thumb {
        background: var(--purple22);
        border-radius: 4px;
      }

      ::-webkit-scrollbar-thumb:hover {
        opacity: 0.9;
      }
    }

    .cargo-details__code {
      margin-right: 30px;

      font-weight: bold;
    }

    .cargo-details__selector {
      position: relative;

      margin: 0 10px;
      cursor: pointer;
    }

    .cargo-details__selector--active {
      &:after {
        content: '';

        position: absolute;
        top: 34px;
        left: -10px;
        right: -10px;

        height: 4px;
        border-radius: 0 0 4px 4px;

        background-color: ${({ color }) => color};
      }
    }

    @media (max-width: 700px) {
      .cargo-details__content {
        flex-wrap: wrap;
      }

      .cargo-details__map-container {
        height: 500px;
        width: 100%;
      }

      .cargo-details__wrapper {
        max-width: unset;
      }
    }
  }
`;

export const ClockContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 99;

  border-radius: 0.625rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  background-color: #fff;

  padding: 1rem 1.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 0.625rem;
  color: var(--purple22);
`;

export const ClockText = styled.p`
  font-size: 2.25rem;
  font-weight: bold;
`;
