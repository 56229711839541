import styled from 'styled-components';

import sad from '../../../../assets/icons/sad.svg';

export const Container = styled.div`
  &.search-feedback {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;

    width: 100%;
    max-width: 390px;
    min-height: 350px;

    padding: 30px;

    color: var(--purple22);
    font-size: 1.4rem;
    text-align: center;

    background-color: #cfcbf140;
    border-radius: 10px;

    .spinner {
      width: 45px;
      height: 45px;

      margin: -50px auto 0;
    }

    .search-feedback__not-found {
      width: 45px;
      height: 45px;

      background-repeat: no-repeat center;
      background-size: cover;
      background-image: url(${sad});
    }

    .search-feedback__text-container {
      line-height: 2rem;
      max-width: 20rem;
    }

    .search-feedback__text {
      font-size: 1.375rem;
      font-weight: bold;
      color: var(--purple22);
    }

    .secondary-button {
      font-size: 1rem;
      font-weight: 600;
      letter-spacing: 0.08em;
      text-transform: uppercase;
    }
  }
`;
