import styled from 'styled-components';

import bank_slip from '../../../../assets/icons/bank_slip.svg';
import mastercard from '../../../../assets/icons/mastercard.svg';
import visa from '../../../../assets/icons/visa.svg';

import add_icon from '../../../../assets/icons/add_icon.svg';
import { ReactComponent as EditIconRaw } from '../../../../assets/icons/edit_icon.svg';

export const Container = styled.div`
  &.payment-section {
    display: flex;
    flex-direction: column;
    gap: 7px;

    height: 570px;
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #e6e6ef;
      border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--purple22);
      border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb:hover {
      opacity: 0.9;
    }

    .payment-section__payment-method {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      width: 100%;
      max-width: 320px;

      margin-right: auto;

      span {
        font-size: 0.75rem;
      }
    }

    .payment-section__bank-slip {
      position: relative;

      display: flex;
      align-items: center;
      gap: 10px;

      width: 100%;
      max-width: 320px;
      min-height: 38px;

      padding: 0 1rem;

      color: #868e96;
      font-size: 0.75rem;
      font-weight: 500;
      text-align: center;

      border: 1px solid transparent;
      border-radius: 12px;
      box-shadow: 0 3px 6px rgb(0 0 0 /12%);

      cursor: pointer;

      &.active {
        border: 1px solid var(--green2c);
        color: var(--green2c);

        &:before {
          // Color background-image with #50BCC1
          // Convert from Black to target hex color using filter
          filter: brightness(0) saturate(100%) invert(72%) sepia(98%)
            saturate(263%) hue-rotate(129deg) brightness(79%) contrast(90%);
        }
      }

      &:before {
        position: relative;
        content: '';

        width: 25px;
        height: 13px;

        background: no-repeat center contain;
        background-image: url(${bank_slip});
      }
    }

    .secondary-button {
      max-width: 380px;
      min-height: 45px;

      margin-top: 10px;

      text-transform: uppercase;
    }

    .add-card-form {
      margin: 0px;
    }
  }
`;

export const PillSelector = styled.div`
  &.pill-selector {
    position: relative;

    display: flex;

    width: 100%;
    max-width: 320px;
    color: var(--purple22);
    font-size: 0.8rem;
    text-align: center;

    border-radius: 12px;
    box-shadow: 0 3px 6px rgb(0 0 0 /12%);

    &:before {
      position: absolute;

      content: '';

      left: 50%;
      top: 3px;
      bottom: 3px;

      transform: translateX(-50%);

      width: 1px;
      background-color: var(--purple22);
    }
    .pill-selector__option {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 50%;
      min-height: 35px;

      background-color: #b6eaed;
      border-radius: 12px 0 0 12px;

      cursor: pointer;
      transition: all 0.5s;
    }

    .pill-selector__option.active {
      background-color: var(--purple22);
      color: #fff;
    }

    .pill-selector__option--left {
      border-radius: 12px 0 0 12px;
    }

    .pill-selector__option--right {
      border-radius: 0 12px 12px 0;
    }
  }
`;

export const CreditCardSection = styled.section`
  &.payment-section__credit-card-section {
    display: flex;
    flex-direction: column;
    gap: 15px;

    width: 100%;
    max-width: 370px;

    padding: 15px 0;

    border-bottom: 1px solid #ced4da;

    animation: entering 0.5s ease-in;

    .credit-card-section__add-credit-card {
      .add-card-form {
        margin: 15px 0;
        padding: 0;
      }
    }

    .credit-card-section__add-credit-card-anchor {
      position: relative;

      display: flex;
      align-items: center;
      gap: 5px;

      margin: 10px auto 0 0;

      color: var(--purple22);
      font-size: 1.25rem;
      font-weight: bold;

      cursor: pointer;

      &:before {
        position: relative;
        content: '';

        width: 30px;
        height: 30px;

        background: no-repeat center;
        background-size: cover;
        background-image: url(${add_icon});
      }
    }

    .add-credit-card__extras {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      width: 100%;

      color: var(--purple22);
      font-weight: bold;
    }
  }
`;

export const CreditCard = styled.button`
  &.credit-card {
    display: flex;
    align-items: center;
    gap: 15px;

    width: 100%;
    min-height: 50px;

    padding: 5px 15px;

    font-size: 1rem;
    color: var(--purple22);

    border: 0;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    border: 2px solid transparent;

    cursor: pointer;

    &.active {
      border: 2px solid var(--green2c);
    }

    .credit-card__flag {
      width: 65px;
      height: 40px;

      background: no-repeat center;
      background-size: contain;

      &.visa {
        background-image: url(${visa});
      }

      &.mastercard {
        background-image: url(${mastercard});
      }
    }

    .credit-card__dots {
      font-size: 1.25rem;
      letter-spacing: 0.1em;
      padding-right: 0.25rem;
    }
  }
`;

export const Radio = styled.div`
  &.radio {
    display: flex;
    flex-wrap: wrap;

    gap: 15px;

    padding: 15px 0;

    width: 100%;

    .radio__option {
      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;

      cursor: pointer;
      user-select: none;

      input {
        opacity: 0;
        height: 0;
        width: 0;
      }

      input:checked ~ .radio__selection {
        color: var(--green2c);

        &:before {
          border: 1px solid var(--green2c);
          box-shadow: 0 0 0px 4px var(--green2c);
        }
      }

      .radio__selection {
        position: relative;

        display: flex;
        align-items: center;

        padding-left: 18px;

        color: #868e96;

        &:before {
          content: '';
          position: absolute;

          top: 45%;
          left: 0;

          transform: translateY(-50%);

          width: 7px;
          height: 7px;

          border-radius: 14px;
          border: 1px solid transparent;
          box-shadow: 0 0 0px 4px #868e96;
        }
      }
    }

    .radio__add-address {
      margin: 0 15px;

      border-radius: 4px;
      text-transform: unset;

      display: flex;
      align-items: center;
      justify-content: center;

      min-height: 1.6875rem;
      height: fit-content;
      box-shadow: none;
      padding: 0 1.75rem;

      font-size: 0.6875rem;
      font-weight: bold;
      line-height: 1rem;
    }
  }
`;

export const Checkbox = styled.div`
  position: relative;
  display: flex;

  font-size: 12px;
  font-weight: lighter;
  color: var(--purple22);

  .checkbox__label {
    display: block;
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    font-size: 14px;
    user-select: none;
    text-transform: none;

    input[type='checkbox'] {
      display: none;
    }

    input:checked ~ span {
      border: 3px solid var(--green2c);

      color: var(--green2c);
      padding: 1px 0;
    }

    span {
      display: block;
      position: absolute;

      top: 0;
      left: 0;

      width: 14px;
      height: 14px;

      font-size: 9px;
      color: #fff;
      font-weight: 700;
      text-align: center;

      border: 3px solid #adb5bd;
      border-radius: 3px;
      background-color: #fff;
    }
  }
`;

export const Address = styled.div`
  &.address {
    display: flex;
    flex-direction: column;
    gap: 15px;
    flex: 1;

    width: 520px;

    padding: 0 5px;

    .address__title {
      font-size: 1.4rem;
      margin-bottom: 15px;
    }

    .square-input__input {
      border-width: 1px;
    }

    .address__field-row {
      display: flex;
      gap: 15px;
      flex-wrap: wrap;
    }

    .address__field--cep {
      max-width: 120px;
    }

    .address__field--address {
      width: 100%;
      max-width: 375px;
    }

    .address__field--neighboorhood,
    .address__field--city {
      width: 100%;
      max-width: 180px;
    }

    .address__field--state {
      width: 100%;
      max-width: 118px;
    }

    .address__field--id {
      width: 100%;
      max-width: 252px;
    }

    .secondary-button {
      margin-top: 15px;
    }
    @media (max-width: 600px) {
      width: 100%;
      max-width: 520px;
    }
  }
`;

export const AddressFeedback = styled.div`
  &.address-feedback {
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 5px;

    width: 100%;

    color: var(--purple22);
    font-size: 0.8rem;
    font-weight: 400;

    padding: 10px 24px;
    margin: 10px 0;

    border: 2px solid var(--greenb6);
    border-left: none;
    border-radius: 0.25rem;

    .address-feedback__header {
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .address-feedback__title {
      width: fit-content;
      padding: 10px;

      background-color: #effeff;

      border-radius: 15px;

      margin-bottom: 10px;
      white-space: nowrap;

      font-weight: 400;
      padding: 0.5rem 0.875rem;
    }

    .address-feedback__edit-button {
      /* position: absolute;
      top: 15px;
      right: 15px;

      width: 12px;
      height: 12px;

      background: no-repeat center 3px;

      cursor: pointer; */

      background: transparent;
      border: none;
      cursor: pointer;
    }

    &:before {
      position: absolute;
      top: -2px;
      bottom: -2px;
      left: 0;

      content: '';

      width: 7px;

      border-radius: 5px 0 0 5px;
      background-color: var(--purple22);
    }
  }
`;

export const BanksSlipSection = styled.section`
  &.bank-slip-section {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;

    width: 100%;
    max-width: 365px;

    padding: 30px;
    margin: 15px 0;

    color: var(--purple22);
    font-size: 1rem;
    text-align: center;

    border-radius: 22px;
    box-shadow: 0 3px 9px rgb(0 0 0/16%);

    animation: entering 0.5s ease-in;

    .bank-slip-section__title {
      font-size: 1.1rem;
      font-weight: bold;
    }

    .bank-slip-section__info {
      color: #6f6f6f;
    }

    .secondary-button {
      text-transform: unset;
    }

    @media (min-width: 568px) {
      margin: 15px 3px;
    }
  }
`;

export const PixSection = styled.section`
  &.pix-section {
    display: flex;
    flex-direction: column;
    gap: 15px;

    width: 100%;
    max-width: 365px;

    padding: 30px 0;

    color: var(--purple22);
    font-size: 1rem;

    animation: entering 0.5s ease-in;

    .pix-section__info {
      color: var(--purple22);
      font-weight: bold;
    }

    .pix-section__row {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;

      @media (min-width: 568px) {
        flex-wrap: nowrap;
      }
    }

    .pix-section__code {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      min-height: 40px;

      padding: 5px;

      color: #2cccd3;
      font-size: 0.75rem;

      background-color: #cdf0f2;
      border-radius: 4px;

      @media (min-width: 568px) {
        max-width: 260px;
      }
    }

    .pix-section__steps {
      display: flex;
      flex-direction: column;
      gap: 14px;
      font-size: 0.9rem;
    }

    .primary-button {
      border-radius: 4px;
      height: 40px;

      @media (min-width: 568px) {
        max-width: 130px;
      }
    }
  }
`;

export const RequestSummary = styled.div`
  &.request-summary {
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 380px;

    color: var(--purple22);
    font-size: 0.875rem;

    background-color: #effeff;
    border-radius: 10px;

    .request-summary__title {
      font-size: 1.25rem;
      font-weight: bold;

      padding: 15px;
    }

    .request-summary__values {
      display: flex;
      flex-direction: column;
      gap: 15px;

      font-weight: lighter;

      padding: 15px;
    }

    .request-summary__values__row {
      display: inline-flex;
      justify-content: space-between;
      width: 100%;

      padding: 0 3px;

      font-size: 0.875rem;
      font-weight: 400;

      color: var(--purple22);
    }

    .request-summary__total {
      display: inline-flex;
      justify-content: space-between;

      padding: 15px 18px;

      font-weight: bold;
      text-transform: uppercase;

      border-top: 1px solid var(--green2c);
    }
  }
`;

export const EditIcon = styled(EditIconRaw)`
  width: 16px;
  height: 16px;
`;
