import { useEffect, useState } from 'react';

import api from 'services/api';

import { Header } from 'shared/components/organisms/Header/Header';
import { Sidebar } from 'pages/Sidebar/Sidebar';

import { handleError } from 'services/errorHandler';

import { Container, Content } from './_home';

import { IVehiclesResponse } from 'types/mainTypes';
import { Map } from 'shared/components/organisms/Map/Map';
import useSocket from 'shared/hooks/useSocket.hook';
import {
  RequestTripFormData,
  RequestVehicleResponse,
} from 'types/requestVehicleTypes';

export interface MapVehicles {
  [key: string]: {
    lat: number;
    lng: number;
  };
}

export interface VehicleUpdatesSocketEvent {
  vehicle_id: string;
  position: {
    latitude: number;
    longitude: number;
  };
}

export const Home = () => {
  const { socket } = useSocket();

  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const [nearVehicles, setNearVehicles] = useState<MapVehicles>();

  const [requestStep, setRequestStep] = useState<string>('default');
  const [requestVehicleData, setRequestVehicleData] =
    useState<RequestTripFormData>();
  const [requestVehicleSearchResult, setRequestVehicleSearchResult] =
    useState<RequestVehicleResponse>();

  const [center, setCenter] = useState({
    lat: -23.55052,
    lng: -46.633309,
  });

  useEffect(() => {
    fetchNearVehicles();
  }, []);

  useEffect(() => {
    socket?.on('vehicle-updates', (e: VehicleUpdatesSocketEvent) => {
      if (!e?.vehicle_id || !e?.position) {
        return;
      }

      const { vehicle_id } = e;

      const lat = e.position?.latitude || 0;
      const lng = e.position?.longitude || 0;

      setNearVehicles((prev) => {
        if (prev?.[vehicle_id]) {
          if (
            Math.abs(prev[vehicle_id].lat - lat) < 0.0001 &&
            Math.abs(prev[vehicle_id].lng - lng) < 0.0001
          ) {
            return prev;
          } else {
            return {
              ...prev,
              [vehicle_id]: {
                lat,
                lng,
              },
            };
          }
        }

        return {
          ...prev,
          [vehicle_id]: {
            lat,
            lng,
          },
        };
      });
    });

    return () => {
      socket?.off('vehicle-updates');
    };
  }, [socket]);

  const fetchNearVehicles = async () => {
    const params = {
      latitude: center.lat,
      longitude: center.lng,
      range: 500,
      limit: 20,
      page: 1,
    };

    try {
      const response = await api.get<IVehiclesResponse>('/vehicles', {
        params,
      });

      const mapVehicles = response.data.results.reduce((acc, vehicle) => {
        return {
          ...acc,
          [vehicle.id]: {
            lat: vehicle?.geolocation?.coordinates?.[1] || 0,
            lng: vehicle?.geolocation?.coordinates?.[0] || 0,
          },
        };
      }, {} as MapVehicles);

      setNearVehicles(mapVehicles);
    } catch (error: any) {
      handleError(error?.response?.data?.message || error?.toString());
    }
  };

  const tripVehicleId =
    requestStep === 'success' && !!requestVehicleSearchResult?.trip
      ? requestVehicleSearchResult?.trip?.vehicle_id
      : '';

  const changeCenter = (lat: number, lng: number) => {
    if (lat && lng) {
      setCenter({ lat, lng });
    }
  };

  return (
    <Container>
      <Header setShowSidebar={setShowSidebar} />
      <Content>
        <div className="map-container">
          <Map
            fixedZoomContainer
            nearVehicles={nearVehicles}
            activeVehicleId={tripVehicleId}
            center={center}
            changeCenter={changeCenter}
          />
        </div>
        <Sidebar
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
          requestStep={requestStep}
          setRequestStep={setRequestStep}
          requestVehicleData={requestVehicleData}
          setRequestVehicleData={setRequestVehicleData}
          requestVehicleSearchResult={requestVehicleSearchResult}
          setRequestVehicleSearchResult={setRequestVehicleSearchResult}
        />
      </Content>
    </Container>
  );
};
