import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { Container } from './_input';

interface IconInputProps extends React.HTMLAttributes<HTMLElement> {
  placeholder: string;
  inputValue?: string;
  setInputValue?: Function;
  type?: string;
  maxLength?: number;
  register?: UseFormRegisterReturn;
  disabled?: boolean;
  defaultValue?: string;
}

export const Input: React.FC<IconInputProps> = ({
  placeholder,
  inputValue,
  setInputValue,
  type,
  maxLength,
  register,
  disabled,
  defaultValue,
  ...rest
}) => {
  return (
    <Container className="icon-input" {...rest}>
      <label className="icon-input__wrapper" htmlFor={placeholder}>
        <div className="icon-input__icon" />

        {register && (
          <input
            type={type || 'text'}
            className="input__input"
            placeholder={placeholder}
            id={`input-${placeholder}`}
            maxLength={maxLength}
            defaultValue={defaultValue && defaultValue}
            value={inputValue && inputValue}
            {...register}
            disabled={disabled}
          />
        )}

        {!register && (
          <input
            type={type || 'text'}
            className="input__input"
            placeholder={placeholder}
            id={`input-${placeholder}`}
            maxLength={maxLength}
            defaultValue={defaultValue && defaultValue}
            value={inputValue && inputValue}
            disabled={disabled}
            onChange={(e) => setInputValue && setInputValue(e.target.value)}
          />
        )}
      </label>
    </Container>
  );
};
