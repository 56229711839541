import styled from 'styled-components';

import edit_icon from '../../../../assets/icons/edit_icon.svg';

export const Container = styled.div`
  &.manager-info {
    margin: 3em auto 0;

    padding: 0 15px;

    width: 100%;
    max-width: 595px;

    animation: slide_left 0.5s;
  }
`;

export const Card = styled.div`
  &.manager-info__card {
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 595px;

    padding: 0 0 3em;

    border-radius: 20px;

    box-shadow: 0 3px 6px rgb(0 0 0 /16%);

    .card__title {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      padding: 15px;

      color: #fff;
      font-size: 1rem;

      border-radius: 20px 20px 0 0;

      background-color: var(--green2c);

      b {
        font-size: 1.2rem;
        font-weight: bold;
      }
    }

    .card__body {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;

      padding: 15px;
    }

    .card__photo {
      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;

      width: 165px;
      height: 165px;

      border: 3px solid var(--green2c);
      border-radius: 50%;
      background-color: #dedede;

      img {
        width: 100%;
        height: 100%;

        border-radius: 50%;
        object-fit: cover;
      }

      .photo-input {
        position: absolute;
        bottom: 0px;
        right: -10px;
      }
    }

    .card__manager-name {
      color: var(--purple22);
      font-size: 1.9rem;
    }

    .card__input-wrapper {
      display: flex;
      flex-direction: column;
      gap: 15px;

      width: 100%;
      max-width: 385px;
    }

    .icon-input {
      position: relative;

      &:after {
        content: '';

        position: absolute;
        top: 50%;
        right: 20px;

        width: 20px;
        height: 20px;

        transform: translateY(-50%);

        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-image: url(${edit_icon});

        filter: grayscale(1);
        opacity: 0.4;
        cursor: pointer;
      }
    }

    .input__input {
      align-items: flex-start;
      text-align: left;

      font-size: 1.4rem;
    }

    .card__password-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;

      margin-top: 15px;

      width: 100%;
      max-width: 345px;
    }

    .card__password {
      display: flex;
      flex-direction: column;
      gap: 5px;

      color: #868e96;
      font-size: 1.2rem;

      input {
        font-size: 1.25rem;
        letter-spacing: 2px;

        border: 0;
        background-color: transparent;
        max-width: 150px;

        &:placeholder {
          color: var(--purple22);
        }
      }
    }

    .card__password-anchor {
      font-size: 1.25rem;
      color: var(--green2c);

      border-bottom: 1px solid var(--green2c);

      cursor: pointer;
    }
  }
`;
