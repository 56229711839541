import React from 'react';
import { useHistory } from 'react-router-dom';
import { SecondaryButton } from 'shared/components/atoms/SecondaryButton/SecondaryButton';
import { Spinner } from 'shared/components/atoms/Spinner/Spinner';

import { Container } from './_searchFeedbackComponent';

interface Props {
  requestStep: string;
  setRequestStep: Function;
  tripId?: string;
}

export const SearchFeedbackComponent: React.FC<Props> = ({
  requestStep,
  setRequestStep,
  tripId,
}) => {
  const history = useHistory();

  const renderSearchFeedback = () => {
    if (requestStep === 'searching') {
      return (
        <>
          <Spinner />
          <div className="search-feedback__text-container">
            <p className="search-feedback__text">Aguarde!</p>
            <p className="search-feedback__text">
              Estamos localizando o seu transportador.
            </p>
          </div>
        </>
      );
    }

    if (requestStep === 'not-found') {
      return (
        <>
          <div className="search-feedback__not-found" />

          <div className="search-feedback__text-container">
            <p className="search-feedback__text">
              Infelizmente não encontramos nenhum veículo disponível.
            </p>
            <p className="search-feedback__text">
              Aumente o raio de busca e tente novamente.
            </p>
          </div>

          <SecondaryButton
            onClick={() => {
              if (tripId) {
                history.push(`/home/search/${tripId}`);
              } else {
                setRequestStep('default');
              }
            }}
          >
            Tente novamente
          </SecondaryButton>
        </>
      );
    }
  };

  return (
    <Container className="search-feedback">{renderSearchFeedback()}</Container>
  );
};
