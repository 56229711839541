import styled from 'styled-components';
export const Address = styled.form`
  &.address {
    display: flex;
    flex-direction: column;
    gap: 15px;
    flex: 1;

    width: 520px;

    padding: 0 5px;

    .address__title {
      font-size: 1.4rem;
      margin-bottom: 15px;
    }

    .address__field-row {
      display: flex;
      gap: 15px;
      flex-wrap: wrap;
    }

    .address__field--cep {
      max-width: 120px;
    }

    .address__field--address {
      width: 100%;
      max-width: 375px;
    }

    .address__field--neighboorhood,
    .address__field--city {
      width: 100%;
      max-width: 180px;
    }

    .address__field--state {
      width: 100%;
      max-width: 118px;
    }

    .address__field--id {
      width: 100%;
      max-width: 252px;
    }

    .secondary-button {
      margin-top: 15px;

      .spinner {
        width: 30px;
        height: 30px;

        border-radius: 50%;
      }
    }
    @media (max-width: 600px) {
      width: 100%;
      max-width: 520px;
    }
  }
`;
