import { forwardRef } from 'react';
import {
  Container,
  ErrorMessage,
  InputWrapper,
  LabelText,
  Prefix,
  StyledInput,
} from './_prefixInput';

interface PrefixInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  prefix: string;
  error?: string;
  label?: string;
  inputClassName?: string;
  labelClassName?: string;
  containerClassName?: string;
  prefixClassName?: string;
}

export const PrefixInput = forwardRef<HTMLInputElement, PrefixInputProps>(
  (
    {
      error,
      prefix,
      label,
      inputClassName,
      labelClassName,
      containerClassName,
      prefixClassName,
      ...rest
    },
    ref,
  ) => {
    return (
      <Container as="label" className={containerClassName}>
        {label && <LabelText className={labelClassName}>{label}</LabelText>}
        <InputWrapper>
          <Prefix className={prefixClassName}>{prefix}</Prefix>
          <StyledInput className={inputClassName} {...rest} ref={ref} />
        </InputWrapper>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Container>
    );
  },
);
