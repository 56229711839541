import useOutside from "shared/hooks/useOutside.hook";
import { CloseButton, Container, DocumentImage } from "./_deliveryDocumentModal";

interface DeliveryDocumentModalProps {
  url: string;
  onClose: () => void;
  isOpen: boolean;
}

export const DeliveryDocumentModal: React.FC<DeliveryDocumentModalProps> = ({ url, onClose, isOpen }) => {

  const handleClose = () => {
    onClose();
  }

  const { ref } = useOutside(handleClose);

  return isOpen ? (
    <Container ref={ref}>
      <CloseButton type="button" onClick={onClose}>&times;</CloseButton>
      <DocumentImage src={url} alt="Comprovante de entrega enviado" />
    </Container>
  ) : null;
}
