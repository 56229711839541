import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import api from 'services/api';
import { handleError } from 'services/errorHandler';
import { tokenizeCard } from 'services/pagarme';
import { SecondaryButton } from 'shared/components/atoms/SecondaryButton/SecondaryButton';

import { Modal } from 'shared/components/organisms/Modal/Modal';
import useAuth from 'shared/hooks/useAuth.hook';
import { createCardSchema } from 'shared/utils/schemas';
import {
  ICreateCardForm,
  ICreditCard,
  IGetCardsResponse,
} from 'types/paymentTypes';
import { AddCreditCardComponent } from '../../../../shared/components/organisms/AddCreditCardComponent/AddCreditCardComponent';

import {
  AddCreditCardFeedback,
  Container,
  DeleteCardConfirmation,
  DeleteCreditCardFeedback,
} from './_paymentInformationComponent';

export const PaymentInformationComponent: React.FC = () => {
  const { user } = useAuth();
  const [cards, setCards] = useState<ICreditCard[]>([]);

  const [deleteCardId, setDeleteCardId] = useState<string>('');
  const [showDeleteFeedback, setShowDeleteFeedback] = useState<boolean>(false);
  const [showAddCardModal, setShowAddCardModal] = useState<boolean>(false);

  const [showAddCreditCardFrom, setShowAddCreditCardForm] =
    useState<boolean>(false);

  const {
    control,
    register,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState: { isSubmitting, isValid },
  } = useForm<ICreateCardForm>({
    resolver: yupResolver(createCardSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const fetchCards = async () => {
    try {
      const response = await api.get<IGetCardsResponse>('/cards');

      setCards(response.data.results);
      if (response.data.results.length === 0) {
        setShowAddCreditCardForm(true);
      }
    } catch (error: any) {
      handleError(
        error?.response?.data?.message ||
          'Algo deu errado, tente novamente mais tarde',
      );
    }
  };

  const handleDelete = async () => {
    const id = deleteCardId;
    setDeleteCardId('');
    try {
      await api.delete(`/cards/${id}`);

      setCards((prevState) => prevState.filter((card) => card.id !== id));
      setShowDeleteFeedback(true);
    } catch (error: any) {
      handleError(
        error?.response?.data?.message ||
          'Algo deu errado, tente novamente mais tarde',
      );
    }
  };

  const onSubmit = async (data: ICreateCardForm) => {
    if (!user) return;
    const {
      cvv,
      expiration_date,
      holder_name,
      number,
      newBillingAddress,
      address,
    } = data;

    const [stringMonth, stringYear] = expiration_date.split('/');
    const exp_month = parseInt(stringMonth);
    const exp_year = parseInt(stringYear);

    const cardData = {
      exp_month,
      exp_year,
      cvv,
      holder_name,
      number: number.replace(/\D/g, ''),
    };

    const existingAddress = user.legal_person
      ? user.legal_person.address
      : user?.individual_person?.address;

    try {
      const token = await tokenizeCard(
        { type: 'card', card: cardData },
        newBillingAddress ? address : existingAddress,
      );

      const response = await api.post('/cards', {
        card_hash: token.id,
      });

      setShowAddCardModal(true);

      setCards((prevState) => [...prevState, response.data]);

      reset();
    } catch (error: any) {
      handleError(
        error?.response?.data?.message ||
          'Algo deu errado, tente novamente mais tarde',
      );
    }
  };

  const renderAddCardSection = () => {
    if (showAddCreditCardFrom) {
      return (
        <form
          className="payment-information__add-card-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <AddCreditCardComponent
            register={register}
            setValue={setValue}
            watch={watch}
            control={control}
          />
          <SecondaryButton type="submit" disabled={isSubmitting || !isValid}>
            Salvar cartão
          </SecondaryButton>
        </form>
      );
    }

    return;
  };

  const handleToggleDeleteModal = (open: boolean) => {
    if (!open) {
      setDeleteCardId('');
    }
  };

  useEffect(() => {
    fetchCards();
  }, []);

  return (
    <Container className="payment-information">
      <div className="payment-information__title">
        Pagamento
        <span>Edite suas informações de pagamento aqui</span>
      </div>

      <div className="payment-information__payment-wrapper">
        <span>
          {cards.length > 0
            ? 'Seus cartões:'
            : 'Você não possui cartões cadastrados'}
        </span>
        {cards.map((card) => (
          <div key={card.id} className="payment-wrapper__card">
            <div
              className={`payment-wrapper__card__flag ${card.card_brand.toLowerCase()}`}
            />
            <span className="payment-wrapper__card-number">
              <span className="payment-wrapper__dots">
                &bull;&bull;&bull;&bull;
              </span>
              <span>{card.last_digits}</span>
            </span>

            <div
              className="payment-wrapper__card__delete"
              onClick={() => setDeleteCardId(card.id)}
            />
          </div>
        ))}
        <div
          className="payment-wrapper__add-card-anchor"
          onClick={() => setShowAddCreditCardForm(!showAddCreditCardFrom)}
        >
          <span className="payment-wrapper__add-card-anchor-text">
            Adicionar cartão
          </span>
        </div>
      </div>

      {renderAddCardSection()}

      <Modal showModal={!!deleteCardId} setShowModal={handleToggleDeleteModal}>
        <DeleteCardConfirmation className="delete-card-confirmation">
          Você tem certeza que deseja excluir esse cartão
          <div className="delete-card-confirmation__options">
            <div
              className="delete-card-confirmation__button"
              onClick={() => handleDelete()}
            >
              Sim
            </div>

            <div
              className="delete-card-confirmation__button"
              onClick={() => setDeleteCardId('')}
            >
              Não
            </div>
          </div>
        </DeleteCardConfirmation>
      </Modal>

      <Modal
        showModal={showDeleteFeedback}
        setShowModal={setShowDeleteFeedback}
      >
        <DeleteCreditCardFeedback className="delete-card-feedback">
          Excluído com sucesso!
          <div className="delete-card-feedback__icon" />
        </DeleteCreditCardFeedback>
      </Modal>

      <Modal showModal={showAddCardModal} setShowModal={setShowAddCardModal}>
        <AddCreditCardFeedback className="add-card-feedback">
          Adicionado com sucesso!
          <div className="add-card-feedback__icon" />
        </AddCreditCardFeedback>
      </Modal>
    </Container>
  );
};
