import styled from 'styled-components';

import background_login from '../../assets/img/background_login.png';

export const Container = styled.div`
  &.container {
    width: 100vw;
    height: 100vh;

    background: no-repeat center;
    background-size: cover;
    background-image: url(${background_login});

    animation: entering 0.2s ease-in;
  }
`;

export const Address = styled.form`
  &.address {
    display: flex;
    flex-direction: column;
    gap: 15px;
    flex: 1;

    width: 520px;

    padding: 0 5px;

    .address__title {
      font-size: 1.4rem;
      margin-bottom: 15px;
    }

    .address__field-row {
      display: flex;
      gap: 15px;
      flex-wrap: wrap;
    }

    .address__field--cep {
      max-width: 120px;
    }

    .address__field--address {
      width: 100%;
      max-width: 375px;
    }

    .address__field--neighboorhood,
    .address__field--city {
      width: 100%;
      max-width: 180px;
    }

    .address__field--state {
      width: 100%;
      max-width: 118px;
    }

    .address__field--id {
      width: 100%;
      max-width: 252px;
    }

    .secondary-button {
      margin-top: 15px;

      .spinner {
        width: 30px;
        height: 30px;

        border-radius: 50%;
      }
    }
    @media (max-width: 600px) {
      width: 100%;
      max-width: 520px;
    }
  }
`;

export const Terms = styled.div`
  &.terms {
    display: flex;
    flex-direction: column;

    width: 500px;

    .terms__title {
      font-size: 1.4rem;
      color: var(--purple22);
      text-align: center;
    }

    .terms__container {
      height: 350px;

      margin: 15px 10px;
      padding: 30px;

      font-size: 1rem;
      color: #727272;

      overflow: auto;

      text-align: justify;

      white-space: pre-line;

      ::-webkit-scrollbar {
        width: 6px;
      }

      ::-webkit-scrollbar-track {
        background: #e6e6ef;
        border-radius: 4px;
      }

      ::-webkit-scrollbar-thumb {
        background: var(--purple22);
        border-radius: 4px;
      }

      ::-webkit-scrollbar-thumb:hover {
        opacity: 0.9;
      }
    }
  }
`;
