import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  &.square-input {
    width: 100%;
  }

  .square-input__label {
    display: flex;
    flex-direction: column;
    gap: 10px;

    width: 100%;

    margin: 0 auto;

    font-size: 1rem;

    transition: all 0.5s;
  }

  .square-input__input {
    display: flex;
    align-items: center;

    width: 100%;

    padding: 5px;

    border-radius: 4px;
    border-color: #b6eaed;
    border-width: 3.5px;

    border: 3px solid var(--green2c);

    font-size: 1rem;
    color: var(--purple22);

    background-color: transparent;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #adb5bd;
    }
  }

  .square-input__error {
    position: absolute;
    bottom: -0.875rem;
    left: 0.125rem;
    width: 100%;
    white-space: normal;
    font-size: 0.75rem;
    color: #ff6565;
  }

  input[type='number'] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
  }
`;
