import styled from 'styled-components';

import config from '../../assets/icons/config.svg';
import white_right_chevron from '../../assets/icons/white_right_chevron.svg';

export const Container = styled.div`
  &.settings {
    display: flex;
    flex-wrap: wrap;

    width: 100%;
    max-width: 1200px;

    margin: 0 auto;
  }
`;

export const SettingsOptions = styled.div`
  &.settings__options {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;

    width: 100%;

    margin: 30px 0;
    padding: 15px;

    .options__title {
      display: flex;
      gap: 10px;

      margin-bottom: 30px;

      color: var(--purple22);
      font-size: 1.8rem;
      font-weight: bold;
    }

    .options__icon {
      width: 30px;
      height: 30px;

      background: no-repeat center;
      background-size: cover;
      background-image: url(${config});
    }

    .primary-button,
    .secondary-button {
      justify-content: space-between;

      padding: 1rem 1.5rem;

      max-width: 460px;

      font-size: 1.25rem;
      font-weight: 500;

      min-height: 3.5rem;
    }

    .options__arrow-icon {
      width: 10px;
      height: 10px;

      background: no-repeat center;
      background-size: cover;
      background-image: url(${white_right_chevron});
    }

    @media (min-width: 568px) {
      max-width: 50%;
      flex-wrap: nowrap;
    }
  }
`;

export const SettingsDisplay = styled.div`
  &.settings__display {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;

    width: 100%;

    @media (min-width: 568px) {
      max-width: 50%;
      flex-wrap: nowrap;
    }
  }
`;
