import styled from 'styled-components';

export const Container = styled.div`
  &.details-modal {
    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    inset: 0;
    z-index: 9998;

    width: calc(100vw - 15px);
    height: 100vh;

    margin: 0 auto;

    animation: showOpacity 0.2s ease forwards;

    &.details-modal--hide {
      animation: hideOpacity 0.2s ease forwards;

      pointer-events: none;
    }

    .details-modal__wrapper {
      width: 100%;
      max-width: 1200px;

      position: relative;
      z-index: 9999;

      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0 3px 6px rgb(0 0 0 /16%);
    }

    .modal__content {
      min-height: 80vh;
      height: fit-content;
      max-height: 100vh;

      overflow: auto;
    }

    .details-modal__close-button {
      position: absolute;
      top: 30px;
      right: 15px;
      transform: translateY(-50%);

      z-index: 99999;

      border: 0;
      background-color: transparent;
      transition: opacity 0.2s ease;

      cursor: pointer;

      svg {
        width: 16px;
        height: auto;
      }

      &:hover {
        opacity: 0.8;
      }
    }

    .modal__backdrop {
      width: 100vw;
      height: 100vh;

      position: fixed;
      inset: 0;
      z-index: 9998;

      display: flex;
      justify-content: center;
      align-items: center;

      background-color: rgb(73 80 87 /40%);
      backdrop-filter: blur(4px);
    }

    @keyframes showOpacity {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    @keyframes hideOpacity {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
  }
`;
