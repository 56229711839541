import React, { useContext, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import api from 'services/api';
import { handleError } from 'services/errorHandler';

import { SecondaryButton } from 'shared/components/atoms/SecondaryButton/SecondaryButton';
import { Spinner } from 'shared/components/atoms/Spinner/Spinner';
import { SquareInput } from 'shared/components/molecules/SquareInput/SquareInput';
import { cepFormat, sanitizeString } from 'shared/utils/format';
import { addressSchema } from 'shared/utils/schemas';

import {
  CitesResponse,
  IAddress,
  IStatesResponse,
  State,
} from 'types/registerTypes';

import { Address } from './_editCompanyAddressComponent';
import { IAddressResponse } from 'types/addressResponse';
import { AuthContext } from 'contexts/auth';
import { User } from 'types/authTypes';
import { getAddressInformation } from 'services/addressApi';

interface Props {
  setShowModal: Function;
  address_id?: string;
}

interface IAddressForm extends IAddress {
  city: string;
}

export const EditCompanyAddressComponent: React.FC<Props> = ({
  setShowModal,
  address_id,
}) => {
  const { setUser } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting },
    watch,
    setValue,
    reset,
  } = useForm<IAddressForm>({
    resolver: yupResolver(addressSchema),
    mode: 'onChange',
  });

  const [loading, setLoading] = useState<boolean>(false);

  const [brazilianStates, setBrazilianStates] = useState<State[]>();

  useEffect(() => {
    fetchAddress();
    // fetchStates();
  }, []);

  const fetchStates = async () => {
    setLoading(true);
    try {
      const response = await api.get<IStatesResponse>(`states`, {
        params: { limit: '99999' },
      });

      if (response) {
        setBrazilianStates(response.data.results);
      }
    } catch (error: any) {
      handleError(error.response?.data?.message || error.toString());
    } finally {
      setLoading(false);
    }
  };

  const searchSelectedState = (state: string) => {
    if (!brazilianStates) return;

    const filteredState = brazilianStates.filter(
      (item) =>
        item.name.toLocaleLowerCase() === state.toLocaleLowerCase() ||
        item.uf.toLocaleLowerCase() === state.toLocaleLowerCase(),
    );

    if (!filteredState.length) {
      setLoading(false);
      toast.error(
        'ESTADO não encontrado, por favor verifique se preencheu corretamente',
        { autoClose: 7000 },
      );

      return;
    }

    const stateId = filteredState[0].id;

    return stateId;
  };

  const fetchCityCode = async (data: IAddress) => {
    const { city_ibge_code, ...rest } = data;
    const city = city_ibge_code;
    const state = rest.uf;

    setLoading(true);

    const stateId = searchSelectedState(state);

    if (!stateId) return;

    try {
      const response = await api.get<CitesResponse>(`cities`, {
        params: { name: city, limit: '999999', stateId },
      });

      if (response) {
        if (response.data.results.length === 0) {
          setLoading(false);

          return toast.error(
            'CIDADE não encontrada, se as informações estão corretas',
            { autoClose: 7000 },
          );
        }
        const cityCode = response.data.results;

        const filteredCity = cityCode.filter(
          (item) => item.name.toLocaleLowerCase() === city.toLocaleLowerCase(),
        );

        const addressFormDataModel = {
          city_ibge_code: filteredCity[0].ibge_code,
          ...rest,
        };

        // submitChanges(addressFormDataModel);
      }
    } catch (error: any) {
      handleError(error.response?.data?.message || error.toString());
    } finally {
      setLoading(false);
    }
  };

  const submitChanges = async (address: IAddressForm) => {
    const { uf, city, ...rest } = address;
    const data = {
      address: { ...rest },
    };

    setLoading(true);

    try {
      const response = await api.put<User>(`users`, data);

      if (response) {
        setUser(response.data);
        toast.success('Endereço atualizado com sucesso!');
        setShowModal(false);
      }
    } catch (error: any) {
      handleError(error.response?.data?.message || error.toString());
    } finally {
      setLoading(false);
    }
  };

  const fetchAddress = async () => {
    setLoading(true);
    try {
      const response = await api.get<IAddressResponse>(
        `addresses/${address_id}`,
      );

      if (response) {
        setFieldValues(response.data);
      }
    } catch (error: any) {
      handleError(error.response?.data?.message || error.toString());
    } finally {
      setLoading(false);
    }
  };
  const setFieldValues = (data: IAddressResponse) => {
    setValue('cep', data.cep);
    setValue('street', data.street);
    setValue('neighborhood', data.neighborhood);
    setValue('city', data.city.name);
    setValue('uf', data.city.state.name);
    setValue('number', data.number);
    setValue('complement', data.complement);
    setValue('name', data.name);
  };

  const handleCepBlur = async () => {
    const cep = watch('cep');

    if (!cep || cep.length < 8) return;

    try {
      const { state, ...addressObj } = await getAddressInformation(cep);

      reset({
        ...addressObj,
        uf: state,
        number: undefined,
        name: undefined,
      });
    } catch (error: any) {
      handleError(
        error?.response?.data.message ||
          error?.message ||
          'Erro ao buscar o CEP',
      );
    }
  };

  return (
    <Address onSubmit={handleSubmit(submitChanges)} className="address">
      <span className="address__title">Editar endereço</span>

      <div className="address__field-row">
        <SquareInput
          className="address__field--cep"
          label="CEP"
          maxLength={10}
          inputValue={cepFormat(watch('cep') || '')}
          register={{
            ...register('cep', {
              setValueAs: (value) => sanitizeString(value),
              onBlur: handleCepBlur,
            }),
          }}
        />

        <SquareInput
          className="address__field--address"
          label="Endereço"
          register={{ ...register('street') }}
        />
      </div>

      <div className="address__field-row">
        <SquareInput
          className="address__field--neighboorhood"
          label="Bairro"
          register={{ ...register('neighborhood') }}
        />

        <SquareInput
          className="address__field--city"
          label="Cidade"
          register={{ ...register('city') }}
        />

        <SquareInput
          className="address__field--state"
          label="Estado"
          register={{ ...register('uf') }}
        />
      </div>

      <div className="address__field-row">
        <SquareInput
          type="number"
          className="address__field--cep"
          label="Número"
          register={{ ...register('number') }}
        />

        <SquareInput
          className="address__field--cep"
          label="Complemento"
          placeholder={'Opcional'}
          register={{ ...register('complement') }}
        />
      </div>

      <div className="address__field-row">
        <SquareInput
          className="address__field--id"
          label="Identificação"
          placeholder={'Ex: Casa, escritório, etc...'}
          register={{ ...register('name') }}
        />
      </div>

      <SecondaryButton type="submit" disabled={isSubmitting || !isValid}>
        {loading ? <Spinner white /> : 'EDITAR ENDEREÇO'}
      </SecondaryButton>
    </Address>
  );
};
