import React from 'react';
import { Spinner } from 'shared/components/atoms/Spinner/Spinner';

import { Container } from './_loadingScreen';

export const LoadingScreen: React.FC = () => {
  return (
    <Container className="loading-screen">
      <Spinner />
    </Container>
  );
};
