import { AuthContext } from 'contexts/auth';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Container, SplashContainer } from './_splashScreen';

export const SplashScreen: React.FC = () => {
  const history = useHistory();

  const { signed } = useContext(AuthContext);

  useEffect(() => {
    setTimeout(() => {
      signed ? history.push('/home') : history.push('login');
    }, 3000);
  }, []);

  return (
    <Container className="container">
      <SplashContainer className="splash">
        <div className="splash__icons-wrapper">
          <div className="icons" />
        </div>

        <h1 className="splash__title">Olá, Embarcador! Bem-vindo a</h1>

        <div className="splash__logo" />
      </SplashContainer>
    </Container>
  );
};
