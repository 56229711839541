import { useState } from "react";
import { handleError } from "services/errorHandler";
import { downloadFile } from "shared/utils/downloadFile";
import { IDocument } from "types/requestVehicleTypes"
import { DeliveryDocumentModal } from "../DeliveryDocumentModal/DeliveryDocumentModal";
import { Container } from "./_deliveryProof";

interface DeliveryProofProps {
  document: IDocument | null;
}

export const DeliveryProof: React.FC<DeliveryProofProps> = ({ document }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!document) return null;

  const handleDownloadImage = async () => {
    const link = document.document_url;
    const fileName = document.document;
    try {
      await downloadFile(link, fileName);
    } catch (error: any) {
      handleError(error?.response?.data?.message || error?.message || 'Algo deu errado, tente novamente mais tarde!');
    }
  }

  return (
    <Container>
      <p className="delivery-proof__label">Comprovante de Entrega</p>
      <img onClick={() => setIsModalOpen(true)} className="delivery-proof__image" src={document.document_url} alt="comprovante" />
      <button type="button" className="delivery-proof__button" onClick={handleDownloadImage}>Baixar comprovante de entrega</button>
      <DeliveryDocumentModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        url={document.document_url}
      />
    </Container>
  )
}



