import { realCurrency } from 'shared/utils/format';
import { ICreatedTrip } from 'types/requestVehicleTypes';

import { CargoDataCardContainer } from './_cargoDataCard';

interface Props {
  cargo: ICreatedTrip;
}

export const CargoDataCard = ({ cargo }: Props) => {
  const { cargo_type, cargo_value, vehicle } = cargo;

  const { vehicle_type } = vehicle;

  const { weight } = vehicle_type;

  return (
    <CargoDataCardContainer className="cargo-data-card">
      <div className="cargo-data-card__title">Dados da carga</div>

      <div className="cargo-data-card__wrapper">
        <div className="cargo-data-card__item">
          <small>Tipo da carga</small>
          {cargo_type}
        </div>

        <div className="cargo-data-card__item">
          <small>Peso da carga</small>
          {weight?.name || '-'}
        </div>

        <div className="cargo-data-card__item">
          <small>Valor da carga</small>
          {realCurrency(cargo_value)}
        </div>
      </div>
    </CargoDataCardContainer>
  );
};
