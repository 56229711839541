import styled from 'styled-components';

import alert_icon from '../../../../assets/icons/alert_icon.svg';
import config_icon from '../../../../assets/icons/config_icon.svg';
import user_icon from '../../../../assets/icons/user_icon.svg';

interface Props {
  openNavigationMenu?: boolean;
  active?: string;
}

export const Spacer = styled.div`
  height: 70px;
  @media (min-width: 1024.1px) {
    display: none;
  }
`;

export const HeaderContainer = styled.div`
  &.header {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    min-height: 66px;

    padding: 5px 5px 0;

    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.16);

    background-color: #fff;

    .header__container {
      display: none;
      align-items: center;
      justify-content: space-around;

      width: 100%;
      max-width: 1200px;

      margin: 0 auto;
    }

    @media (min-width: 1024.1px) {
      position: relative;

      .header__logo {
        display: none;
      }

      .header__container {
        display: flex;
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  &.header__button-container {
    display: flex;
    gap: 15px;

    .button {
      width: 195px;
    }

    .button--loadings {
      color: var(--purple22);
      cursor: pointer;
      background-color: transparent !important;

      &:hover {
        box-shadow: unset;
      }
    }

    .button--loadings.active {
      position: relative;

      &:after {
        content: '';

        position: absolute;

        bottom: -7px;
        left: 30px;
        right: 30px;

        height: 3px;
        background-color: var(--purple22);
      }
    }
  }
`;

export const Logo = styled.img`
  width: 130px;
  height: auto;

  padding: 0 20px;

  cursor: pointer;
  @media (min-width: 1024.1px) {
    margin: auto;
  }
`;

export const UserInfoWrapper = styled.div`
  &.header__user-info-wrapper {
    display: flex;

    width: 100%;
    max-width: 370px;

    .header__user-info {
      position: relative;

      display: flex;
      justify-content: space-around;
      align-items: center;
      gap: 15px;

      width: 195px;
      height: 60px;

      margin: auto 0 0 auto;

      border-radius: 13px 13px 0 0;
      background-color: var(--green2c);

      /* &:hover {
        .user-info__actions {
          display: flex;
        }
      } */

      .header__user-info-icon {
        width: 20px;
        height: 20px;

        background: no-repeat center;
        background-size: contain;
      }

      .info-icon--notification {
        background-image: url(${alert_icon});
        cursor: pointer;
      }

      .info-icon--config {
        background-image: url(${config_icon});
        cursor: pointer;
      }

      .info-icon--user {
        background-image: url(${user_icon});
        cursor: pointer;
      }

      .info-icon--photo {
        img {
          border-radius: 50%;
          border: 1px solid #fff;

          width: 100%;
          height: 100%;

          object-fit: cover;
        }
      }

      .user-info__actions {
        position: absolute;

        top: 90%;
        right: 10%;

        display: none;
        flex-direction: column;
        gap: 5px;

        width: 130px;
        height: 70px;

        font-size: 1rem;
        color: #868e96;
        text-align: end;

        padding: 10px 5px;

        span {
          cursor: pointer;

          padding: 5px;

          border-radius: 5px;

          &:hover {
            background-color: #e4e4e4;
          }
        }

        transition: 0.5s all;

        background-color: #fff;
        border-radius: 14px;
        box-shadow: 0 3px 6px rgb(0 0 0 /16%);
      }

      .user-info__actions--mobile {
        top: unset;
        bottom: 100%;

        left: 50%;

        transform: translateX(-50%);
      }
    }
  }
`;

export const Hamburger = styled.div<Props>`
  position: relative;
  width: 30px;
  height: ${(props) => (props.openNavigationMenu ? '30px' : '22px')};
  cursor: pointer;
  transition: all 0.3s;
  background-image: ${(props) =>
    props.openNavigationMenu
      ? 'transparent'
      : 'linear-gradient(0deg, transparent 46%,#095070 46%,#095070 53%,transparent 53%)'};

  &:before {
    content: '';
    position: absolute;
    top: ${(props) => (props.openNavigationMenu ? '13px' : '0')};
    left: 0;

    width: 30px;
    height: 2px;

    background: var(--purple22);
    transition: all 0.3s;

    transform: ${(props) =>
      props.openNavigationMenu ? 'rotate(45deg) translateX(1px)' : 'unset'};
  }
  &:after {
    content: '';
    position: absolute;
    bottom: ${(props) => (props.openNavigationMenu ? '13px' : '0')};
    left: 0;
    width: 30px;
    height: 2px;

    background: var(--purple22);
    transition: all 0.3s;

    transform: ${(props) =>
      props.openNavigationMenu ? 'rotate(-45deg) translateX(1px)' : 'unset'};
  }
  @media (min-width: 1024.1px) {
    display: none;
  }
`;

export const Navbar = styled.div<Props>`
  &.header__navbar {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    @media (max-width: 1024px) {
      flex-direction: column;
      justify-content: left;

      position: fixed;
      z-index: 998;
      top: 70px;
      left: ${(props) => (props.openNavigationMenu ? 0 : '-586px')};

      width: 85%;
      max-width: 585px;
      height: calc(100vh - 70px);

      padding: 15px;

      background: #98dfe347;
      backdrop-filter: blur(2px);
      box-shadow: inset 5px 3px 6px rgb(0 0 0 / 16%),
        2px 5px 6px rgb(0 0 0 / 16%);
      border-right: 1px solid #f3faff;
      transition: left 0.5s;
    }

    .header__button-container {
      flex-direction: column;

      max-width: unset;

      .button {
        width: 100%;
      }
    }

    .header__user-info-wrapper {
      max-width: unset;

      margin-top: auto;

      .header__user-info {
        width: 100%;

        border-radius: 13px;
      }
    }

    @media (min-width: 1024.1px) {
      display: none;
      justify-content: space-around;

      width: 100%;
      margin-right: auto;
    }
  }
`;

export const DropShadow = styled.div<Props>`
  @media (max-width: 1024px) {
    display: ${(props) => (props.openNavigationMenu ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    z-index: 997;
    width: 100vw;
    min-height: 100vh;
    background: rgba(255, 255, 255, 0.5);
    transition: all 1s;
    backdrop-filter: blur(2px);
    opacity: ${(props) => (props.openNavigationMenu ? 1 : 0)};
  }
`;
