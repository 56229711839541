import axios from 'axios';
import { IAddressModalFormData } from 'shared/components/organisms/AddressModal/AddressModal';
import { ITokenizeCard, ITokenizeResponse } from 'types/paymentTypes';
import { AddressClass } from 'types/requestVehicleTypes';

interface IBillingAddress {
  line_1: string; // (Número, Rua, Bairro)
  line_2?: string; // (Complemento - Andar, Sala, Apto)
  zip_code: string;
  city: string;
  state: string; // Formato ISO 3166-2 Por ex: BR-SP
  country: string; // Formato ISO 3166-1 Por ex: BR
}

export const tokenizeCard = async (
  card: ITokenizeCard,
  address: AddressClass | IAddressModalFormData | undefined,
) => {
  const billing_address = mapAddressToBillingAddress(address);

  const response = await axios.post<ITokenizeResponse>(
    'https://api.pagar.me/core/v5/tokens',
    {
      ...card,
      card: {
        ...card.card,
        billing_address,
      },
    },
    {
      params: {
        appId: process.env.REACT_APP_PAGARME_PUBLIC_KEY,
      },
    },
  );

  return response.data;
};

function mapAddressToBillingAddress(
  address: IAddressModalFormData | AddressClass | undefined,
): IBillingAddress | undefined {
  if (!address) {
    return undefined;
  }

  const { city, cep, complement, neighborhood, street, number } = address;

  const line_1 = `${number}, ${street}, ${neighborhood}`;
  const line_2 = complement || undefined;
  const zip_code = cep.replace('-', '');

  let billing_address = {
    country: 'BR',
    line_1,
    line_2,
    zip_code,
  } as IBillingAddress;

  if (typeof city === 'string') {
    const { uf } = address as IAddressModalFormData;

    billing_address = {
      ...billing_address,
      city,
      state: uf,
    };
  } else {
    const { state, name } = city;
    billing_address = {
      ...billing_address,
      city: name,
      state: state.uf,
    };
  }

  return billing_address;
}
