import React from 'react';
import { SecondaryButton } from 'shared/components/atoms/SecondaryButton/SecondaryButton';
import { realCurrency } from 'shared/utils/format';
import { RequestVehicleResponse } from 'types/requestVehicleTypes';

import { Container } from './_requestResultComponent';

interface Props {
  setRequestStep: Function;
  requestVehicleResult?: RequestVehicleResponse;
}

export const RequestResultComponent: React.FC<Props> = ({
  setRequestStep,
  requestVehicleResult,
}) => {
  const trip = requestVehicleResult?.trip;
  const distances = requestVehicleResult?.distances;
  const vehicle = trip?.vehicle;

  const total = () => {
    const transport = trip?.transport_cost || 0;
    const toll = trip?.toll_cost || 0;
    const insurance = trip?.insurance_cost || 0;

    const total = transport + toll + insurance;

    return total;
  };

  const formattedDisplacementDistance = distances?.displacement
    ? (distances?.displacement / 1000).toLocaleString('pt-BR', {
        maximumFractionDigits: 1,
      })
    : '0';

  if (!trip) return null;

  return (
    <Container className="request-result">
      <div className="request-result__info-wrapper">
        {/* <span className="request-result__distance">
          A {formattedDisplacementDistance} Km de distância
        </span> */}

        <span className="request-result__company">
          {requestVehicleResult?.responsible?.name || ''}
        </span>

        <div className="request-result__truck-info">
          <div className="truck-info__detail">
            <div className="truck-info__icon truck-info__icon--truck" />
            <span>Veículo</span>
            {vehicle?.vehicle_type?.name || ''}
          </div>

          <div className="truck-info__detail">
            <div className="truck-info__icon truck-info__icon--cubage" />
            <span>Volume</span>
            {trip?.vehicle?.vehicle_type?.volume?.max} m³
          </div>

          <div className="truck-info__detail">
            <div className="truck-info__icon truck-info__icon--plate" />
            <span>Placa</span>
            {vehicle?.plate}
          </div>
        </div>
      </div>

      <div className="request-result__divider" />

      <div className="request-result__summary">
        <div className="summary__title">Resumo da solicitação</div>

        <div className="summary__values">
          <div className="values__row">
            Valor frete
            <span>{realCurrency(trip.transport_cost)}</span>
          </div>

          {/* <div className="values__row">
            Valor do pedágio
            <span>{realCurrency(trip?.toll_cost)}</span>
          </div> */}

          <div className="values__row">
            Valor do seguro
            <span>{realCurrency(trip.insurance_cost)}</span>
          </div>
        </div>
        <span className="summary__total">
          Valor total <span>{realCurrency(total())}</span>
        </span>
      </div>

      <SecondaryButton onClick={() => setRequestStep('payment')}>
        Definir método de pagamento
      </SecondaryButton>
    </Container>
  );
};
