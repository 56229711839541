import styled from 'styled-components';

import white_back_arrow from '../../assets/icons/white_back_arrow.svg';

import chevron_left from '../../assets/icons/chevron_left.svg';

import donut from '../../assets/img/donut.png';

import sad from '../../assets/icons/sad.svg';

import truck from '../../assets/icons/truck.svg';
import cubage from '../../assets/icons/cubage.svg';
import plate from '../../assets/icons/plate.svg';

export const Container = styled.div`
  &.sidebar {
    // position: absolute;
    // z-index: 998;

    // float: left;

    width: 90vw;
    min-height: 87vh;

    //left: 0;
    //top: 90px;

    margin: 1rem 0;

    color: var(--purple22);
    background-color: #fff;

    border-radius: 0 25px 25px 0;
    // box-shadow: 0 3px 6px rgb(0 0 0 /16%);
    filter: drop-shadow(0 3px 3px rgba(0 0 0 /16%));

    transition: all 0.5s;
    transform: translateX(calc(-100% + 15px));

    &.show {
      transform: unset;

      .sidebar__form {
        max-height: unset;
        overflow: auto;

        ::-webkit-scrollbar {
          width: 6px;
        }

        ::-webkit-scrollbar-track {
          background: #e6e6ef;
          border-radius: 4px;
          margin-top: 1rem;
          margin-bottom: 1rem;
        }

        ::-webkit-scrollbar-thumb {
          background: var(--purple22);
          border-radius: 4px;
        }

        ::-webkit-scrollbar-thumb:hover {
          opacity: 0.9;
        }
      }
    }

    .sidebar__form {
      display: flex;
      flex-direction: column;
      gap: 20px;

      padding: 15px;

      max-height: 0px;
      overflow: hidden;
    }

    .square-input__input {
      border-width: 1.5px;
      font-size: 0.9rem;
    }

    .sidebar__title {
      display: flex;
      align-items: center;

      padding: 15px;

      color: #fff;
      background-color: var(--purple22);
      border-radius: 0 25px 0 0;

      min-height: 60px;
    }

    .title__wrapper {
      display: flex;
      flex-direction: column;
      gap: 5px;

      font-size: 1.25rem;
      font-weight: bold;
    }

    .title__go-to-form {
      display: inline-flex;
      gap: 5px;

      font-size: 0.75rem;
      font-weight: lighter;

      cursor: pointer;
    }

    .title__back-arrow {
      width: 10px;
      height: 10px;

      background: no-repeat center;
      background-size: contain;
      background-image: url(${white_back_arrow});
    }

    .sidebar__expand-button {
      content: '';

      position: absolute;
      top: 50%;
      right: 3px;

      transform: translate(50%, -50%) scaleX(-1);

      width: 30px;
      height: 30px;

      background-color: #fff;
      background-repeat: no-repeat;
      background-position: 40% 50%;
      background-size: 9px;
      background-image: url(${chevron_left});

      border-radius: 22px;
      // box-shadow: 0 3px 6px rgb(0 0 0 /16%);

      cursor: pointer;

      &.show {
        transform: translate(50%, -50%) scaleX(1);
      }
    }

    @media (min-width: 768px) {
      width: 60vw;
    }

    @media (min-width: 1200px) {
      width: 50vw;

      .sidebar__form {
        padding: 15px 24px 15px calc(100% - 590px);
      }

      .sidebar__title {
        padding-left: calc(100% - 590px);
      }
    }
  }

  @media (min-width: 1300px) {
    background-repeat: no-repeat;
    background-size: 150px;
    background-position: -75px 50%;
    background-image: url(${donut});
  }
`;

export const SearchFeedback = styled.div`
  &.search-feedback {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;

    width: 100%;
    max-width: 390px;
    min-height: 350px;

    padding: 30px;

    color: var(--purple22);
    font-size: 1.4rem;
    text-align: center;

    background-color: #cfcbf1ad;
    border-radius: 10px;

    span {
      max-width: 300px;
    }

    .spinner {
      width: 45px;
      height: 45px;

      margin: -50px auto 0;
    }

    .search-feedback__not-found {
      width: 45px;
      height: 45px;

      background-repeat: no-repeat center;
      background-size: cover;
      background-image: url(${sad});
    }
  }
`;

export const SearchResult = styled.div`
  &.search-result {
    display: flex;
    flex-direction: column;
    gap: 30px;

    padding: 30px 15px;

    .search-result__info-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .search-result__distance {
      font-size: 0.9rem;
    }

    .search-result__company {
      font-size: 1.5rem;
      font-weight: bold;
    }

    .search-result__truck-info {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      width: 100%;
      max-width: 380px;

      padding: 15px;

      font-size: 1rem;
      font-weight: bold;

      border: 1px solid var(--green2c);
      border-radius: 10px;

      .truck-info__detail {
        display: flex;
        flex-direction: column;
        gap: 5px;

        span {
          font-size: 0.7rem;
          font-weight: normal;
        }
      }

      .truck-info__icon {
        width: 25px;
        height: 25px;

        background: no-repeat center;
        background-size: contain;
        &.truck-info__icon--truck {
          background-image: url(${truck});
        }

        &.truck-info__icon--cubage {
          background-image: url(${cubage});
        }

        &.truck-info__icon--plate {
          background-image: url(${plate});
        }
      }
    }

    .search-result__divider {
      width: 70px;
      height: 1px;

      border-bottom: 1px solid var(--green2c);
    }

    .search-result__summary {
      display: flex;
      flex-direction: column;

      width: 100%;
      max-width: 380px;

      color: var(--purple22);
      font-size: 0.9rem;

      background-color: #effeff;
      border-radius: 10px;
    }

    .summary__title {
      font-size: 1.25rem;
      font-weight: bold;

      padding: 15px;
    }

    .summary__values {
      display: flex;
      flex-direction: column;
      gap: 15px;

      font-weight: lighter;

      padding: 15px;
    }

    .values__row {
      display: inline-flex;
      justify-content: space-between;

      padding: 0 3px;
    }

    .summary__total {
      display: inline-flex;
      justify-content: space-between;

      padding: 15px 18px;

      font-weight: bold;
      text-transform: uppercase;

      border-top: 1px solid var(--green2c);
    }

    .secondary-button {
      max-width: 380px;
      margin: unset;

      font-weight: normal;
      text-transform: uppercase;
    }
  }
`;
