import { NotificationsContext } from "contexts/notifications";
import { useContext } from "react";

const useNotifications = () => {
  const data = useContext(NotificationsContext)

  return data;
}

export default useNotifications;
