import styled from 'styled-components';

interface Props {
  background: string;
  color: string;
}

export const Container = styled.div<Props>`
  &.status-card {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    max-width: 365px;

    border-radius: 10px;
    box-shadow: 0 3px 6px rgb(0 0 0 /16%);

    .status-card__header {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      width: 100%;
      min-height: 75px;

      color: ${({ color }) => `${color}`};
      font-weight: bold;

      background-color: ${({ background }) => `${background}`};
      border-radius: 10px 10px 0 0;
    }

    .status-card__status {
      font-size: 1.25rem;
    }

    .status-card__description {
      font-size: 0.9rem;
    }

    .status-card__body {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;

      width: 100%;
      min-height: 160px;

      padding: 10px 15px;

      p {
        margin: auto;
      }
    }
  }
`;
