import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  font-size: 1rem;
`;

export const StyledLabel = styled.label`
  font-weight: bold;
  font-size: 1rem;
  color: var(--purple22);
`;

export const StyledInput = styled.input`
  border: 2px solid var(--greenb6);
  border-radius: 0.25rem;

  height: 2.5rem;

  padding: 0.75rem 1rem;

  color: var(--purple22);

  font-size: inherit;

  min-width: 0px;
  width: 100%;

  &:focus {
    outline: none;
    border: 2px solid var(--green2c);
  }
`;
