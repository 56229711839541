import { Redirect, Route, Switch } from 'react-router-dom';
import { Login } from 'pages/Login/Login';
import { Register } from 'pages/Register/Register';
import { SplashScreen } from 'pages/SplashScreen/SplashScreen';
import Terms from 'pages/Terms/Terms';

const PublicRoutes = () => (
  <Switch>
    <Route component={SplashScreen} path="/" exact />
    <Route component={Login} path="/login" exact />
    <Route component={Register} path="/cadastro" exact />
    <Route component={Terms} path="/politica-de-privacidade" exact />
    <Route path="*">
      <Redirect to="/" />
    </Route>
  </Switch>
);

export default PublicRoutes;
