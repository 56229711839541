import styled from 'styled-components';

import box_icon from 'assets/icons/box_icon.svg';

import { ReactComponent as Search } from 'assets/icons/magnifying_icon.svg'

export const Container = styled.div`
  &.my-loadings {
    display: flex;
    flex-direction: column;
    gap: 15px;

    width: 100%;
    max-width: 1200px;

    margin: 0 auto;
    padding: 2em 5px;

    color: var(--purple22);

    .my-loadings__title {
      position: relative;

      display: inline-flex;
      align-items: center;
      gap: 10px;

      font-size: 1.85em;
      font-weight: bold;

      &:before {
        content: '';
        position: relative;

        width: 32px;
        height: 32px;

        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-image: url(${box_icon});
      }
    }

    .my-loadings__status-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 35px;
    }
  }
`;

export const SearchBoxContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.625rem 1rem;
  min-height: 2.5rem;

  border-radius: 0.625rem;
  border: 2px solid var(--green2c);

  max-width: 17.5rem;

`;

export const SearchBoxInput = styled.input`
  min-width: 0;
  flex: 1;
  border: none;
  outline: none;

  font-size: 0.875rem;
  color: #868E96;

  &::placeholder {
    color: #868E96;
  }
`;

export const SearchIcon = styled(Search)`
  width: 1rem;
  height: 1rem;
`;
