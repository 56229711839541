import styled from 'styled-components';
import double_check from 'assets/icons/double_check.svg';

export const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;

  width: 100%;
  max-width: 300px;

  padding: 15px;

  font-size: 1.8rem;
  color: var(--purple22);
  font-weight: bold;
  text-align: center;

  .feedback__icon {
    width: 100px;
    height: 100px;

    background: no-repeat center;
    background-size: contain;
    background-image: url(${double_check});
  }
`;
