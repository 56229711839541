import styled from 'styled-components';

export const ControllerSelectContainer = styled.label<{
  containerMaxWidth?: string;
  containerWidth?: string;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  flex: 1;

  max-width: ${({ containerMaxWidth }) => containerMaxWidth};
  width: ${({ containerWidth }) => containerWidth};
`;

export const ControllerSelectLabelText = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: var(--purple22);
  white-space: nowrap;
`;

export const ErrorMessage = styled.span`
  position: absolute;
  bottom: -0.875rem;
  left: 0.125rem;
  width: 100%;
  white-space: normal;
  font-size: 0.75rem;
  color: #ff6565;
`;
