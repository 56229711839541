import { createContext, SetStateAction, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useSocket from 'shared/hooks/useSocket.hook';
import {
  IDisplayNotification,
  INotification,
  TripStatus,
} from 'types/mainTypes';

interface INotificationsContext {
  notifications: IDisplayNotification[];
  hasUnreadNotifications: boolean;
  setHasUnreadNotifications: React.Dispatch<SetStateAction<boolean>>;
  markNotificationsAsRead: (notificationIds: string[]) => void;
  pendingPaymentTripIds: string[];
  cancelledTripIds: string[];
  onPaymentSuccess: (tripId: string) => void;
}

interface INotificationsEvent extends Partial<INotification> {
  results?: INotification[];
}

const getButtonText = (statusId?: TripStatus): string => {
  if (!statusId) return 'Acompanhar coleta';

  const buttonTexts: {
    [key in TripStatus]?: string;
  } = {
    [TripStatus.waitingPayment]: 'Pagar entrega',
    [TripStatus.Canceled]: 'Procurar outro veículo',
    [TripStatus.Pending]: 'Procurar outro veículo',
  };

  return buttonTexts[statusId] || 'Acompanhar coleta';
};

const urlToSendFromStatus = (
  statusId: TripStatus,
  tripId: string,
): string | undefined => {
  switch (statusId) {
    case TripStatus.Pending:
      return '/home/search';
    case TripStatus.waitingPayment:
      return `/home/payment/${tripId}`;
    case TripStatus.Canceled:
      return `/home/search${tripId ? `/${tripId}` : ''}`;
    default:
      return `/minhas-cargas/${tripId}`;
  }
};

export const NotificationsContext = createContext({} as INotificationsContext);

export const NotificationsProvider: React.FC = ({ children }) => {
  const [notifications, setNotifications] = useState<IDisplayNotification[]>(
    [],
  );
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);
  const history = useHistory();

  const { socket } = useSocket();

  const notificationToDisplay = (
    notification: INotification,
  ): IDisplayNotification => {
    const { trip_id, trip } = notification;

    const displayMessage = notification?.message || '';

    const buttonText = getButtonText(trip?.status_id);

    const url = urlToSendFromStatus(trip?.status_id, trip_id);

    return {
      ...notification,
      displayMessage,
      buttonText,
      onClickCallback: () => {
        markNotificationsAsRead([notification.id]);
        if (url) {
          history.push(url);
        }
        return;
      },
    };
  };

  useEffect(() => {
    socket?.on('notifications', (e: INotificationsEvent) => {
      // Past notifications coming from database
      if (e.results) {
        // notification.message === null when shipper cancels trip
        const filteredNotifications = e.results.filter(
          (notification: INotification) => notification.message != null,
        );

        const unreadNotifications = filteredNotifications.filter(
          (notification: INotification) => !notification.read,
        );

        const uniqueTripIdNotifications = unreadNotifications.filter(
          (notification, index, self) => {
            return (
              self.findIndex((n) => n.trip_id === notification.trip_id) ===
              index
            );
          },
        );

        setNotifications(uniqueTripIdNotifications.map(notificationToDisplay));
        setHasUnreadNotifications(unreadNotifications.length > 0);
      }

      // New notification
      if (e) {
        if (e.message != null) {
          setNotifications((prevState) => {
            const filteredNotifications = prevState.filter(
              (notification) => notification.trip_id !== e.trip_id,
            );

            return [
              notificationToDisplay(e as INotification),
              ...filteredNotifications,
            ];
          });
          setHasUnreadNotifications(true);
        }
      }
    });

    return () => {
      socket?.off('notifications');
    };
  }, [socket]);

  const markNotificationsAsRead = (notificationIds: string[]) => {
    /* socket?.emit('read', notificationIds);
    setNotifications((prevState) =>
      prevState.filter(
        (notification) => !notificationIds.includes(notification.id),
      ),
    ); */
  };

  const handlePaymentSuccess = (tripId: string) => {
    setNotifications((prevState) => {
      return prevState.map((notification) => {
        if (notification.trip_id === tripId) {
          return {
            ...notification,
            trip: {
              ...notification.trip,
              status_id: TripStatus.Displacement,
            },
          };
        } else {
          return notification;
        }
      });
    });
  };

  const pendingPaymentNotifications = notifications.filter(
    (notification) =>
      notification?.trip?.status_id === TripStatus.waitingPayment,
  );

  const pendingPaymentTripIds = pendingPaymentNotifications.map(
    (notification) => notification.trip_id,
  );

  const cancelledTripNotifications = notifications.filter(
    (notification) =>
      notification?.current_status_id === TripStatus.Canceled &&
      !notification?.read,
  );

  const cancelledTripIds = cancelledTripNotifications.map(
    (notification) => notification.trip_id,
  );

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        hasUnreadNotifications,
        setHasUnreadNotifications,
        markNotificationsAsRead,
        pendingPaymentTripIds,
        cancelledTripIds,
        onPaymentSuccess: handlePaymentSuccess,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
