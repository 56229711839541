import { NavLink } from 'react-router-dom';

import { ReactComponent as ConfigIcon } from 'assets/icons/config_icon.svg';

import useAuth from 'shared/hooks/useAuth.hook';
import { Container, IconWrapper, Option, OptionsWrapper } from './_userSettingsButton';

export const UserSettingsButton: React.FC = () => {
  const { signOut } = useAuth();

  const handleSignout = () => {
    signOut();
  }

  return (
    <Container>
      <IconWrapper>
        <ConfigIcon />
      </IconWrapper>
      <OptionsWrapper>
        <Option as={NavLink} to="/configuracoes">Configurações</Option>
        <Option onClick={handleSignout}>Sair</Option>
      </OptionsWrapper>
    </Container>
  );
};


