import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';

import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { SecondaryButton } from 'shared/components/atoms/SecondaryButton/SecondaryButton';
import { SquareInput } from 'shared/components/molecules/SquareInput/SquareInput';
import { Modal } from 'shared/components/organisms/Modal/Modal';
import { CompanyRegisterComponent } from './components/CompanyRegisterComponent/CompanyRegisterComponent';
import { ManagerRegisterComponent } from './components/ManagerRegisterComponent/ManagerRegisterComponent';

import { Address, Container, Terms } from './_register';

import {
  CitesResponse,
  IAddress,
  IManager,
  IRegister,
  IStatesResponse,
  State,
} from '../../../src/types/registerTypes';

import { addressSchema } from 'shared/utils/schemas';

import api from 'services/api';
import { handleError } from 'services/errorHandler';

import { Spinner } from 'shared/components/atoms/Spinner/Spinner';
import { cepFormat } from 'shared/utils/format';
import { getAddressInformation } from 'services/addressApi';

export const Register: React.FC = () => {
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting },
    control,
    watch,
    reset,
  } = useForm<IAddress>({
    resolver: yupResolver(addressSchema),
    mode: 'onChange',
  });

  const [currentSection, setCurrentSection] = useState<string>('manager');
  const [terms, setTerms] = useState<string>('');

  const [managerFormData, setManagerFormData] = useState<IManager>();
  const [addressFormData, setAddressFormData] = useState<IAddress>();

  const [brazilianStates, setBrazilianStates] = useState<State[]>();

  const [loading, setLoading] = useState<boolean>(false);

  const [showAddressModal, setShowAddressModal] = useState<boolean>(false);
  const [showTermsModal, setShowTermsModal] = useState<boolean>(false);

  const onSubmit: SubmitHandler<IAddress> = (data) => {
    setAddressFormData(data);
    setShowAddressModal(false);
  };

  useEffect(() => {
    // fetchStates();
    fetchSystemInfos();
  }, []);

  const fetchCityCode = async (data: IAddress) => {
    const { city_ibge_code, ...rest } = data;
    const city = city_ibge_code;
    const state = rest.uf;

    setLoading(true);

    const stateId = searchSelectedState(state);

    if (!stateId) return;

    try {
      const response = await api.get<CitesResponse>(`cities`, {
        params: { name: city, limit: '999999', stateId },
      });

      if (response) {
        if (response.data.results.length === 0) {
          setLoading(false);

          return toast.error(
            'CIDADE não encontrada, se as informações estão corretas',
            { autoClose: 7000 },
          );
        }
        const cityCode = response.data.results;

        const filteredCity = cityCode.filter(
          (item) => item.name.toLocaleLowerCase() === city.toLocaleLowerCase(),
        );

        const addressFormDataModel = {
          city_ibge_code: filteredCity[0].ibge_code,
          ...rest,
        };

        setAddressFormData(addressFormDataModel);

        setShowAddressModal(false);
      }
    } catch (error: any) {
      handleError(error.response?.data?.message || error.toString());
    } finally {
      setLoading(false);
    }
  };

  const fetchStates = async () => {
    setLoading(true);
    try {
      const response = await api.get<IStatesResponse>(`states`, {
        params: { limit: '99999' },
      });

      if (response) {
        setBrazilianStates(response.data.results);
      }
    } catch (error: any) {
      handleError(error.response?.data?.message || error.toString());
    } finally {
      setLoading(false);
    }
  };

  const fetchSystemInfos = async () => {
    try {
      const response = await api.get<{
        system_infos: {
          terms_and_conditions_shipper: string;
        };
      }>('/system-infos');

      setTerms(response.data?.system_infos?.terms_and_conditions_shipper || '');
    } catch (error: any) {
      handleError(error?.response?.data?.message || '');
    }
  };

  const handleCepBlur = async () => {
    const cep = watch('cep');

    if (!cep || cep.length < 8) return;

    try {
      const { state, ...addressObj } = await getAddressInformation(cep);

      reset({
        ...addressObj,
        uf: state,
        number: undefined,
        name: undefined,
      });
    } catch (error: any) {
      handleError(
        error?.response?.data.message ||
          error?.message ||
          'Erro ao buscar o CEP',
      );
    }
  };

  const searchSelectedState = (state: string) => {
    if (!brazilianStates) return;

    const filteredState = brazilianStates.filter(
      (item) =>
        item.name.toLocaleLowerCase() === state.toLocaleLowerCase() ||
        item.uf.toLocaleLowerCase() === state.toLocaleLowerCase(),
    );

    if (!filteredState.length) {
      setLoading(false);
      toast.error(
        'ESTADO não encontrado, por favor verifique se preencheu corretamente',
        { autoClose: 7000 },
      );

      return;
    }

    const stateId = filteredState[0].id;

    return stateId;
  };

  const submitForm = async (registerFormData: IRegister) => {
    setLoading(true);

    if (!managerFormData || !addressFormData) return;

    const person =
      registerFormData.cpf_cnpj.length > 11 ? 'legal' : 'individual';

    const personObject =
      person === 'legal'
        ? {
            legal_person: {
              responsible_name: managerFormData.responsible_name,
              trade_name: registerFormData.trade_name,
              corporate_name: registerFormData.corporate_name || undefined,
              cnpj: registerFormData.cpf_cnpj,
            },
          }
        : {
            individual_person: {
              name: registerFormData.trade_name,
              cpf: registerFormData.cpf_cnpj,
            },
          };

    const formData = {
      ...personObject,
      email: managerFormData.email,
      password: registerFormData.password,
      password_confirmation: registerFormData.password_confirmation,
      cellphone: managerFormData.cellphone,
      accepted_terms: Boolean(registerFormData.accepted_terms),
      address: {
        name: addressFormData.name || undefined,
        street: addressFormData.street,
        neighborhood: addressFormData.neighborhood,
        cep: addressFormData.cep,
        complement: addressFormData.complement,
        number: addressFormData.number,
        city_ibge_code: Number(addressFormData.city_ibge_code),
      },
      shipper: {
        has_insurance: false,
        has_ddr: false,
      },
    };

    try {
      const response = await api.post(`users`, formData);

      if (response) {
        toast.success('Cadastro realizado com sucesso');

        setTimeout(() => {
          history.push('login');
        }, 3000);
      }
    } catch (error: any) {
      handleError(error.response?.data?.message || error.toString());
    } finally {
      setLoading(false);
    }
  };

  const renderRegisterSection = () => {
    if (currentSection === 'manager') {
      return (
        <ManagerRegisterComponent
          setCurrentSection={setCurrentSection}
          setManagerFormData={setManagerFormData}
        />
      );
    }

    if (currentSection === 'company') {
      return (
        <CompanyRegisterComponent
          setShowAddressModal={setShowAddressModal}
          setShowTermsModal={setShowTermsModal}
          loading={loading}
          submitForm={submitForm}
          addressFormData={addressFormData}
        />
      );
    }
  };

  return (
    <Container className="container">
      {renderRegisterSection()}

      <Modal showModal={showAddressModal} setShowModal={setShowAddressModal}>
        <Address onSubmit={handleSubmit(onSubmit)} className="address">
          <span className="address__title">Adicionar endereço</span>

          <div className="address__field-row">
            <Controller
              name="cep"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <SquareInput
                  className="address__field--cep"
                  label="CEP"
                  inputValue={cepFormat(field.value || '')}
                  setInputValue={field.onChange}
                  maxLength={9}
                  onBlur={handleCepBlur}
                />
              )}
            />

            <SquareInput
              className="address__field--address"
              label="Endereço"
              register={{ ...register('street') }}
            />
          </div>

          <div className="address__field-row">
            <SquareInput
              className="address__field--neighboorhood"
              label="Bairro"
              register={{ ...register('neighborhood') }}
            />

            <SquareInput
              className="address__field--city"
              label="Cidade"
              register={{ ...register('city') }}
            />

            <SquareInput
              className="address__field--state"
              label="Estado"
              register={{ ...register('uf') }}
            />
          </div>

          <div className="address__field-row">
            <SquareInput
              type="number"
              className="address__field--cep"
              label="Número"
              register={{ ...register('number') }}
            />

            <SquareInput
              className="address__field--cep"
              label="Complemento"
              placeholder="Opcional"
              register={{ ...register('complement') }}
            />
          </div>

          <div className="address__field-row">
            <SquareInput
              className="address__field--id"
              label="Identificação"
              placeholder="Ex: Casa, escritório, etc..."
              register={{ ...register('name') }}
            />
          </div>

          <SecondaryButton type="submit" disabled={isSubmitting || !isValid}>
            {loading ? <Spinner white /> : 'ADICIONAR ENDEREÇO'}
          </SecondaryButton>
        </Address>
      </Modal>

      <Modal showModal={showTermsModal} setShowModal={setShowTermsModal}>
        <Terms className="terms">
          <span className="terms__title">Termos obrigatórios</span>

          <div className="terms__container">{terms}</div>
        </Terms>
      </Modal>
    </Container>
  );
};
