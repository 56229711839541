import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  inset: 0;
  z-index: 9998;

  width: calc(100vw - 15px);
  height: 100vh;

  margin: 0 auto;

  animation: showOpacity 0.2s ease forwards;

  &.modal-forget-hide {
    animation: hideOpacity 0.2s ease forwards;

    pointer-events: none;
  }

  .modal-forget__wrapper {
    min-width: 250px;
    min-height: 250px;
    width: fit-content;
    height: fit-content;

    position: relative;
    z-index: 9999;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 3px 6px rgb(0 0 0 /16%);
  }

  .modal__content {
    margin: 15px 10px;

    max-height: 70vh;
    max-width: 90vw;
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #e6e6ef;
      border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--purple22);
      border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb:hover {
      opacity: 0.9;
    }
  }

  .modal-forget__close-button {
    position: absolute;
    top: 15px;
    right: 15px;

    border: 0;
    background-color: transparent;
    transition: opacity 0.2s ease;

    cursor: pointer;

    svg {
      width: 16px;
      height: auto;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .modal__backdrop {
    width: 100vw;
    height: 100vh;

    position: fixed;
    inset: 0;
    z-index: 9998;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgb(73 80 87 /40%);
    backdrop-filter: blur(4px);
  }

  @keyframes showOpacity {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes hideOpacity {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;
