/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { requestVehicleSchema } from 'shared/utils/schemas';

import api from 'services/api';
import { cepFormat } from 'shared/utils/format';

import { SecondaryButton } from 'shared/components/atoms/SecondaryButton/SecondaryButton';

import { SquareInput } from 'shared/components/molecules/SquareInput/SquareInput';

import { Container, FormGroupRow } from './_requestFormComponent';

import { getAddressInformation } from 'services/addressApi';
import { ControllerSelect } from 'shared/components/molecules/ControllerSelect/ControllerSelect';
import { PrefixInput } from 'shared/components/molecules/PrefixInput/PrefixInput';
import { Bodywork, PaginatedResult, VehicleType } from 'types/mainTypes';
import {
  RequestTripFormData,
  RequestVehicleResponse,
  VehicleVolume,
  VehicleWeight,
} from 'types/requestVehicleTypes';
import { formatDataForRequest } from '../../utils';

interface Props {
  requestStep: string;
  setRequestStep: Function;
  setRequestVehicleSearchResult: Function;
  requestVehicleData: Partial<RequestTripFormData> | undefined;
  requestVehicleResult?: RequestVehicleResponse;
  setRequestVehicleData: Function;
  closeSidebar: () => void;
}

const kmOptions = Array.from({ length: 10 }, (_, i) => ({
  value: (i + 1) * 10,
  label: `${(i + 1) * 10}`,
}));

export const RequestFormComponent: React.FC<Props> = ({
  setRequestStep,
  setRequestVehicleSearchResult,
  requestVehicleData,
  setRequestVehicleData,
  requestVehicleResult,
  requestStep,
}) => {
  const [vehicleTypes, setVehicleTypes] = useState<VehicleType[]>([]);
  const [vehicleBodyworks, setVehicleBodyworks] = useState<Bodywork[]>([]);
  const [vehicleWeights, setVehicleWeights] = useState<VehicleWeight[]>([]);
  const [vehicleVolumes, setVehicleVolumes] = useState<VehicleVolume[]>([]);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, dirtyFields },
    watch,
    setValue,
    control,
    getValues,
    reset,
  } = useForm<RequestTripFormData>({
    resolver: yupResolver(requestVehicleSchema),
    defaultValues: {
      search_radius: kmOptions[0],
    },
    mode: 'onChange',
  });

  const vehicleTypeOption = watch('vehicle_type_id');
  const vehicleBodyworkOption = watch('cargo_bodywork_id');
  const vehicleWeightOption = watch('cargo_weight');
  const vehicleVolumeOption = watch('volume');

  const vehicleType = vehicleTypes?.find(
    ({ id }) => id === vehicleTypeOption?.value,
  );

  const vehicleBodywork = vehicleBodyworks?.find(
    ({ id }) => id === vehicleBodyworkOption?.value,
  );

  const vehicleWeight = vehicleWeights?.find(
    ({ id }) => id === vehicleWeightOption?.value,
  );

  const vehicleVolume = vehicleVolumes?.find(
    ({ id }) => id === vehicleVolumeOption?.value,
  );

  const getVehicleTypes = async () => {
    try {
      const response = await api.get<PaginatedResult<VehicleType>>(
        '/vehicle-types',
        {
          params: { distinct: true },
        },
      );

      setVehicleTypes(response.data.results);
    } catch (error: any) {
      handleError(error?.response?.data?.message || error.toString());
    }
  };

  const getVehicleBodyworks = async () => {
    try {
      const response = await api.get<Bodywork[]>('bodywork-types', {
        params: {
          vehicle_type_name: vehicleType?.name,
        },
      });

      if (response.data.length === 1) {
        setValue('cargo_bodywork_id', {
          value: response.data[0].id,
          label: response.data[0].name,
        });
      }

      setVehicleBodyworks(response.data);
    } catch (error: any) {
      handleError(error?.response?.data?.message || error.toString());
    }
  };

  const getVehicleWeights = async () => {
    try {
      const response = await api.get<PaginatedResult<VehicleWeight>>(
        'vehicle-weights',
        {
          params: {
            vehicle_type_name: vehicleType?.name,
            vehicle_bodywork_id: vehicleBodywork?.id,
          },
        },
      );

      const firstVehicleWeight = response.data.results[0];

      const firstVehicleWeightOption = firstVehicleWeight
        ? {
            value: firstVehicleWeight.id,
            label: firstVehicleWeight.name,
          }
        : null;

      setValue('cargo_weight', firstVehicleWeightOption);

      setVehicleWeights(response.data.results);
    } catch (error: any) {
      handleError(error?.response?.data?.message || error.toString());
    }
  };

  const getVehicleVolumes = async () => {
    try {
      const response = await api.get<PaginatedResult<VehicleVolume>>(
        'vehicle-volumes',
        {
          params: {
            vehicle_type_name: vehicleType?.name,
            vehicle_bodywork_id: vehicleBodywork?.id,
          },
        },
      );

      const firstVehicleVolume = response.data.results[0];

      const firstVehicleVolumeOption = firstVehicleVolume
        ? {
            value: firstVehicleVolume.id,
            label: firstVehicleVolume.name,
          }
        : null;

      setValue('volume', firstVehicleVolumeOption);

      setVehicleVolumes(response.data.results);
    } catch (error: any) {
      handleError(error?.response?.data?.message || error.toString());
    }
  };

  useEffect(() => {
    if (requestVehicleData && !requestVehicleResult) {
      const currentData = getValues();
      reset(
        { ...currentData, ...requestVehicleData },
        {
          keepDirty: false,
        },
      );
    }
  }, [requestVehicleData]);

  useEffect(() => {
    getVehicleTypes();
  }, []);

  useEffect(() => {
    if (vehicleType) {
      getVehicleBodyworks();
    }
  }, [vehicleType]);

  useEffect(() => {
    if (vehicleType && vehicleBodywork) {
      getVehicleWeights();
      getVehicleVolumes();
    }
  }, [vehicleType, vehicleBodywork]);

  const createTrip = async (data: RequestTripFormData) => {
    const filteredVehicleTypes = await api.get('/vehicle-types', {
      params: {
        name: vehicleType?.name,
        bodywork_type_id: vehicleBodywork?.id,
        weight_id: vehicleWeight?.id,
        volume_id: vehicleVolume?.id,
      },
    });

    if (filteredVehicleTypes?.data?.results?.length > 0) {
      const response = await api.post(
        '/trips',
        formatDataForRequest({
          ...data,
          vehicle_type_id: filteredVehicleTypes.data.results[0].id,
        }),
      );

      setRequestVehicleSearchResult(response.data);
    } else {
      handleError('Tipo de veículo inválido');
    }
  };

  const updateTripRadius = async (tripId: string, radius: number) => {
    const response = await api.put(`/trips/next/${tripId}`, {
      search_radius: radius,
    });

    setRequestVehicleSearchResult(response.data);
  };

  const onSubmit = async (data: RequestTripFormData) => {
    if (!data?.cargo_bodywork_id) {
      toast.error('Selecione o tipo de carroceria');

      return;
    }

    setRequestVehicleData(data);

    try {
      // If only the search radius changed, update the trip to search for the next transporter
      // instead of creating a new trip which will probably be assigned to the previous transporter
      setRequestStep('searching');
      if (
        data?.id &&
        Object.keys(dirtyFields).length <= 1 &&
        dirtyFields?.search_radius // Check if only the search radius changed
      ) {
        await updateTripRadius(data.id, data.search_radius.value);
      } else {
        await createTrip(data);
      }
    } catch (error: any) {
      /* setRequestStep('default');
      handleError(error?.response?.data?.message || error.toString()); */
    }
  };

  const handleCepBlur = async (
    addressType: 'initial_address' | 'delivery_address',
  ) => {
    let cep: string;
    if (addressType === 'initial_address') {
      cep = getValues('initial_address.cep');
    } else {
      cep = getValues('delivery_address.cep');
    }

    if (!cep || cep.length < 8) return;

    try {
      const addressObj = await getAddressInformation(cep);

      if (addressType === 'initial_address') {
        const currentNumber = getValues('initial_address.number');

        setValue(
          'initial_address',
          { ...addressObj, number: currentNumber },
          {
            shouldValidate: true,
          },
        );
      } else {
        const currentNumber = getValues('delivery_address.number');

        setValue(
          'delivery_address',
          { ...addressObj, number: currentNumber },
          {
            shouldValidate: true,
          },
        );
      }
    } catch (error: any) {
      handleError(
        error?.response?.data.message ||
          error?.message ||
          'Erro ao buscar o CEP',
      );
    }
  };

  const handleError = (error: string) => {
    const noTruckFoundString =
      'Não foi possível encontrar um veículo com as especificações.';
    if (error === noTruckFoundString) {
      return setRequestStep('not-found');
    }

    setRequestStep('default');
    return toast.error(error);
  };

  const renderAddressError = (
    addressType: 'initial_address' | 'delivery_address',
  ) => {
    const addressErrors = errors[addressType];

    if (!addressErrors) return null;

    // Check if addressErrors has any defined keys, if it does and its key has a message, display the first message found
    const firstError = Object.entries(addressErrors).find(([key, value]) => {
      return value?.message;
    })?.[1]?.message;

    if (!firstError) return null;

    return <span className="form__group-row__error-message">{firstError}</span>;
  };

  const vehicleTypesOptions = vehicleTypes.map((vehicleType) => ({
    label: vehicleType?.name,
    value: vehicleType?.id,
  }));

  const vehicleBodyworkOptions = vehicleBodyworks.map((vehicleBodywork) => ({
    label: vehicleBodywork?.name,
    value: vehicleBodywork?.id,
  }));

  const vehicleWeightOptions = vehicleWeights.map((vehicleWeight) => ({
    label: vehicleWeight?.name,
    value: vehicleWeight?.id,
  }));

  const vehicleVolumeOptions = vehicleVolumes.map((vehicleVolume) => ({
    label: vehicleVolume?.name,
    value: vehicleVolume?.id,
  }));

  return (
    <>
      <Container className="request-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="request-form__group">
          <span className="request-form__label">Endereço de coleta</span>
          <FormGroupRow className="form__group-row">
            <Controller
              name="initial_address.cep"
              control={control}
              render={({ field }) => (
                <SquareInput
                  className="request-form__input--medium"
                  placeholder="CEP"
                  inputValue={cepFormat(field.value || '')}
                  setInputValue={field.onChange}
                  maxLength={9}
                  onBlur={() => handleCepBlur('initial_address')}
                />
              )}
            />

            <SquareInput
              placeholder="Endereço"
              register={{ ...register('initial_address.street') }}
              className="request-form__input--fill"
            />

            <SquareInput
              className="request-form__input--small"
              placeholder="Número"
              register={{ ...register('initial_address.number') }}
            />
          </FormGroupRow>
          <FormGroupRow className="form__group-row">
            <SquareInput
              className="request-form__input--medium"
              placeholder="Bairro"
              register={{ ...register('initial_address.neighborhood') }}
            />

            <SquareInput
              className="request-form__input--long"
              placeholder="Cidade"
              register={{ ...register('initial_address.city') }}
            />

            <SquareInput
              className="request-form__input--tiny"
              placeholder="UF"
              register={{ ...register('initial_address.state') }}
            />
            {renderAddressError('initial_address')}
          </FormGroupRow>
        </div>
        <div className="request-form__group">
          <SquareInput
            placeholder="Contato na coleta"
            error={errors?.colecting_contact?.message}
            register={{ ...register('colecting_contact') }}
          />
        </div>

        <div className="request-form__group">
          <span className="request-form__label">Endereço de entrega</span>
          <FormGroupRow className="form__group-row">
            <Controller
              name="delivery_address.cep"
              control={control}
              render={({ field }) => (
                <SquareInput
                  className="request-form__input--medium"
                  placeholder="CEP"
                  inputValue={cepFormat(field.value || '')}
                  setInputValue={field.onChange}
                  maxLength={9}
                  onBlur={() => handleCepBlur('delivery_address')}
                />
              )}
            />

            <SquareInput
              placeholder="Endereço"
              register={{ ...register('delivery_address.street') }}
              className="request-form__input--fill"
            />

            <SquareInput
              className="request-form__input--small"
              placeholder="Número"
              register={{ ...register('delivery_address.number') }}
            />
          </FormGroupRow>
          <FormGroupRow className="form__group-row">
            <SquareInput
              className="request-form__input--medium"
              placeholder="Bairro"
              register={{ ...register('delivery_address.neighborhood') }}
            />

            <SquareInput
              className="request-form__input--long"
              placeholder="Cidade"
              register={{ ...register('delivery_address.city') }}
            />

            <SquareInput
              className="request-form__input--tiny"
              placeholder="UF"
              register={{ ...register('delivery_address.state') }}
            />
            {renderAddressError('delivery_address')}
          </FormGroupRow>
        </div>

        <div className="request-form__group">
          <SquareInput
            placeholder="Contato na entrega"
            error={errors?.delivery_contact?.message}
            register={{ ...register('delivery_contact') }}
          />
        </div>

        <div className="request-form__group">
          <FormGroupRow className="form__group-row">
            <ControllerSelect
              control={control}
              name="vehicle_type_id"
              options={vehicleTypesOptions}
              placeholder="Selecione"
              label="Tipo do veículo"
              error={errors?.vehicle_type_id?.value?.message}
              containerWidth="10rem"
              onChange={() => {
                setValue('cargo_bodywork_id', null);
                setValue('cargo_weight', null);
                setValue('volume', null);
              }}
            />

            <ControllerSelect
              control={control}
              options={vehicleBodyworkOptions}
              name="cargo_bodywork_id"
              placeholder="Selecione"
              label="Tipo de carroceria"
              containerWidth="12rem"
              onChange={() => {
                setValue('cargo_weight', null);
                setValue('volume', null);
              }}
            />

            <ControllerSelect
              control={control}
              name="cargo_weight"
              placeholder="Selecione"
              label="Peso da carga"
              containerWidth="12rem"
              options={vehicleWeightOptions}
            />
          </FormGroupRow>
        </div>

        <div className="request-form__group">
          <FormGroupRow className="second-group form__group-row">
            <ControllerSelect
              control={control}
              name="volume"
              placeholder="Selecione"
              label="Cubagem da carga"
              containerWidth="10rem"
              options={vehicleVolumeOptions}
            />

            <SquareInput
              label="Tipo de carga"
              register={{ ...register('cargo_type') }}
              placeholder="Ex.: Papel, cimento, etc."
              error={errors?.cargo_type?.message}
            />

            <PrefixInput
              label="Valor da carga"
              labelClassName="request-form__label"
              inputClassName="request-form__prefix-input"
              containerClassName="request-form__prefix-input-container"
              prefix="R$"
              type="number"
              inputMode="decimal"
              min={0}
              error={errors?.cargo_value?.message}
              {...register('cargo_value')}
            />
          </FormGroupRow>
        </div>

        <div className="request-form__group">
          <FormGroupRow className="form__group-row">
            <label className="request-form__textarea-label">
              Alguma observação
              <textarea
                placeholder="Ex.: Complemento; Coleta imediata; Carga liberada para coleta a partir das 15:00,
(nº da ordem de transporte)."
                {...register('observation')}
              />
            </label>
          </FormGroupRow>
        </div>

        <div className="request-form__group">
          <FormGroupRow className="form__group-row">
            {/* <label className="request-form__number-label">
              Raio de busca (km)
              <Controller
                name="search_radius"
                defaultValue={5}
                control={control}
                render={({ field: { onChange, value } }) => {
                  const handleChange = (n: number) => {
                    if (value + n < 0) {
                      onChange(0);
                    } else {
                      onChange(value + n);
                    }
                  };

                  return (
                    <>
                      <input
                        className="request-form__input--number"
                        type="number"
                        value={value}
                        onChange={(e) =>
                          onChange(Number(sanitizeString(e.target.value)))
                        }
                        inputMode={'numeric'}
                      />
                      <span
                        className="request-form__number-label__arrow arrow--up"
                        onClick={() => handleChange(10)}
                      />
                      <span
                        className="request-form__number-label__arrow arrow--down"
                        onClick={() => handleChange(-10)}
                      />
                    </>
                  );
                }}
              />
            </label> */}
            <label className="request-form__number-label">
              Raio de busca (km)
              <ControllerSelect
                defaultValue={kmOptions[0]}
                name="search_radius"
                control={control}
                placeholder="Selecione"
                options={kmOptions}
                menuPlacement="top"
              />
            </label>

            <SecondaryButton type="submit" disabled={isSubmitting}>
              Buscar
            </SecondaryButton>
          </FormGroupRow>
        </div>
      </Container>
    </>
  );
};
