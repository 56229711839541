import styled, { css } from 'styled-components';

export const Container = styled.div<{
  showModal: boolean;
}>`
  position: fixed;
  inset: 0;

  min-height: 100vh;
  width: 100%;

  overflow: auto;

  background-color: rgb(73 80 87 /40%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 99999;

  transition: all 0.3s;

  opacity: 1;
  visibility: visible;

  animation: showOpacity 0.2s ease forwards;

  ${({ showModal }) =>
    !showModal &&
    css`
      animation: hideOpacity 0.2s ease forwards;
      pointer-events: none;
    `}

  .modal-container__content {
    height: fit-content;
    width: fit-content;
    max-height: 100%;
  }

  @keyframes showOpacity {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes hideOpacity {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;
