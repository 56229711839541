import {
  DirectionsRenderer,
  DirectionsService,
  GoogleMap,
  Marker,
  OverlayView,
  useJsApiLoader,
} from '@react-google-maps/api';
import { useCallback, useMemo, useState } from 'react';

import rounded_pin_icon from 'assets/icons/rounded_pin_icon.svg';
import truck_in_route from 'assets/icons/truck_in_route.svg';
import truck_icon from 'assets/icons/truck_icon.svg';

import { mapStyles } from 'shared/utils/mapConfigs';
import { DistanceIndicatorContainer, MapWrapper, ZoomController } from './_map';
import { MapVehicles } from 'pages/Home/Home';

interface MapProps {
  origin?: number[];
  destination?: number[];
  nearVehicles?: MapVehicles;
  onTravelTimeChange?: (travelTime: number) => void;
  activeVehicleId?: string;
  center: {
    lat: number;
    lng: number;
  };
  changeCenter: (lat: number, lng: number) => void;
  fixedZoomContainer: boolean;
}

const mapOptions = {
  styles: mapStyles,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  disableDefaultUI: true,
};

const polyline = {
  strokeColor: `#2CCCD3`,
  strokeWeight: 5,
  strokeOpacity: 0.5,
};

const containerStyle = {
  width: '100%',
  height: '100%',
};

export const Map: React.FC<MapProps> = ({
  origin,
  destination,
  nearVehicles,
  center,
  changeCenter,
  activeVehicleId = '',
  onTravelTimeChange = () => {},
  fixedZoomContainer = false,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
  });
  const [map, setMap] = useState<undefined | google.maps.Map>();
  const [directionsResponse, setDirectionsResponse] =
    useState<google.maps.DirectionsResult | null>();

  const [originLng, originLat] = origin || [0, 0];
  const [destinationLng, destinationLat] = destination || [0, 0];

  const renderCargoMarker = () => {
    if (!directionsResponse) return;

    return (
      <Marker
        position={{ lat: destinationLat, lng: destinationLng }}
        icon={rounded_pin_icon}
      />
    );
  };

  const renderSelectedTruckMarker = () => {
    if (!directionsResponse) return;

    return (
      <Marker
        position={{ lat: originLat, lng: originLng }}
        icon={truck_in_route}
      />
    );
  };

  const renderDistanceMarker = () => {
    if (!directionsResponse) return null;

    const distance =
      directionsResponse.routes[0]?.legs[0]?.distance?.value || 0;

    const formattedDistance = `${(distance / 1000).toLocaleString('pt-BR', {
      maximumFractionDigits: 1,
    })} km`;

    return (
      <OverlayView
        mapPaneName="markerLayer"
        position={{ lat: originLat, lng: originLng }}
      >
        <DistanceIndicatorContainer>
          <span>{formattedDistance}</span>
        </DistanceIndicatorContainer>
      </OverlayView>
    );
  };

  const renderNearVehiclesMarkers = () => {
    if (!nearVehicles) return null;

    return Object.entries(nearVehicles).map(([key, { lat, lng }]) =>
      activeVehicleId === key ? null : (
        <Marker key={key} position={{ lat, lng }} icon={truck_icon} />
      ),
    );
  };

  const onLoad = useCallback(function callback(_map: google.maps.Map) {
    setMap(_map);
  }, []);

  const onUnmount = useCallback(function callback() {
    setMap(undefined);
  }, []);

  // Prevent multiple calls to the directions service
  const directionsOptions = useMemo(() => {
    return {
      destination: {
        lat: destinationLat,
        lng: destinationLng,
      },
      origin: {
        lat: originLat,
        lng: originLng,
      },
      travelMode: 'DRIVING',
    };
  }, [destinationLat, destinationLng, originLat, originLng]);

  const directionsCallback = useCallback(
    (
      response: google.maps.DirectionsResult | null,
      status: google.maps.DirectionsStatus | null,
    ) => {
      if (response == null || status == null) return;
      if (status === 'OK') {
        setDirectionsResponse(response);

        // Get Travel Time from directions response and call onTravelTimeChange with the time in seconds
        const travelTime = response?.routes[0]?.legs[0]?.duration?.value || 0;
        onTravelTimeChange(travelTime);
      }
    },
    [onTravelTimeChange],
  );

  const handleCenterChange = () => {
    if (!map) return;

    const coords = map.getCenter()?.toJSON();

    if (!coords) return;

    const { lat, lng } = coords;

    changeCenter(lat, lng);
  };

  const handleDirectionsChange = useCallback(() => {
    if (!map) return;

    map?.fitBounds(
      directionsResponse?.routes[0].bounds ||
        new window.google.maps.LatLngBounds({
          lat: -23.5489,
          lng: -46.6388,
        }),
    );
  }, [directionsResponse, map]);

  return (
    <MapWrapper>
      {isLoaded && (
        <GoogleMap
          options={mapOptions}
          mapContainerStyle={containerStyle}
          onLoad={onLoad}
          center={center}
          zoom={10}
          onUnmount={onUnmount}
          onDragEnd={handleCenterChange}
        >
          {origin && destination ? (
            <DirectionsService
              key={2}
              callback={directionsCallback}
              options={directionsOptions as google.maps.DirectionsRequest}
            />
          ) : null}
          {directionsResponse != null && (
            <DirectionsRenderer
              key={1}
              directions={directionsResponse}
              options={{
                directions: directionsResponse,
                polylineOptions: polyline,
                suppressMarkers: true,
                suppressPolylines: false,
                preserveViewport: true,
              }}
              onDirectionsChanged={handleDirectionsChange}
            />
          )}
          {renderCargoMarker()}
          {renderSelectedTruckMarker()}
          {renderDistanceMarker()}
          {renderNearVehiclesMarkers()}
          {map && (
            <ZoomController
              className={`zoom-controller ${fixedZoomContainer ? 'fixed' : ''}`}
            >
              <div
                className="zoom-controller__button zoom-controller__button--plus"
                onClick={() => map.setZoom((map.getZoom() ?? 10) + 1)}
              />
              <div
                className="zoom-controller__button"
                onClick={() => map.setZoom((map.getZoom() ?? 10) - 1)}
              />
            </ZoomController>
          )}
        </GoogleMap>
      )}
    </MapWrapper>
  );
};
