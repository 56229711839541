import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import api from 'services/api';
import { handleError } from 'services/errorHandler';
import { tokenizeCard } from 'services/pagarme';
import { PrimaryButton } from 'shared/components/atoms/PrimaryButton/PrimaryButton';
import { SecondaryButton } from 'shared/components/atoms/SecondaryButton/SecondaryButton';
import { Spinner } from 'shared/components/atoms/Spinner/Spinner';
import { AddCreditCardComponent } from 'shared/components/organisms/AddCreditCardComponent/AddCreditCardComponent';
import useAuth from 'shared/hooks/useAuth.hook';
import useNotifications from 'shared/hooks/useNotifications.hook';
import { realCurrency } from 'shared/utils/format';
import { createCardSchema } from 'shared/utils/schemas';
import {
  ICreateCardForm,
  ICreditCard,
  IGetCardsResponse,
} from 'types/paymentTypes';
import { RequestVehicleResponse } from 'types/requestVehicleTypes';

import {
  BanksSlipSection,
  Container,
  CreditCard,
  CreditCardSection,
  PillSelector,
  PixSection,
  RequestSummary,
} from './_paymentSectionComponent';

interface IPixPaymentResponse {
  msg: string;
  pix: {
    code: string;
    url: string;
  };
}

interface IBoletoPaymentResponse {
  msg: string;
  boleto: {
    line: string;
    pdf: string;
  };
}

interface PaymentSectionComponentProps {
  requestVehicleResult?: RequestVehicleResponse;
  closeSidebar: () => void;
  setRequestVehicleSearchResult: Function;
  setRequestVehicleData: Function;
}

export const PaymentSectionComponent: React.FC<
  PaymentSectionComponentProps
> = ({
  closeSidebar,
  requestVehicleResult,
  setRequestVehicleData,
  setRequestVehicleSearchResult,
}) => {
  const { user } = useAuth();
  const { onPaymentSuccess } = useNotifications();
  const [paymentOption, setPaymentOption] = useState('credit_card');
  const [pixCode, setPixCode] = useState('');
  const [boleto, setBoleto] = useState<IBoletoPaymentResponse>();
  const [cards, setCards] = useState<ICreditCard[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cardId, setCardId] = useState('');
  const [showAddCreditCardForm, setShowAddCreditCardForm] =
    useState<boolean>(false);

  const {
    control,
    getValues,
    register,
    setValue,
    watch,
    formState: { isValid },
  } = useForm<ICreateCardForm>({
    mode: 'onChange',
    resolver: yupResolver(createCardSchema),
  });

  useEffect(() => {
    fetchCards();
  }, []);

  const fetchCards = async () => {
    try {
      const response = await api.get<IGetCardsResponse>('/cards');

      setCards(response.data.results);
      if (response.data.results.length === 0) {
        setShowAddCreditCardForm(true);
      }
    } catch (error: any) {
      handleError(
        error?.response?.data?.message ||
          'Algo deu errado, tente novamente mais tarde',
      );
    }
  };

  const isActive = (option: string) => {
    return paymentOption === option ? 'active' : '';
  };

  const renderAddCardSection = () => {
    if (showAddCreditCardForm) {
      return (
        <AddCreditCardComponent
          register={register}
          setValue={setValue}
          watch={watch}
          control={control}
        />
      );
    }

    return;
  };

  const handleSelectCard = (cardId: string) => {
    setCardId(cardId);
    setShowAddCreditCardForm(false);
  };

  const handleShowAddCreditCardForm = () => {
    setCardId('');
    setShowAddCreditCardForm((prevState) => !prevState);
  };

  const handleCreditCardPayment = async () => {
    if (!user) {
      console.log('User not found');
      return;
    }

    const trip = requestVehicleResult?.trip;

    if (cardId) {
      await api.post(`/trips/pay/${trip?.id}`, {
        method: paymentOption,
        payment: {
          card: {
            card_id: cardId,
          },
        },
      });
    } else if (!showAddCreditCardForm) {
      toast.error('Adicione ou selecione um cartão');
      return;
    } else {
      if (!isValid) {
        toast.error('Preencha todos os campos corretamente');
        return;
      }

      const data = getValues();

      const {
        cvv,
        expiration_date,
        holder_name,
        number,
        newBillingAddress,
        address,
      } = data;

      const [stringMonth, stringYear] = expiration_date.split('/');
      const exp_month = parseInt(stringMonth);
      const exp_year = parseInt(stringYear);

      const cardData = {
        exp_month,
        exp_year,
        cvv,
        holder_name,
        number: number.replace(/\D/g, ''),
      };

      const existingAddress = user.legal_person
        ? user.legal_person.address
        : user?.individual_person?.address;

      const token = await tokenizeCard(
        { type: 'card', card: cardData },
        newBillingAddress ? address : existingAddress,
      );

      await api.post(`/trips/pay/${trip?.id}`, {
        method: paymentOption,
        payment: {
          card: {
            new_card: {
              card_hash: token.id,
              create_card: false,
            },
          },
        },
      });
    }

    toast.success('Pagamento realizado com sucesso!');
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      if (paymentOption === 'boleto') {
        if (!boleto) {
          handleError('Você deve gerar um boleto para finalizar a solicitação');
          return;
        }
      } else if (paymentOption === 'pix') {
        if (!pixCode) {
          handleError(
            'Você deve gerar um código de pix para finalizar a solicitação',
          );
          return;
        }
      } else if (paymentOption === 'credit_card') {
        await handleCreditCardPayment();
      }

      onPaymentSuccess(requestVehicleResult?.trip?.id || '');
      setRequestVehicleData(undefined);
      setRequestVehicleSearchResult(undefined);
      closeSidebar();
    } catch (error: any) {
      handleError(
        error?.response?.data?.message ||
          error?.message ||
          'Algo deu errado, tente novamente mais tarde',
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDownloadBoleto = () => {
    if (!boleto || !boleto?.boleto?.pdf) {
      handleError('Algo deu errado, tente novamente mais tarde');
      return;
    }

    const url = boleto.boleto.pdf;

    window.open(url, '_blank');
  };

  const handleGenerateBoleto = async () => {
    setIsSubmitting(true);
    try {
      const response = await api.post<IBoletoPaymentResponse>(
        `/trips/pay/${requestVehicleResult?.trip?.id}`,
        {
          method: 'boleto',
        },
      );

      setBoleto(response.data);
    } catch (error: any) {
      handleError(
        error?.response?.data?.message ||
          'Algo deu errado, tente novamente mais tarde',
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleGeneratePix = async () => {
    setIsSubmitting(true);
    try {
      const response = await api.post<IPixPaymentResponse>(
        `/trips/pay/${requestVehicleResult?.trip.id}`,
        {
          method: 'pix',
        },
      );

      const { pix } = response.data;

      setPixCode(pix.code);
    } catch (error: any) {
      handleError(
        error?.response?.data?.message ||
          'Algo deu errado, tente novamente mais tarde',
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCopyText = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success('Código copiado com sucesso', {
        autoClose: 2000,
      });
    } catch (error: any) {
      console.log(error);
    }
  };

  const disableSubmitButton = () => {
    if (isSubmitting) {
      return true;
    }

    if (paymentOption === 'credit_card') {
      // Se não tiver um cartão selecionado e o form de adicionar um novo cartão estiver fechado
      if (!cardId && !showAddCreditCardForm) {
        return true;
      }

      // Se o form de adicionar um novo cartão estiver aberto e não tiver um cartão selecionado, verificar se o formulário está válido
      if (showAddCreditCardForm && !cardId) {
        return !isValid;
      }

      return false;
    }
  };

  const totalCost =
    (requestVehicleResult?.trip?.toll_cost || 0) +
    (requestVehicleResult?.trip?.insurance_cost || 0) +
    (requestVehicleResult?.trip?.transport_cost || 0);

  return (
    <>
      <Container className="payment-section">
        <div className="payment-section__payment-method">
          <PillSelector className="pill-selector">
            <div
              className={`
                pill-selector__option
                pill-selector__option--left
                ${isActive('credit_card')}`}
              onClick={() => setPaymentOption('credit_card')}
            >
              Cartão de Crédito
            </div>

            <div
              className={`
                pill-selector__option
                pill-selector__option--right
                ${isActive('pix')}`}
              onClick={() => setPaymentOption('pix')}
            >
              PIX
            </div>
          </PillSelector>
          <span>OU</span>

          <div
            className={`payment-section__bank-slip ${isActive('boleto')}`}
            onClick={() => setPaymentOption('boleto')}
          >
            Boleto Bancário
          </div>
        </div>
        {paymentOption === 'credit_card' && (
          <CreditCardSection className="payment-section__credit-card-section">
            Pagar com:
            {cards.map((card) => (
              <CreditCard
                key={card.id}
                className={`credit-card ${cardId === card.id ? 'active' : ''}`}
                type="button"
                onClick={() => handleSelectCard(card.id)}
              >
                <div
                  className={`credit-card__flag ${card.card_brand.toLowerCase()}`}
                />
                <span className="credit-card__card-number">
                  <span className="credit-card__dots">
                    &bull;&bull;&bull;&bull;
                  </span>
                  <span>{card.last_digits}</span>
                </span>
              </CreditCard>
            ))}
            <div
              className="credit-card-section__add-credit-card-anchor"
              onClick={handleShowAddCreditCardForm}
            >
              Adicionar cartão
            </div>
            <div className="payment-section__add-credit-card">
              {renderAddCardSection()}
            </div>
          </CreditCardSection>
        )}
        {paymentOption === 'boleto' && (
          <BanksSlipSection className="bank-slip-section">
            {boleto && (
              <>
                <span className="bank-slip-section__title">
                  Para finalizar você deve pagar o seu boleto de{' '}
                  {realCurrency(totalCost)}
                </span>
                <span className="bank-slip-section__info">
                  Você pode pagar pelo internet Banking com o seu código:
                </span>

                <b>{boleto.boleto.line}</b>

                <SecondaryButton
                  type="button"
                  onClick={() => handleCopyText(boleto.boleto.line)}
                >
                  Copiar código
                </SecondaryButton>

                <span className="bank-slip-section__info">
                  Ou imprimir para pagá-lo em uma agência bancária:
                </span>
              </>
            )}

            <PrimaryButton
              type="button"
              onClick={boleto ? handleDownloadBoleto : handleGenerateBoleto}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Spinner white />
              ) : boleto ? (
                'Baixar boleto'
              ) : (
                'Gerar boleto'
              )}
            </PrimaryButton>
          </BanksSlipSection>
        )}
        {paymentOption === 'pix' && (
          <PixSection className="pix-section">
            <span className="pix-section__info">
              Copie o código Pix abaixo e cole em seu aplicativo de pagamento
              para finalizar a compra.
            </span>

            <div className="pix-section__row">
              <div className="pix-section__code">{pixCode || ''}</div>

              <PrimaryButton
                type="button"
                onClick={
                  pixCode ? () => handleCopyText(pixCode) : handleGeneratePix
                }
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <Spinner white />
                ) : pixCode ? (
                  'Copiar código'
                ) : (
                  'Gerar código'
                )}
              </PrimaryButton>
            </div>

            <span className="pix-section__info">Como pagar usando Pix?</span>

            <div className="pix-section__steps">
              <span>
                1. Cole o código Pix acima em seu aplicativo bancário ou
                carteira digital de preferência.
              </span>

              <span>2. Confirme o pagamento.</span>

              <span>
                3. Pronto! Seu pagamento será aprovado em alguns instantes.
              </span>
            </div>
          </PixSection>
        )}
        <RequestSummary className="request-summary">
          <div className="request-summary__title">Resumo da solicitação</div>

          <div className="request-summary__values">
            <div className="request-summary__values__row">
              <span>Valor frete</span>
              <span>
                {realCurrency(requestVehicleResult?.trip?.transport_cost || 0)}
              </span>
            </div>

            {/* <div className="request-summary__values__row">
              <span>Valor do pedágio</span>
              <span>
                {realCurrency(requestVehicleResult?.trip?.toll_cost || 0)}
              </span>
            </div> */}

            <div className="request-summary__values__row">
              <span>Valor do seguro</span>
              <span>
                {realCurrency(requestVehicleResult?.trip?.insurance_cost || 0)}
              </span>
            </div>
          </div>
          <span className="request-summary__total">
            <span>Valor total</span>
            <span>{realCurrency(totalCost)}</span>
          </span>
        </RequestSummary>
        <SecondaryButton
          type="button"
          onClick={handleSubmit}
          disabled={disableSubmitButton()}
        >
          {isSubmitting ? <Spinner white /> : 'Finalizar solicitação'}
        </SecondaryButton>
      </Container>
    </>
  );
};
