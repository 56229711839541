import styled from 'styled-components';

import camera_icon from '../../../../assets/icons/camera_icon.svg';

export const Container = styled.div`
  &.photo-input {
    .photo-input__label {
      display: block;
      width: 50px;
      height: 50px;

      background-color: #effeff;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 25px;
      background-image: url(${camera_icon});

      border-radius: 26px;
      box-shadow: 0 3px 6px rgb(0 0 0 /16%);

      cursor: pointer;

      transition: all 0.3s;
      &:hover {
        transform: scale(1.05);
      }
    }

    input {
      display: none;
    }

    @media (max-width: 368px) {
      .photo-input__label {
        justify-content: center !important;
      }
    }
  }
`;
