import { useState } from 'react';
import {
  CargoStepContainer,
  CargoStepIconsContainer,
  CargoStepText,
  CheckFilledIcon,
  DetailsContainer,
  InformationContainer,
  InformationLabel,
  InformationText,
  InformationWrapper,
  MapPinIcon,
  QuestionIcon,
  WhiteDownChevronIcon,
} from './_cargoStepDetail';

interface CargoStepDetailProps {
  name: string;
  number: string;
  status: 'pending' | 'in_progress' | 'done';
  address?: string;
  doneAt?: Date;
}

const formatDate = (date: Date) => {
  const stringDate = date.toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });

  return stringDate.split(' ').join(' às ');
};

export const CargoStepDetail: React.FC<CargoStepDetailProps> = ({
  name,
  number,
  status,
  address,
  doneAt,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const getStatusText = () => {
    switch (status) {
      case 'done':
        return `Concluído${doneAt ? `: ${formatDate(doneAt)}` : ''}`;
      default:
        return 'Em progresso';
    }
  };

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <CargoStepContainer
        type="button"
        onClick={handleToggle}
        status={status}
        disabled={status === 'pending'}
      >
        <CargoStepText>
          Etapa {number}: {name}
        </CargoStepText>
        <CargoStepIconsContainer>
          {status === 'done' && <CheckFilledIcon />}
          <WhiteDownChevronIcon />
        </CargoStepIconsContainer>
      </CargoStepContainer>
      <DetailsContainer isExpanded={isExpanded}>
        {address && (
          <InformationContainer>
            <MapPinIcon />
            <InformationWrapper>
              <InformationLabel>Endereço de coleta:</InformationLabel>
              <InformationText>{address}</InformationText>
            </InformationWrapper>
          </InformationContainer>
        )}
        <InformationContainer>
          <QuestionIcon />
          <InformationWrapper>
            <InformationLabel>Status</InformationLabel>
            <InformationText>{getStatusText()}</InformationText>
          </InformationWrapper>
        </InformationContainer>
      </DetailsContainer>
    </>
  );
};
