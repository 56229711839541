import React from 'react';
import { realCurrency } from 'shared/utils/format';
import { TripStatus } from 'types/mainTypes';
import { ICreatedTrip } from 'types/requestVehicleTypes';

import { Container } from './_statusInfoComponent';

interface Props {
  loadingInfo: ICreatedTrip;
  border: string;
  showDetails?: boolean;
  handleInfoClick: Function;
}

const statusLabels: Partial<Record<TripStatus, string>> = {
  [TripStatus.Collecting]: 'Coleta',
  [TripStatus.Displacement]: 'Deslocamento',
  [TripStatus.OnGoing]: 'Viagem',
  [TripStatus.Finished]: 'Entregue',
};

export const StatusInfoComponent: React.FC<Props> = ({
  loadingInfo,
  border,
  showDetails,
  handleInfoClick,
}) => {
  const {
    order,
    initial_address,
    delivery_address,
    cargo_value,
    vehicle,
    created_at,
    status_id,
  } = loadingInfo;

  const showPointAnchor = () => {
    if (status_id === TripStatus.Finished) {
      return (
        <div className="status-info__point-button--delivery">Entregue</div>
      );
    }

    return (
      statusLabels[status_id] && (
        <div className="status-info__point-button">
          {statusLabels[status_id]}
        </div>
      )
    );
  };

  return (
    <Container
      className="status-info"
      {...{ border }}
      onClick={() => handleInfoClick(loadingInfo)}
    >
      <div className="status-info__columns-wrapper">
        <div className="status-info__column">
          <span className="status-info__code">{order}</span>

          <div className="status-info__way">
            <div className="status-info__address">
              <span>De</span>

              <b>
                {initial_address.address.city.name} -{' '}
                {initial_address.address.city.state.uf}
              </b>
            </div>

            <div className="status-info__address">
              <span>Para</span>
              <b>
                {delivery_address.address.city.name} -{' '}
                {delivery_address.address.city.state.uf}{' '}
              </b>
            </div>
          </div>
        </div>

        <div className="status-info__column">
          <div className="status-info__hour ">
            {new Date(created_at).toLocaleTimeString('pt-BR', {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </div>

          <div className="status-info__date">
            {new Date(created_at).toLocaleDateString('pt-BR', {
              year: '2-digit',
              month: '2-digit',
              day: '2-digit',
            })}
          </div>

          {showPointAnchor()}
        </div>
      </div>

      <div className="status-info__additional-info">
        <div className="status-info__price">{realCurrency(cargo_value)}</div>

        <div className="status-info__truck-type">
          {vehicle?.vehicle_type?.name || '-'}
        </div>
      </div>
    </Container>
  );
};
