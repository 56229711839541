import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;

  width: fit-content;
  height: fit-content;

  max-width: 100vw;
  max-height: 95vh;

  padding: 2rem 3.5rem;

  background-color: #fff;

  border-radius: 1.5rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

`

export const CloseButton = styled.button`
  background: transparent;
  border: none;

  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem 1.5rem;
  font-size: 2rem;

  cursor: pointer;
`

export const DocumentImage = styled.img`
  border-radius: 1.25rem;
  max-width: 100%;
  max-height: 50vh;
`
