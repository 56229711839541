import React from 'react';

import { Container } from './_photoInput';

interface FileInput {
  acceptFile?: string;
  setInputFile: Function;
  setImageURL?: Function;
}

export const PhotoInput: React.FC<FileInput> = ({
  acceptFile,
  setInputFile,
  setImageURL,
}) => {
  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (files && files.length < 0) {
      return;
    }

    if (files && files.length > 0) {
      const selectedFile = files[0];
      setInputFile(selectedFile);

      setImageURL && setImageURL(URL.createObjectURL(files[0]));
    }
  };

  return (
    <Container className="photo-input">
      <label className="photo-input__label">
        <input
          type="file"
          accept={acceptFile}
          onChange={(e) => handleSelectFile(e)}
        />
      </label>
    </Container>
  );
};
