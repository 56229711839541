import ReactDOM from 'react-dom';

import { useRef } from 'react';

import { ReactComponent as X } from '../../../../assets/icons/close.svg';

import { Container } from './_modal';

interface IModal {
  showModal: boolean;
  setShowModal: Function;
}

export const Modal: React.FC<IModal> = ({
  showModal,
  setShowModal,
  children,
}) => {
  const modalRoot = document.getElementById('root-modal');
  const modalRef = useRef(null);

  if (!modalRoot) {
    return null;
  }

  const renderModal = () => {
    if (showModal) {
      return (
        <Container className={`${!showModal ? 'modal-forget-hide' : ''}`}>
          <div ref={modalRef} className="modal-forget__wrapper">
            <button
              type="button"
              onClick={() => setShowModal(!showModal)}
              className="modal-forget__close-button"
            >
              <X />
            </button>

            <div className="modal__content">{children}</div>
          </div>

          <div
            className="modal__backdrop"
            onClick={() => setShowModal(!showModal)}
          />
        </Container>
      );
    }
  };

  return ReactDOM.createPortal(<>{renderModal()} </>, modalRoot);
};
