import React, { useCallback, useEffect, useRef, useState } from 'react';

import { v4 as uuid } from 'uuid';

import useAuth from 'shared/hooks/useAuth.hook';
import { ChatContainer, ForwardArrowIcon, HasUnreadBadge } from './_chat';
import firebase from 'services/firebase';

interface Props {
  tripId: string;
  responsibleId: string;
}

interface IChatMessage {
  id: string;
  message: string;
  created_at: firebase.firestore.Timestamp;
  author: string;
  author_id: string;
  is_read: boolean;
}

const firestore = firebase.firestore;

export const Chat: React.FC<Props> = ({ responsibleId, tripId }) => {
  const { user } = useAuth();

  const [isExpanded, setIsExpanded] = useState(false);
  const [newMessage, setNewMessage] = useState('');
  const [messages, setMessages] = useState<IChatMessage[]>([]);

  const listRef = useRef<HTMLUListElement>(null);

  const userId = user?.id || '';
  const userName = user?.legal_person?.responsible_name || '';
  const chatId = `${responsibleId}+${userId}`;

  const sendMessage = useCallback(
    (message: string) => {
      if (!message.trim()) return;

      firestore()
        .collection('chats')
        .doc(`${responsibleId}+${userId}`)
        .update({
          messages: firestore.FieldValue.arrayUnion({
            message,
            id: uuid(),
            author: userName,
            author_id: userId,
            created_at: firestore.Timestamp.now(),
            is_read: false,
          }),
        });
    },
    [userId, userName, responsibleId],
  );

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    sendMessage(newMessage);

    setNewMessage('');
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleSubmit(e);
    }
  };

  useEffect(() => {
    const unsubscribe = firestore()
      .collection('chats')
      .doc(chatId)
      .onSnapshot((snapshot) => {
        const data = snapshot.data() as { messages: IChatMessage[] };

        setMessages(data?.messages || []);
      });

    return () => unsubscribe();
  }, [tripId, userId, responsibleId]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [messages]);

  const hasUnreadMessages = messages.some(
    (message) => message.is_read === false && message.author_id !== userId,
  );

  return (
    <ChatContainer isExpanded={isExpanded}>
      {hasUnreadMessages && <HasUnreadBadge />}
      <button
        type="button"
        className="chat__header-button"
        onClick={() => {
          if (!isExpanded) {
            firestore()
              .collection('chats')
              .doc(chatId)
              .update({
                messages: messages.map((message) => {
                  if (message?.author_id === user?.id) return message;
                  return {
                    ...message,
                    is_read: true,
                  };
                }),
              });
          }
          setIsExpanded((prev) => !prev);
        }}
      >
        CHAT
      </button>
      <div className="chat__content">
        <ul className="chat__messages-list" ref={listRef}>
          {messages?.map(({ author_id, message, id }) => (
            <li
              key={id}
              className={`chat__message ${
                author_id === userId ? 'chat__message--from-me' : ''
              }`}
            >
              <p className="chat__message-text">{message}</p>
            </li>
          ))}
        </ul>
        <form className="chat__textarea-container" onSubmit={handleSubmit}>
          <label className="chat__textarea-wrapper">
            <textarea
              className="chat__textarea"
              placeholder="Mandar mensagem"
              value={newMessage}
              onKeyPress={handleKeyPress}
              onChange={(e) => {
                setNewMessage(e.target.value);
              }}
            />
            <p className="chat__textarea-wrapper__text">
              {newMessage.trim() ? newMessage : 'Mandar mensagem'}
            </p>
          </label>
          <button className="chat__submit-button">
            <ForwardArrowIcon />
          </button>
        </form>
      </div>
    </ChatContainer>
  );
};
