import styled from 'styled-components';

import splash_group from '../../assets/img/splash_group.png';
import logo_vagoo from '../../assets/icons/logo_vagoo.svg';

import background_login from '../../assets/img/background_login.png';

export const Container = styled.div`
  &.container {
    width: 100vw;
    height: 100vh;

    background: no-repeat center;
    background-size: cover;
    background-image: url(${background_login});
  }
`;

export const SplashContainer = styled.div`
  &.splash {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 30px;

    width: 100%;
    max-width: 700px;

    padding: 15px;

    background-color: #fff;

    border-radius: 30px;

    animation: entering 0.5s;

    .splash__icons-wrapper {
      width: 100%;

      max-width: 380px;
      min-height: 135px;

      .icons {
        width: 100%;
        height: 135px;

        background: no-repeat center;
        background-size: contain;
        background-image: url(${splash_group});

        animation: slide_up 0.5s;
      }
    }

    .splash__title {
      font-size: 2rem;
      font-weight: bold;
      color: var(--purple22);
      text-align: center;

      animation: entering 0.8s;
    }

    .splash__logo {
      width: 100%;
      max-width: 250px;
      height: 65px;

      background: no-repeat center;
      background-size: contain;
      background-image: url(${logo_vagoo});

      animation: slide_up 1.2s;
    }

    @media (min-width: 580px) {
      min-height: 480px;
    }
  }
`;
