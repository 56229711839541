import styled from "styled-components";

import { ReactComponent as CheckFilled } from 'assets/icons/check_filled.svg';
import { ReactComponent as MapPin } from 'assets/icons/map_pin.svg';
import { ReactComponent as Question } from 'assets/icons/question.svg';
import { ReactComponent as WhiteRightChevron } from 'assets/icons/white_right_chevron.svg';

const statusBackgroundColors = {
  done: 'var(--purple22)',
  in_progress: 'var(--green2c)',
  pending: '#A4BABB',
}

export const CargoStepContainer = styled.button<{
  status: 'pending' | 'in_progress' | 'done';
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  min-height: 3rem;
  border-radius: 0.625rem;

  padding: 1rem 1.375rem;

  cursor: pointer;

  background-color: ${({ status }) => statusBackgroundColors[status]};
  border: none;
  outline: none;
`;

export const CargoStepText = styled.p`
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
`;

export const CargoStepIconsContainer = styled.div`
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
`

export const CheckFilledIcon = styled(CheckFilled)``;

export const MapPinIcon = styled(MapPin)``;

export const QuestionIcon = styled(Question)``;

export const WhiteDownChevronIcon = styled(WhiteRightChevron)`
  transform: rotate(90deg);
`;

export const DetailsContainer = styled.div<{
  isExpanded: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: ${({ isExpanded }) => isExpanded ? '1.5rem 1.375rem' : '0 1.375rem'};

  max-height: ${({ isExpanded }) => isExpanded ? '200px' : '0px'};
  overflow: hidden;
  transition: all 0.2s ease-in-out;
`;

export const InformationContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const InformationWrapper = styled.div`
`;

export const InformationLabel = styled.p`
  font-size: 0.75rem;
  line-height: 1.125rem;
`;

export const InformationText = styled.p`
  font-size: 1rem;

  font-weight: bold;
  color: var(--purple22);
  text-transform: uppercase;
`;
