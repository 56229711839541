import { VehicleUpdatesSocketEvent } from 'pages/Home/Home';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Map } from 'shared/components/organisms/Map/Map';
import useNotifications from 'shared/hooks/useNotifications.hook';
import useSocket from 'shared/hooks/useSocket.hook';
import { formatSeconds } from 'shared/utils/format';
import { requestingStatuses } from 'shared/utils/tripStatus';
import { TripStatus } from 'types/mainTypes';
import { ICreatedTrip } from 'types/requestVehicleTypes';
import { CargoDetailsInfo } from '../CargoDetailsInfo/CargoDetailsInfo';
import { Chat } from '../Chat/Chat';
import { FollowRouteDetails } from '../FollowRouteDetails/FollowRouteDetails';

import { ClockContainer, ClockText, Container } from './_cargoDetails';

interface Props {
  cargo: ICreatedTrip;
  setCargo: Dispatch<SetStateAction<ICreatedTrip | undefined>>;
  cancelTrip: (id: string) => Promise<void>;
}

const preCollectionStatuses = [
  TripStatus.Pending,
  TripStatus.Collecting,
  TripStatus.Displacement,
  TripStatus.waitingPayment,
  TripStatus.Canceled,
];

export const CargoDetails: React.FC<Props> = ({
  cancelTrip,
  cargo,
  setCargo,
}) => {
  const [followRoute, setFollowRoute] = useState<boolean>(false);
  const { notifications } = useNotifications();
  const [showDeliveryInfo, setShowDeliveryInfo] = useState<boolean>(false);
  const [formattedTravelTime, setFormattedTravelTime] = useState('00:00');
  const [vehicleGeo, setVehicleGeo] = useState<number[] | undefined>(() => {
    if (cargo?.vehicle?.geolocation) {
      return cargo?.vehicle?.geolocation.coordinates;
    }
    return undefined;
  });
  const [center, setCenter] = useState({
    lat: -23.55052,
    lng: -46.633309,
  });
  const { socket } = useSocket();

  const { status_id, order, responsible, id } = cargo;

  useEffect(() => {
    // Refresh Data on Notification about trip
    const newNotification = notifications[0];

    if (
      id &&
      newNotification &&
      newNotification?.trip_id === id &&
      newNotification.trip?.status_id !== status_id
    ) {
      setCargo((prevState) => {
        if (!prevState) {
          return undefined;
        }

        return {
          ...prevState,
          status_id: newNotification.trip.status_id,
          status_marks: newNotification.trip.status_marks,
        };
      });
    }
  }, [notifications]);

  useEffect(() => {
    socket?.on('vehicle-updates', (e: VehicleUpdatesSocketEvent) => {
      const { vehicle_id } = e;

      if (vehicle_id === cargo.vehicle_id) {
        if (vehicleGeo) {
          const [lng = 0, lat = 0] = vehicleGeo;

          if (
            // Aprox. 11 metros
            Math.abs(e.position.longitude - lng) < 0.0001 &&
            Math.abs(e.position.latitude - lat) < 0.0001
          ) {
            return;
          }
        }
        setVehicleGeo([e.position.longitude, e.position.latitude]);
      }
    });

    return () => {
      socket?.off('vehicle-updates');
    };
  }, [socket, cargo]);

  const changeCenter = (lat: number, lng: number) => {
    setCenter({ lat, lng });
  };

  const color = () => {
    if (showDeliveryInfo) {
      return 'var(--purple22)';
    }

    const setColor: { [key in TripStatus]: string } = {
      [TripStatus.Pending]: 'var(--purple22)',
      [TripStatus.Displacement]: '#fff',
      [TripStatus.Collecting]: '#fff',
      [TripStatus.OnGoing]: '#fff',
      [TripStatus.waitingPayment]: 'var(--purple22)',
      [TripStatus.Finished]: '#fff',
      [TripStatus.Canceled]: 'var(--purple22)',
    };

    return setColor[status_id];
  };

  const background = () => {
    if (showDeliveryInfo) {
      return 'var(--green2c)';
    }

    const setBackground: { [key in TripStatus]: string } = {
      [TripStatus.Pending]: '#CFCBF1',
      [TripStatus.Displacement]: 'var(--purple22)',
      [TripStatus.Collecting]: 'var(--purple22)',
      [TripStatus.OnGoing]: 'var(--purple22)',
      [TripStatus.waitingPayment]: '#CFCBF1',
      [TripStatus.Finished]: 'var(--purple22)',
      [TripStatus.Canceled]: '#CFCBF1',
    };

    return setBackground[status_id];
  };

  const active = (follow: boolean) => {
    return follow === true ? 'cargo-details__selector--active' : '';
  };

  const renderMapClock = () => {
    if (status_id === TripStatus.Finished || followRoute) return null;

    return null;
  };

  const renderChat = () => {
    if (!followRoute) return null;

    return <Chat tripId={id} responsibleId={responsible?.id || ''} />;
  };

  const handleShowDeliveryInfo = () => {
    setShowDeliveryInfo(true);
    setFollowRoute(false);
  };

  const changeTravelTime = (seconds: number) => {
    const formatted = formatSeconds(seconds);

    setFormattedTravelTime(formatted);
  };

  const destinationGeo = preCollectionStatuses.includes(cargo?.status_id)
    ? cargo?.initial_address?.geolocation?.coordinates
    : cargo?.delivery_address?.geolocation?.coordinates;

  return (
    <Container
      className="cargo-details"
      color={color()}
      background={`${background()}`}
    >
      <div className="cargo-details__header">
        <span className="cargo-details__code">{order}</span>

        <div
          className={`cargo-details__selector ${active(!followRoute)}`}
          onClick={() => setFollowRoute(false)}
        >
          {showDeliveryInfo
            ? 'Informações da entrega'
            : 'Informações da solicitação'}
        </div>

        {!requestingStatuses.includes(cargo.status_id) && !showDeliveryInfo && (
          <div
            className={`cargo-details__selector ${active(followRoute)}`}
            onClick={() => setFollowRoute(true)}
          >
            Acompanhar trajeto
          </div>
        )}
      </div>

      <div className="cargo-details__content">
        <div className="cargo-details__wrapper">
          {!followRoute && (
            <CargoDetailsInfo
              cargoInfo={cargo}
              cancelTrip={cancelTrip}
              showDeliveryInfo={showDeliveryInfo}
            />
          )}

          {followRoute && (
            <FollowRouteDetails
              trip={cargo}
              onShowDeliveryInfo={handleShowDeliveryInfo}
            />
          )}
        </div>
        <div className="cargo-details__map-container">
          {renderMapClock()}
          {renderChat()}
          <Map
            fixedZoomContainer={false}
            origin={vehicleGeo}
            destination={destinationGeo}
            onTravelTimeChange={changeTravelTime}
            center={center}
            changeCenter={changeCenter}
          />
        </div>
      </div>
    </Container>
  );
};
