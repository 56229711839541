import styled from 'styled-components';

import background_login from '../../assets/img/background_login.png';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: no-repeat center;
  background-size: cover;
  background-image: url(${background_login});

  animation: entering 0.2s ease-in;
`;

export const Content = styled.div`
  width: 100%;

  flex: 1;

  padding: 3.5rem 0 2rem 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  min-height: 100vh;
  height: fit-content;
  overflow: auto;
`;

export const Logo = styled.img`
  width: 15.625rem;

  margin-bottom: 3.5rem;
`;

export const TermsContainer = styled.div`
  border-radius: 20px;

  min-height: 37rem;
  height: fit-content;
  width: 100%;
  max-width: 50rem;

  padding: 2.125rem 2.625rem 3.75rem 2.625rem;

  background: rgba(255, 255, 255, 0.5);
`;

export const TermsTitle = styled.h1`
  color: #221551;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
`;

export const TermsText = styled.p`
  margin-top: 2rem;

  width: 100%;
  text-align: justify;
  white-space: pre-line;
  font-size: 1rem;
  line-height: 1.3;
`;
