import ReactDOM from 'react-dom';

import { useEffect, useRef, useState } from 'react';

import { ReactComponent as CloseIcon } from '../../../../assets/icons/blue_close_icon.svg';
import { ReactComponent as WhiteCloseIcon } from '../../../../assets/icons/white_close_icon.svg';

import api from 'services/api';
import { handleError } from 'services/errorHandler';
import { requestingStatuses } from 'shared/utils/tripStatus';
import { ICreatedTrip } from 'types/requestVehicleTypes';
import { CargoDetails } from '../CargoDetails/CargoDetails';
import { Container } from './_detailsModal';

interface IModal {
  showModal: boolean;
  cancelTrip: (id: string) => Promise<void>;
  cargoId: string;
  onClose: () => void;
}

export const DetailsModal: React.FC<IModal> = ({
  showModal,
  cancelTrip,
  cargoId,
  onClose,
}) => {
  const [cargo, setCargo] = useState<ICreatedTrip>();
  const modalRoot = document.getElementById('root-modal');
  const modalRef = useRef(null);

  const fetchTripDetails = async () => {
    try {
      const response = await api.get<{
        trip: ICreatedTrip;
        travelTime: number;
      }>(`/trips/${cargoId}`);

      setCargo(response.data.trip);
    } catch (error: any) {
      handleError(
        error?.response?.data?.message ||
          'Algo deu errado, tente novamente mais tarde',
      );
    }
  };

  useEffect(() => {
    if (cargoId) {
      fetchTripDetails();
    }
  }, [cargoId]);

  if (!modalRoot) {
    return null;
  }

  const hide = () => {
    return !showModal ? 'details-modal--hide' : '';
  };

  const renderCloseIcon = () => {
    return !requestingStatuses.includes(cargo?.status_id || 0) ? (
      <WhiteCloseIcon />
    ) : (
      <CloseIcon />
    );
  };

  const renderModal = () => {
    if (showModal) {
      return (
        <Container className={`details-modal ${hide()}`}>
          <div ref={modalRef} className="details-modal__wrapper">
            <div className="details-modal__close-button" onClick={onClose}>
              {renderCloseIcon()}
            </div>

            <div className="modal__content">
              {cargo && (
                <CargoDetails
                  cargo={cargo}
                  setCargo={setCargo}
                  cancelTrip={cancelTrip}
                />
              )}
            </div>
          </div>

          <div className="modal__backdrop" onClick={onClose} />
        </Container>
      );
    }
  };

  return ReactDOM.createPortal(<>{renderModal()} </>, modalRoot);
};
