import { Redirect, Route, Switch } from 'react-router-dom';

import { Home } from '../pages/Home/Home';
import { SettingsPage } from 'pages/SettingsPage/SettingsPage';
import { SplashScreen } from 'pages/SplashScreen/SplashScreen';
import { MyLoadingsPage } from 'pages/MyLoadings/MyLoadingsPage';
import Terms from 'pages/Terms/Terms';

const PrivateRoutes = () => (
  <Switch>
    <Route exact path="/login">
      <Redirect to="/bem-vindo" />
    </Route>
    <Route component={Terms} path="/politica-de-privacidade" exact />
    <Route exact path="/">
      <Redirect to="/bem-vindo" />
    </Route>
    <Route component={SplashScreen} path="/bem-vindo" />
    <Route
      component={Home}
      path={['/home', '/home/:search', '/home/:search/:tripId']}
      exact
    />
    <Route component={SettingsPage} path="/configuracoes" exact />
    <Route
      component={MyLoadingsPage}
      path={['/minhas-cargas', '/minhas-cargas/:tripId']}
      exact
    />
  </Switch>
);

export default PrivateRoutes;
