import React, { useContext } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import api from 'services/api';

import { SecondaryButton } from 'shared/components/atoms/SecondaryButton/SecondaryButton';
import { Input } from 'shared/components/molecules/Input/Input';
import { Spinner } from 'shared/components/atoms/Spinner/Spinner';

import { emailSchema } from 'shared/utils/schemas';

import { Container } from './_editEmailComponent';
import { AuthContext } from 'contexts/auth';
import { User } from 'types/authTypes';

interface Props {
  defaultValue: string;
  setShowModal: Function;
}

interface IEmail {
  email: string;
}

export const EditEmailComponent: React.FC<Props> = ({
  defaultValue,
  setShowModal,
}) => {
  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<IEmail>({
    resolver: yupResolver(emailSchema),
    mode: 'onChange',
  });

  const { setUser } = useContext(AuthContext);

  const onSubmit: SubmitHandler<IEmail> = (data) => {
    editInformation(data);
  };

  const editInformation = async (data: IEmail) => {
    try {
      const response = await api.put<User>(`users`, data);

      if (response) {
        toast.success('E-mail atualizado com sucesso');

        setUser(response.data);

        setShowModal(false);
      }
    } catch (error: any) {
      handleError(error.response?.data?.message || error.toString());
    }
  };

  const handleError = (error: string) => {
    toast.error(error);
  };

  return (
    <Container className="edit-email" onSubmit={handleSubmit(onSubmit)}>
      <span className="edit-email__title">Alterar E-mail:</span>

      <Input
        placeholder="E-mail"
        defaultValue={defaultValue}
        register={{ ...register('email') }}
      />

      <SecondaryButton disabled={isSubmitting || !isValid}>
        {isSubmitting ? <Spinner white /> : 'Salvar alteração'}
      </SecondaryButton>
    </Container>
  );
};
