import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  flex: 1;
  height: 100%;

  > .map-container {
    width: 100%;
    height: 100%;
    grid-column-start: 1;
    grid-row-start: 1;
  }

  > .sidebar {
    grid-column-start: 1;
    grid-row-start: 1;
    position: relative;
    z-index: 998;
  }
`

export const ZoomControler = styled.div`
  &.zoom-controler {
    position: fixed;
    z-index: 99;

    bottom: 20px;
    right: 5px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;

    width: 70px;
    height: 130px;

    border-radius: 12px;
    box-shadow: 0 2px 8px rgb(0 0 0 /16%);
    background-color: #fff;

    .zoom-controler__button {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 40px;
      height: 40px;

      color: #fff;
      font-size: 25px;

      border-radius: 21px;

      background-color: var(--purple22);
      box-shadow: 0 2px 4px rgb(0 0 0 /16%);

      cursor: pointer;
      transition: all 0.5s;

      &:before {
        content: '-';
      }

      &:hover {
        filter: brightness(1.5);
      }
    }

    .zoom-controler__button--plus {
      &:before {
        content: '+';
      }
    }
  }
`;
