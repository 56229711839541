import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from 'services/api';
import logo from '../../assets/icons/logo_vagoo.svg';
import {
  Container,
  Content,
  TermsContainer,
  TermsTitle,
  TermsText,
  Logo,
} from './_terms';

const Terms = () => {
  const [terms, setTerms] = useState('');

  const getTerms = async () => {
    try {
      const { data } = await api.get('/system-infos');

      setTerms(data?.system_infos?.terms_and_conditions_shipper || '');
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getTerms();
  }, []);

  return (
    <Container>
      <Content>
        <Logo src={logo} />
        <TermsContainer>
          <TermsTitle>Política de privacidade</TermsTitle>
          <TermsText>{terms}</TermsText>
        </TermsContainer>
      </Content>
    </Container>
  );
};

export default Terms;
