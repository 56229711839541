import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { GeneralInput } from 'shared/components/atoms/GeneralInput/GeneralInput';
import { SecondaryButton } from 'shared/components/atoms/SecondaryButton/SecondaryButton';
import { addressModalSchema } from 'shared/utils/schemas';
import { AddressForm } from './_addressModal';
import { getAddressInformation } from 'services/addressApi';
import { handleError } from 'services/errorHandler';
import { ModalContainer } from 'shared/components/molecules/ModalContainer/ModalContainer';

export interface IAddressModalFormData {
  cep: string;
  street: string;
  neighborhood: string;
  city_ibge_code: string;
  number: string;
  city: string;
  complement: string;
  name?: string;
  uf: string;
}

interface AddressModalProps {
  setShowModal: (open: boolean) => void;
  showModal: boolean;
  onConfirm: (addressFormData: IAddressModalFormData) => void;
  title?: string;
  hasNameField?: boolean;
  buttonText?: string;
  initialFormData?: Partial<IAddressModalFormData>;
}

export const AddressModal: React.FC<AddressModalProps> = ({
  setShowModal,
  showModal,
  onConfirm,
  initialFormData,
  buttonText = 'Adicionar endereço',
  hasNameField = true,
  title = 'Adicionar endereço',
}) => {
  const {
    handleSubmit,
    register,
    reset,
    getValues,
    formState: { isValid },
  } = useForm<IAddressModalFormData>({
    resolver: yupResolver(addressModalSchema),
    mode: 'onChange',
    defaultValues: initialFormData,
  });

  const handleCepBlur = async () => {
    const cep = getValues('cep');

    if (!cep || cep.length < 8) return;

    try {
      const { state, number, ...addressObj } = await getAddressInformation(cep);

      reset({
        ...addressObj,
        uf: state,
      });
    } catch (error: any) {
      handleError(
        error?.response?.data.message ||
          error?.message ||
          'Erro ao buscar o CEP',
      );
    }
  };

  return (
    <ModalContainer setShowModal={setShowModal} showModal={showModal}>
      <AddressForm as="div">
        <button
          type="button"
          className="address-form__close-button"
          onClick={() => setShowModal(false)}
        >
          &times;
        </button>
        <p className="address-form__title">{title}</p>
        <div className="address-form__row">
          <GeneralInput
            label="CEP"
            type="text"
            containerClassName="address-form__field address-form__field--small"
            {...register('cep', {
              onBlur: handleCepBlur,
            })}
          />
          <GeneralInput
            label="Endereço"
            type="text"
            containerClassName="address-form__field address-form__field--fill"
            {...register('street')}
          />
        </div>
        <div className="address-form__row">
          <GeneralInput
            label="Bairro"
            type="text"
            containerClassName="address-form__field address-form__field--medium"
            {...register('neighborhood')}
          />
          <GeneralInput
            label="Cidade"
            type="text"
            containerClassName="address-form__field address-form__field--medium"
            {...register('city')}
          />
          <GeneralInput
            label="Estado"
            type="text"
            containerClassName="address-form__field address-form__field--small"
            {...register('uf')}
          />
        </div>
        <div className="address-form__row">
          <GeneralInput
            label="Número"
            type="text"
            containerClassName="address-form__field address-form__field--small"
            {...register('number')}
          />
          <GeneralInput
            label="Complemento (opcional)"
            type="text"
            containerClassName="address-form__field address-form__field--medium"
            {...register('complement')}
          />
        </div>
        {hasNameField && (
          <div className="address-form__row">
            <GeneralInput
              label="Identificação"
              placeholder="Ex,: Casa, Escritório, etc."
              type="text"
              containerClassName="address-form__field address-form__field--long"
              {...register('name')}
            />
          </div>
        )}
        {/* Form inside modal was bubbling events, triggering submit on parent form -> switch button to type="button" and form element to div */}
        <SecondaryButton
          type="button"
          disabled={!isValid}
          onClick={handleSubmit(onConfirm)}
        >
          {buttonText}
        </SecondaryButton>
      </AddressForm>
    </ModalContainer>
  );
};
