import ReactDOM from 'react-dom';
import useOutside from 'shared/hooks/useOutside.hook';
import { Container } from './_modalContainer';

interface ModalContainerProps {
  showModal: boolean;
  setShowModal: (open: boolean) => void;
}

export const ModalContainer: React.FC<ModalContainerProps> = ({
  setShowModal,
  showModal,
  children,
}) => {
  const modalRoot = document.getElementById('root-modal');

  const handleClose = () => {
    setShowModal(false);
  };

  const { ref: modalRef } = useOutside(handleClose);

  if (!modalRoot) {
    return null;
  }

  return ReactDOM.createPortal(
    <Container showModal={showModal}>
      <div className="modal-container__content" ref={modalRef}>
        {children}
      </div>
    </Container>,
    modalRoot,
  );
};
