import React from 'react';

import { GenericButton } from './_button';

interface ButtonProps extends React.HTMLAttributes<HTMLElement> {
  backgroundColor?: string;
  disabled?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  backgroundColor,
  disabled,
  children,
  ...rest
}) => {
  return (
    <GenericButton
      className="button"
      backgroundColor={backgroundColor}
      disabled={disabled}
      {...rest}
    >
      {children}
    </GenericButton>
  );
};
