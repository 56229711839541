import styled from 'styled-components';

export const GenericButton = styled.button`
  &.button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 48px;

    color: #fff;
    font-size: 0.85rem;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;

    border: 0;
    border-radius: 10px;

    cursor: pointer;
    transition: all 0.2s;

    background-color: ${({ backgroundColor }: { backgroundColor?: string }) =>
      backgroundColor ? backgroundColor : 'var(--purple22)'};

    &:hover {
      color: #fff;
      box-shadow: 0px 0px 3px rgb(0 0 0 /16%);
      filter: brightness(1.1);
    }

    &:disabled {
      filter: saturate(0.3) brightness(1.5);
      cursor: not-allowed;
    }
  }
`;
