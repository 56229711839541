import styled from 'styled-components';
import background_login from '../../assets/img/background_login.png';

export const Container = styled.div`
  &.loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100vw;
    height: 100vh;

    background: no-repeat center;
    background-size: cover;
    background-image: url(${background_login});

    animation: entering 0.5s ease-in;
  }
`;
