/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from 'services/api';
import { handleError } from 'services/errorHandler';
import useNotifications from 'shared/hooks/useNotifications.hook';
import {
  ICreatedTrip,
  RequestTripFormData,
  RequestVehicleResponse,
} from 'types/requestVehicleTypes';
import { PaymentSectionComponent } from './components/PaymentSectionComponent/PaymentSectionComponent';
import { RequestFormComponent } from './components/RequestFormComponent/RequestFormComponent';
import { RequestResultComponent } from './components/RequestResultComponent/RequestResultComponent';
import { SearchFeedbackComponent } from './components/SearchFeedBackComponent/SearchFeedbackComponent';
import { formatDataForRequest, mapTripToForm } from './utils';
import { Container } from './_sidebar';

interface Props {
  showSidebar: boolean;
  setShowSidebar: Function;
  requestStep: string;
  setRequestStep: Function;
  requestVehicleData?: RequestTripFormData;
  setRequestVehicleData: Function;
  requestVehicleSearchResult?: RequestVehicleResponse;
  setRequestVehicleSearchResult: Function;
}

const timeToRetry = 59000;

export const Sidebar: React.FC<Props> = ({
  showSidebar,
  setShowSidebar,
  requestStep,
  requestVehicleData,
  requestVehicleSearchResult,
  setRequestStep,
  setRequestVehicleData,
  setRequestVehicleSearchResult,
}) => {
  const { cancelledTripIds, pendingPaymentTripIds } = useNotifications();

  const params = useParams<{
    search?: string;
    tripId?: string;
  }>();

  const handleParamsChange = () => {
    if (params.search) {
      switch (params.search) {
        case 'search':
          setShowSidebar(true);
          setRequestStep('default');
          break;
        case 'payment':
          setShowSidebar(true);
          setRequestStep('payment');
          break;
        default:
          break;
      }
    }

    if (
      params.tripId &&
      params.tripId !== requestVehicleSearchResult?.trip?.id
    ) {
      fetchTripDetails();
    }
  };

  const fetchTripDetails = async () => {
    try {
      const response = await api.get<{
        trip: ICreatedTrip;
      }>(`/trips/${params.tripId}`);

      setRequestVehicleSearchResult({
        ...requestVehicleSearchResult,
        trip: response.data.trip,
      } as RequestVehicleResponse);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleParamsChange();
  }, [params]);

  const collapse = () => {
    return showSidebar ? 'show' : '';
  };

  const renderGoToFormAnchor = () => {
    if (requestStep === 'success') {
      return (
        <span
          className="title__go-to-form"
          onClick={() => setRequestStep('default')}
        >
          <span className="title__back-arrow" />
          Redefinir solicitação
        </span>
      );
    }

    if (requestStep === 'payment') {
      return (
        <span
          className="title__go-to-form"
          onClick={() => setRequestStep('default')}
        >
          <span className="title__back-arrow" />
          Voltar
        </span>
      );
    }
  };

  const renderSidebarTitle = () => {
    return requestStep === 'payment'
      ? 'Selecionar forma de pagamento'
      : 'Solicitação de veículo';
  };

  const handleCloseSidebar = () => {
    setShowSidebar(false);
    setRequestStep('default');
  };

  const requestFormComponent = (
    <RequestFormComponent
      setRequestStep={setRequestStep}
      setRequestVehicleSearchResult={setRequestVehicleSearchResult}
      setRequestVehicleData={setRequestVehicleData}
      requestVehicleData={requestVehicleData}
      closeSidebar={handleCloseSidebar}
      requestStep={requestStep}
      requestVehicleResult={requestVehicleSearchResult}
    />
  );

  const searchComponent = (
    <SearchFeedbackComponent
      requestStep={requestStep}
      setRequestStep={setRequestStep}
    />
  );

  const notFoundComponent = (
    <SearchFeedbackComponent
      requestStep={requestStep}
      setRequestStep={setRequestStep}
      tripId={requestVehicleSearchResult?.trip?.id}
    />
  );

  const requestResultComponent = (
    <RequestResultComponent
      setRequestStep={setRequestStep}
      requestVehicleResult={requestVehicleSearchResult}
    />
  );

  const paymentSectionComponent = (
    <PaymentSectionComponent
      requestVehicleResult={requestVehicleSearchResult}
      closeSidebar={handleCloseSidebar}
      setRequestVehicleData={setRequestVehicleData}
      setRequestVehicleSearchResult={setRequestVehicleSearchResult}
    />
  );

  const renderSection = () => {
    const section: { [key: string]: JSX.Element } = {
      default: requestFormComponent,
      searching: searchComponent,
      'not-found': notFoundComponent,
      success: requestResultComponent,
      payment: paymentSectionComponent,
    };

    return section[requestStep];
  };

  useEffect(() => {
    if (requestVehicleSearchResult) {
      if (
        pendingPaymentTripIds?.includes(requestVehicleSearchResult?.trip?.id)
      ) {
        setRequestStep('success');
        setShowSidebar(true);
      } else if (
        cancelledTripIds?.includes(requestVehicleSearchResult?.trip?.id) &&
        requestStep === 'searching'
      ) {
        setRequestStep('not-found');
        setShowSidebar(true);
      }
    }
  }, [cancelledTripIds, pendingPaymentTripIds, requestVehicleSearchResult]);

  useEffect(() => {
    if (requestVehicleSearchResult) {
      setRequestVehicleData((prevState: Partial<RequestTripFormData>) => ({
        ...mapTripToForm(requestVehicleSearchResult?.trip),
        search_radius: prevState?.search_radius,
      }));
    }
  }, [requestVehicleSearchResult]);

  const createTrip = async (data?: RequestTripFormData) => {
    if (!!requestVehicleSearchResult || !data) {
      return;
    }

    const filteredVehicleTypes = await api.get('/vehicle-types', {
      params: {
        name: data?.vehicle_type_id?.label,
        bodywork_type_id: data?.cargo_bodywork_id?.value,
        weight_id: data?.cargo_weight?.value,
        volume_id: data?.volume?.value,
      },
    });

    if (filteredVehicleTypes?.data?.results?.length > 0) {
      try {
        const response = await api.post(
          '/trips',
          formatDataForRequest({
            ...data,
            vehicle_type_id: filteredVehicleTypes.data.results[0].id,
          }),
        );

        setRequestVehicleSearchResult(response.data);
      } catch (error: any) {
        if (
          error?.response?.data?.message ===
          'Não foi possível encontrar um veículo com as especificações.'
        ) {
          setRequestStep('not-found');
        } else {
          handleError(error?.response?.data?.message || error?.message);
          setRequestStep('default');
        }
      }
    } else {
      handleError('Tipo de veículo inválido');
    }
  };

  useEffect(() => {
    if (requestStep === 'searching' && !requestVehicleSearchResult) {
      const timeout = setTimeout(() => {
        createTrip(requestVehicleData);
      }, timeToRetry);

      return () => clearTimeout(timeout);
    }
  }, [requestStep, requestVehicleData, requestVehicleSearchResult]);

  return (
    <Container className={`sidebar ${collapse()}`}>
      <div className="sidebar__title">
        <div className="title__wrapper">
          {renderGoToFormAnchor()}
          {renderSidebarTitle()}
        </div>
      </div>

      <div className="sidebar__form">{renderSection()}</div>

      <div
        className={`sidebar__expand-button ${collapse()}`}
        onClick={() => setShowSidebar(!showSidebar)}
      />
    </Container>
  );
};
