import React, { useState } from 'react';

import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';

import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { PrimaryButton } from 'shared/components/atoms/PrimaryButton/PrimaryButton';
import { Spinner } from 'shared/components/atoms/Spinner/Spinner';
import { Input } from 'shared/components/molecules/Input/Input';
import { registerSchema } from 'shared/utils/schemas';

import { IAddress, IRegister } from 'types/registerTypes';

import logo from '../../../../assets/icons/logo_vagoo.svg';

import {
  CompanyRegisterContainer,
  Logo,
  PillSelector,
  PinIcon,
  Radio,
  ReadPolicy,
} from './_companyRegisterComponent';

import { maskCpfOrCnpj, sanitizeString } from 'shared/utils/format';

interface Props {
  setShowAddressModal: Function;
  setShowTermsModal: Function;
  loading: boolean;
  submitForm: Function;
  addressFormData?: IAddress;
}

const formatAddress = (address: IAddress): string => {
  const { street, number, city, cep } = address;

  return `${street}, ${number}, ${city} - ${cep}`;
};

export const CompanyRegisterComponent: React.FC<Props> = ({
  setShowAddressModal,
  setShowTermsModal,
  loading,
  submitForm,
  addressFormData,
}) => {
  const [insurance, setInsurance] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const securityIsActive = (option: string) => {
    return insurance === option ? 'active' : '';
  };

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting, errors },
    watch,
  } = useForm<IRegister>({
    resolver: yupResolver(registerSchema),
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<IRegister> = (data) => {
    submitForm(data);
  };

  const confirmPasswordError = () => {
    if (errors.password_confirmation?.message === 'not-match')
      return 'As senhas precisam ser iguais';
  };

  return (
    <CompanyRegisterContainer
      className="company-register-container"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="company-register-container__form-container">
        <Logo
          className="header__logo"
          src={logo}
          alt="Logo Vagoo"
          width="280"
          height="60"
        />

        <div className="form">
          <h1 className="form__title">
            <b>Cadastro</b>
            Informações sobre a empresa
          </h1>

          <div className="form__register-container">
            <div className="register-container__register">
              <Input
                type="text"
                placeholder={'Nome (Pessoa física/jurídica)'}
                register={{ ...register('trade_name') }}
              />

              <Input
                inputValue={maskCpfOrCnpj(watch('cpf_cnpj') || '')}
                maxLength={18}
                placeholder={'CNPJ/CPF'}
                register={{
                  ...register('cpf_cnpj', {
                    setValueAs: (value) => sanitizeString(value),
                  }),
                }}
              />
              <Input
                type="text"
                placeholder={'Razão Social'}
                register={{ ...register('corporate_name') }}
              />

              <div
                className="register__add-address"
                onClick={() => setShowAddressModal(true)}
              >
                Adicionar endereço
              </div>

              {addressFormData ? (
                <div className="register__address-container">
                  <PinIcon />
                  <p className="register__address">
                    {formatAddress(addressFormData)}
                  </p>
                </div>
              ) : null}

              {/* <div className="register__insurance">
                <b>Seguro</b>
                Selecione uma das opções
                <PillSelector className="insurance__radio-wrapper">
                  <label
                    className={`
                    insurance__radio
                    radio--transporter ${securityIsActive('transporter')}`}
                    onClick={() => setInsurance('transporter')}
                  >
                    <input
                      type="radio"
                      value="false"
                      {...register('has_insurance')}
                    />
                    Por conta do Vagoo
                  </label>

                  <label
                    className={`
                    insurance__radio
                    radio--shipper ${securityIsActive('shipper')}`}
                    onClick={() => setInsurance('shipper')}
                  >
                    <input
                      type="radio"
                      value="true"
                      {...register('has_insurance')}
                    />
                    Por conta do embarcador
                  </label>
                </PillSelector>
                {insurance === 'shipper' && (
                  <div className="insurance__ddr">
                    Fornece DDR?
                    <Radio className="ddr-radio">
                      <label className="ddr-radio__option">
                        <input
                          {...register('has_ddr')}
                          type="radio"
                          value="true"
                        />
                        <div className="radio__selection">Sim</div>
                      </label>

                      <label className="ddr-radio__option">
                        <input
                          {...register('has_ddr')}
                          type="radio"
                          value="false"
                        />
                        <div className="radio__selection">Não</div>
                      </label>
                    </Radio>
                  </div>
                )}
              </div> */}

              <div className="register__create-password">
                <span className="create-password__title">Criar senha</span>

                <div className="create-password__input-wrapper">
                  <Input
                    placeholder="Senha"
                    type={showPassword ? 'text' : 'password'}
                    register={{ ...register('password') }}
                  />
                  <button
                    className="create-password__visible-icon-button"
                    type="button"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                  </button>
                </div>

                <div className="create-password__input-wrapper">
                  <Input
                    placeholder="Confirmar senha"
                    type={showConfirmPassword ? 'text' : 'password'}
                    register={{ ...register('password_confirmation') }}
                  />
                  <button
                    className="create-password__visible-icon-button"
                    type="button"
                    onClick={() => setShowConfirmPassword((prev) => !prev)}
                  >
                    {showConfirmPassword ? (
                      <AiFillEye />
                    ) : (
                      <AiFillEyeInvisible />
                    )}
                  </button>
                </div>
                <span className="register__error-feedback">
                  {confirmPasswordError()}
                </span>
              </div>

              <ReadPolicy>
                <label className="policy__checkbox-label">
                  <input {...register('accepted_terms')} type="checkbox" />

                  <span>&#10003;</span>

                  <b className="policy__checkbox-label_text">
                    Li e aceito os termos de uso e política de privacidade
                  </b>
                </label>

                <div
                  className="policy__read-terms"
                  onClick={() => setShowTermsModal(true)}
                >
                  LER
                </div>
              </ReadPolicy>

              <PrimaryButton
                type="submit"
                disabled={isSubmitting || !isValid || !addressFormData}
              >
                {loading ? <Spinner white /> : 'Finalizar Cadastro'}
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </CompanyRegisterContainer>
  );
};
