import { Option } from 'types/requestVehicleTypes';
import * as yup from 'yup';
import { isFilled } from './validations';

export const registerSchema = yup
  .object()
  .shape({
    password: yup.string().required(),
    password_confirmation: yup
      .string()
      .required()
      .oneOf([yup.ref('password'), null], 'not-match'),
    accepted_terms: yup
      .boolean()
      .required()
      .test({ message: 'error', test: (value) => value === true }),

    trade_name: yup.string().required(),
    corporate_name: yup.string().optional(),
    cpf_cnpj: yup.string().required(),

    // has_insurance: yup.string().required(),
    // has_ddr: yup.string().optional(),
  })
  .required();

export const managerSchema = yup
  .object({
    responsible_name: yup.string().required(),
    email: yup.string().email().required(),
    cellphone: yup.string().required().min(10),
  })
  .required();

export const addressSchema = yup
  .object()
  .shape({
    name: yup.string().optional(),
    street: yup.string().required(),
    neighborhood: yup.string().required(),
    cep: yup.string().required().min(7),
    number: yup.string().required(),
    city_ibge_code: yup.string().required(),
    state: yup.string().optional(),
    complement: yup.string().optional(),
  })
  .required();

export const authSchema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

export const requestFormAddressSchema = yup.object({
  cep: yup.string().required('CEP é obrigatório'),
  street: yup.string().required('Endereço é obrigatório'),
  neighborhood: yup.string().required('Bairro é obrigatório'),
  number: yup.string().required('Número é obrigatório'),
  city_ibge_code: yup.string().required(),
  state: yup.string().required('Estado é obrigatório'),
  city: yup.string().required('Cidade é obrigatório'),
});

export const optionSchema: yup.SchemaOf<Option> = yup.object({
  value: yup.mixed().required('Campo obrigatório'),
  label: yup.string().required('Campo obrigatório'),
});

export const requestVehicleSchema = yup.object({
  volume: yup.mixed().optional(),
  cargo_type: yup.string().required('Tipo de carga é obrigatório'),
  cargo_value: yup.string().required('Valor da carga é obrigatório'),
  search_radius: optionSchema.required('Raio de busca é obrigatório'),
  initial_address: requestFormAddressSchema.required(),
  delivery_address: requestFormAddressSchema.required(),
  observation: yup.string(),
  cargo_bodywork_id: yup.mixed().optional(),
  cargo_weight: yup.mixed().optional(),
  colecting_contact: yup.string().required('Contato é obrigatório'),
  delivery_contact: yup.string().required('Contato é obrigatório'),
  vehicle_type_id: optionSchema.required('Modelo de veículo é obrigatório'),
  id: yup.string().optional(),
});

export const emailSchema = yup.object({
  email: yup.string().email().required(),
});

export const cellPhoneSchema = yup.object({
  cellphone: yup.string().required().min(10),
});

export const editPasswordSchema = yup.object({
  old_password: yup.string().required(),
  password: yup.string().required(),
  password_confirmation: yup
    .string()
    .required()
    .oneOf([yup.ref('password'), null], 'not-match'),
});

export const editShipperSchema = yup.object({
  shipper: yup.object().shape({
    has_insurance: yup.string().required(),
    has_ddr: yup.string().required(),
    // has_rctr_c: yup.string().required(),
  }),
});

export const addressModalSchema = yup.object().shape({
  name: yup.string().optional(),
  street: yup.string().required(),
  neighborhood: yup.string().required(),
  cep: yup.string().required().min(7),
  number: yup.string().required(),
  city_ibge_code: yup.string().required(),
  state: yup.string().optional(),
  complement: yup.string().optional(),
});

export const createCardSchema = yup.object({
  number: yup.string().required().length(19),
  holder_name: yup
    .string()
    .required()
    .test((value) => isFilled(value || '')),
  expiration_date: yup.string().required().length(5),
  cvv: yup.string().required().length(3),

  newBillingAddress: yup.boolean(),
  address: yup.object().when('newBillingAddress', {
    is: true,
    then: addressModalSchema.required(),
  }),
});
