import React from 'react';
import { TripStatus } from 'types/mainTypes';
import { CargoStepperContainer } from './_cargoStepper';

interface CargoStepperProps {
  status_id: TripStatus;
}

const steps = ['Deslocamento', 'Coleta', 'Viagem', 'Entrega'];

const getLastCompletedStep = (status_id: TripStatus): number => {
  switch (status_id) {
    case TripStatus.Collecting:
      return steps.indexOf('Deslocamento') || 0;
    case TripStatus.OnGoing:
      return steps.indexOf('Coleta') || 0;
    case TripStatus.Finished:
      return steps.indexOf('Entrega') || 0;
    default:
      return 0;
  }
};

export const CargoStepper: React.FC<CargoStepperProps> = ({ status_id }) => {
  const lastCompletedStep = getLastCompletedStep(status_id);

  return (
    <CargoStepperContainer className="cargo-stepper">
      <span className="cargo-stepper__title">Trajeto</span>

      <div className="cargo-stepper__step-container">
        {steps.map((step, index) => (
          <React.Fragment key={step}>
            <div
              className={`cargo-stepper__step ${
                index <= lastCompletedStep ? ' active' : ''
              }`}
            >
              <div
                className={`cargo-stepper__step__${
                  index === 0 ? 'initial' : 'dot'
                }
                  ${index === lastCompletedStep + 1 ? ' active' : ''}
                `}
              />
              {step}
            </div>
            {index < 3 && (
              <div
                className={`cargo-stepper__step__divider ${
                  index === lastCompletedStep ? ' active' : ''
                }`}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </CargoStepperContainer>
  );
};
