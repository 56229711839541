export const sanitizeString = (value: string): string => {
  return value.replace(/\D/g, '');
};

export const cpfFormat = (cpf: string) => {
  cpf = cpf.replace(/\D/g, '');

  if (cpf.length <= 11) {
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  } else {
    cpf = cpf.replace(/^(\d{2})(\d)/, '$1.$2');
    cpf = cpf.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    cpf = cpf.replace(/\.(\d{3})(\d)/, '.$1/$2');
    cpf = cpf.replace(/(\d{4})(\d)/, '$1-$2');
  }

  return cpf;
};

export const cepFormat = (postal: string) => {
  postal = postal.replace(/\D/g, '');
  if (postal.length > 8) {
    postal = postal.slice(0, -1);
  }
  postal = postal.replace(/^([0-9]{5})([0-9]{3})$/, '$1-$2');

  return postal.toString();
};

export const emailValidation = (email: String) => {
  let usuario = email.substring(0, email.indexOf('@'));
  let dominio = email.substring(email.indexOf('@') + 1, email.length);

  if (
    usuario.length >= 1 &&
    dominio.length >= 3 &&
    usuario.search('@') === -1 &&
    dominio.search('@') === -1 &&
    usuario.search(' ') === -1 &&
    dominio.search(' ') === -1 &&
    dominio.search('.') !== -1 &&
    dominio.indexOf('.') >= 1 &&
    dominio.lastIndexOf('.') < dominio.length - 1
  ) {
    return true;
  } else {
    return false;
  }
};

export const normalize = (str: string) => {
  const parse = str
    .normalize('NFD')
    .replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
  return parse;
};

export const phoneFormat = (value: string) => {
  let phone = value.replace(/\D/g, '').replace(/^0/, '');

  if (phone.length <= 10) {
    phone = phone.replace(/(\d{2})(\d)/, '($1) $2');
    phone = phone.replace(/(\d{4})(\d)/, '$1-$2');
    phone = phone.replace(/(\d{4})(\d)$/, '$1-$2');
  } else {
    phone = phone.replace(/(\d{2})(\d)/, '($1) $2');
    phone = phone.replace(/(\d{5})(\d)/, '$1-$2');
    phone = phone.replace(/(\d{4})(\d)$/, '$1-$2');
  }
  return phone;
};

export const cnpjFormat = (cnpj: string) => {
  cnpj = cnpj.replace(/\D/g, '');

  if (cnpj.length > 14) cnpj = cnpj.slice(0, -1);

  cnpj = cnpj.replace(/(\d{2})(\d)/, '$1.$2');
  cnpj = cnpj.replace(/(\d{3})(\d)/, '$1.$2');
  cnpj = cnpj.replace(/(\d{3})(\d)/, '$1/$2');
  cnpj = cnpj.replace(/(\d{4})(\d)/, '$1-$2');

  return cnpj;
};

export const bornDateFormat = (value: string) => {
  let bornDate = value.replace(/\D/g, '');

  bornDate = bornDate.replace(/(\d{2})(\d)/, '$1/$2');
  bornDate = bornDate.replace(/(\d{2})(\d)/, '$1/$2');
  bornDate = bornDate.replace(/(\d{4})(\d)/, '$1/$2');

  return bornDate;
};

export const normalizeBornDate = (value: string) => {
  let bornDate = value;

  bornDate = bornDate.replace(/(\d{2})(\d)/, '$1/$2');
  bornDate = bornDate.replace(/(\d{2})(\d)/, '$1/$2');
  bornDate = bornDate.replace(/(\d{4})(\d)/, '$1/$2');

  bornDate = bornDate.split('/').reverse().join('-');

  return bornDate;
};

export const realCurrency = (value?: number) => {
  if (value == null) return '';
  return value.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
};

export const cardNumberFormat = (value: string) => {
  if (!value) return '';

  const onlyDigits = value.replace(/\D/g, '');

  return onlyDigits.replace(/\d{4}(?=.)/g, '$& ');
};

export const cardExpirationFormat = (value: string) => {
  if (!value) return '';

  const newValue = value
    .replace(/[^0-9/]/g, '')
    .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
    .replace(/^(\d{1})\//, '0$1/')
    .replace(/[/]/g, (match, offset, all) => {
      if (match === '/') {
        return all.indexOf('/') === offset ? '/' : '';
      }
      return '';
    });

  return newValue;
};

export const formatSeconds = (seconds: number) => {
  // If seconds >= 3600, return HH:mm:ss
  // If seconds < 3600, return mm:ss

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secondsLeft = seconds % 60;

  if (hours > 0) {
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${secondsLeft.toString().padStart(2, '0')}`;
  }

  return `${minutes.toString().padStart(2, '0')}:${secondsLeft
    .toString()
    .padStart(2, '0')}`;
};

const rtf = new Intl.RelativeTimeFormat('pt-BR', {
  style: 'narrow',
  numeric: 'auto',
});

export const formatRelativeDate = (date: Date) => {
  const now = new Date();
  const diffInSeconds = (now.getTime() - date.getTime()) / 1000;
  switch (true) {
    case diffInSeconds < 60:
      return 'Agora';
    case diffInSeconds < 3600:
      return rtf.format(Math.ceil(-diffInSeconds / 60), 'minute');
    case diffInSeconds < 86400:
      return rtf.format(Math.ceil(-diffInSeconds / 3600), 'hour');
    case diffInSeconds < 604800:
      return rtf.format(Math.ceil(-diffInSeconds / 86400), 'day');
    case diffInSeconds < 2419200:
      return rtf.format(Math.ceil(-diffInSeconds / 604800), 'week');
    case diffInSeconds < 29030400:
      return rtf.format(Math.ceil(-diffInSeconds / 2419200), 'month');
    default:
      return rtf.format(Math.ceil(-diffInSeconds / 29030400), 'year');
  }
};

export const maskCpfOrCnpj = (value: string) => {
  if (!value) {
    return '';
  }

  if (value.length <= 15) {
    return cpfFormat(value);
  }

  return cnpjFormat(value);
};
