import React, { useContext, useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import api from 'services/api';

import { SecondaryButton } from 'shared/components/atoms/SecondaryButton/SecondaryButton';
import { Input } from 'shared/components/molecules/Input/Input';
import { Spinner } from 'shared/components/atoms/Spinner/Spinner';

import { Container } from './_editCompanyComponent';
import { AuthContext } from 'contexts/auth';
import { User } from 'types/authTypes';
import { isFilled } from 'shared/utils/validations';
import { cnpjFormat, sanitizeString } from 'shared/utils/format';

interface Props {
  defaultValue: string;
  setShowModal: Function;
  modalTitle: string;
  field: string;
}

export const EditCompanyComponent: React.FC<Props> = ({
  defaultValue,
  setShowModal,
  modalTitle,
  field,
}) => {
  const { setUser } = useContext(AuthContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    field === 'cnpj' && setValue(sanitizeString(defaultValue));
  }, []);

  const dataToEdit = () => {
    const data: { [key: string]: any } = {};

    data[field] = field === 'cnpj' ? sanitizeString(value) : value;

    const dataToEdit = {
      legal_person: data,
    };

    return dataToEdit;
  };

  const editInformation = async () => {
    setLoading(true);

    const data = dataToEdit();

    try {
      const response = await api.put<User>(`users`, data);

      if (response) {
        toast.success(`${modalTitle} atualizado(a) com sucesso`);

        setUser(response.data);

        setShowModal(false);
      }
    } catch (error: any) {
      handleError(error.response?.data?.message || error.toString());
    } finally {
      setLoading(false);
    }
  };

  const handleError = (error: string) => {
    toast.error(error);
  };

  return (
    <Container className="edit-company">
      <span className="edit-company__title">Alterar {modalTitle}:</span>

      {field !== 'cnpj' && (
        <Input
          placeholder="Editar"
          defaultValue={defaultValue}
          setInputValue={setValue}
        />
      )}

      {field === 'cnpj' && (
        <Input
          placeholder="Editar"
          inputValue={cnpjFormat(value)}
          setInputValue={setValue}
        />
      )}

      <SecondaryButton
        disabled={loading || !isFilled(value)}
        onClick={() => editInformation()}
      >
        {loading ? <Spinner white /> : 'Salvar alteração'}
      </SecondaryButton>
    </Container>
  );
};
