import styled from 'styled-components';

export const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ZoomController = styled.div`
  &.zoom-controller {
    position: absolute;
    z-index: 99;

    bottom: 20px;
    right: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;

    width: 70px;
    height: 130px;

    border-radius: 12px;
    box-shadow: 0 2px 8px rgb(0 0 0 /16%);
    background-color: #fff;

    .zoom-controller__button {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 40px;
      height: 40px;

      color: #fff;
      font-size: 25px;

      border-radius: 21px;

      background-color: var(--purple22);
      box-shadow: 0 2px 4px rgb(0 0 0 /16%);

      cursor: pointer;
      transition: all 0.5s;

      &:before {
        content: '-';
      }

      &:hover {
        filter: brightness(1.5);
      }
    }

    .zoom-controller__button--plus {
      &:before {
        content: '+';
      }
    }
  }

  &.fixed {
    position: fixed;
  }
`;

export const DistanceIndicatorContainer = styled.div`
  width: fit-content;
  min-height: 1.5rem;
  color: #fff;
  background-color: var(--green2c);
  border-radius: 1.25rem;
  padding: 0.5rem 1rem;
  font-family: 'Roboto';
  font-size: 0.875rem;
`;
